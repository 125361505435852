import { inject, Injectable, signal } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MockData } from "@/types/mock-data.interface";
import { environment } from "@/environments/environment";
import { catchError, map, Observable, of } from "rxjs";
import { DataResponse, ErrorResponse } from "@/types/data-response.interface";
import { Recipe } from "@/types/recipe.type";
import AppointmentsRequestParams from "@/types/appointments-request.interface";
import isDateString from "@/utils/functions/is-date-string";

@Injectable({
  providedIn: "root",
})
export class MockService {
  data = signal<MockData | null>(null);
  error = signal<ErrorResponse | null>(null);
  http = inject(HttpClient);

  getMockData() {
    return this.http
      .get<MockData>(`/assets/mock/${environment.MOCK_DATA_FILE_NAME}.json`)
      .pipe(
        map<MockData, DataResponse<MockData>>((data) => {
          if (!(data.reservations instanceof Array)) {
            throw new Error("Reservations should be an array of items.");
          }

          return {
            status: "success",
            data,
          };
        }),
        catchError<DataResponse<MockData>, Observable<ErrorResponse>>(
          (error) => {
            console.error(error);

            return of({
              status: "error",
              message: $localize`Riprova più tardi`,
              description: $localize`Siamo spiacenti, in questo momento non è possibile recuperare la lista delle tue prenotazioni.`,
            });
          }
        )
      );
  }

  getRecipeById(recipeId: string) {
    const mockRecipes = this.data()?.recipes || [];

    return mockRecipes.find((recipe) => recipe.id === recipeId);
  }

  getAppointments(params?: AppointmentsRequestParams) {
    const {
      offset = 0,
      limit = 3,
      date = new Date().toISOString().split("T")[0],
      district,
    } = params || {
      offset: 0,
      limit: 3,
    };

    let appointments = this.data()?.appointments || [];

    if (date) {
      if (!isDateString(date)) {
        throw new Error("Invalid date format");
      }

      appointments = appointments.filter(
        (appointment) => appointment.date === date
      );
    }

    if (district) {
      appointments = appointments.filter(
        (appointment) => appointment.district === district
      );
    }

    return of(appointments.slice(offset, offset + limit));
  }

  getAllReservations() {
    return this.data()?.reservations || [];
  }

  getAllRecipes() {
    return this.data()?.recipes || [];
  }

  insertRecipe() {
    return new Promise<Recipe>((resolve) => {
      setTimeout(
        () =>
          resolve({
            id: `100A1010294${Math.round(Math.random() * 1000)}`,
            category: "laboratory-tests",
            visits: ["Esami di laboratorio"],
            date: "2024-01-14T12:00",
            author: "Dott. Paolo Zanetti",
            insertByUser: true,
          }),
        1000
      );
    });
  }
}
