import { Route } from "@angular/router";
import { relativeRoutesPaths, routeLinks } from "@/classes/route-utils";
import userResolver from "../resolvers/user.resolver";

const userProfileContactSPIDRoute: Route = {
  path: relativeRoutesPaths.userContactDataSPID,
  loadComponent: () =>
    import(
      "@/app/pages/user-contact-data-spid-page/user-contact-data-spid.component"
    ).then((m) => m.UserContactDataSpidComponent),
  // canMatch: [
  //   () => {
  //     const userService = inject(UserService);
  //     return userService.userHasContactDataFromSpid();
  //   },
  // ],
  resolve: {
    user: userResolver,
  },
  data: {
    breadcrumbItems: [
      routeLinks.home,
      routeLinks.userProfile,
      routeLinks.userContactData,
      routeLinks.userContactDataSPID,
    ],
  },
};

export default userProfileContactSPIDRoute;
