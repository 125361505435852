import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "stringifyObject",
  standalone: true,
})
export default class StringifyObjectPipe implements PipeTransform {
  transform(value: object): string {
    return JSON.stringify(value);
  }
}
