import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IconBase } from "@/classes/icon-base";

@Component({
  selector: "cup-check-icon",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: { "[style]": "hostStyle()" },
  template: `
    <svg
      aria-hidden="true"
      [style.font-size.rem]="fontSize() / 16"
      [style.color]="colorMap[color()]"
      class="custom-icon"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3637 1.87869L11.9495 0.464474L4.87845 7.53554L2.05003 4.70712L0.635813 6.12133L3.46424 8.94976L4.87845 10.364L6.29267 8.94976L13.3637 1.87869Z"
        fill="currentColor"
      />
    </svg>
  `,
})
export class CheckIconComponent extends IconBase {}
