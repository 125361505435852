import {
  ChangeDetectionStrategy,
  Component,
  ErrorHandler,
  inject,
  signal,
} from "@angular/core";
import { BreadcrumbComponent } from "@/molecules/breadcrumb/breadcrumb.component";
import { HeroComponent } from "@/molecules/hero.component";
import { JsonPipe, NgOptimizedImage } from "@angular/common";
import { FullLayoutComponent } from "@/atoms/full-layout.component";
import { ItButtonDirective } from "design-angular-kit";
import { InputComponent } from "@/atoms/input/input.component";
import {
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import validateForm from "@/utils/functions/validate-form";
import { NewReservationInputRefRegister } from "@/classes/new-reservation-input-ref-register";
import exactLengthValidator from "@/utils/functions/exact-length-validator";
import { RecipesService } from "@/services/recipes.service";
import { ActivatedRoute, Router } from "@angular/router";
import { lastValueFrom } from "rxjs";
import { SessionStorageService } from "@/services/session-storage.service";
import { ResponseClientError } from "@/classes/errors";
import { ButtonComponent } from "@/atoms/button/button.component";

@Component({
  selector: "cup-insert-recipe",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BreadcrumbComponent,
    HeroComponent,
    ReactiveFormsModule,
    NgOptimizedImage,
    FullLayoutComponent,
    ItButtonDirective,
    InputComponent,
    FormsModule,
    ButtonComponent,
  ],
  providers: [JsonPipe],
  styles: `
    @use "cup";

    .example-image {
      object-position: top left;
      object-fit: contain;
      width: 100%;
      height: auto;
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: cup.toRem(48);
      margin-top: cup.toRem(48);

      &__btn {
        align-self: flex-start;
        margin-bottom: cup.toRem(46);

        @include cup.media-breakpoint-up(lg) {
          margin-bottom: cup.toRem(66);
        }
      }
    }
  `,
  template: `
    <cup-breadcrumb />

    <cup-hero
      i18n-heroTitle
      i18n-heroSubTitle
      i18n-heroCopy
      heroTitle="Carica una ricetta"
      heroSubTitle="Il numero della ricetta è in alto a destra"
      heroCopy="Inserisci i codici della ricetta manualmente nei campi dedicati."
      [showHr]="false"
    />

    <form (ngSubmit)="handleConfirm()">
      <cup-full-layout colClass="mt-5" [colConfig]="{ xs: 12, lg: 8 }">
        <img
          class="example-image"
          height="202"
          priority
          width="856"
          ngSrc="/assets/recipe-example.png"
          i18n-alt
          alt=""
        />
      </cup-full-layout>

      <cup-full-layout>
        <div class="content">
          <div class="row">
            <div class="col-6 col-lg-3">
              <cup-input
                #input
                [formGroup]="form"
                name="firstCode"
                placeholder="Inserisci il 1° codice"
                i18n-label
                i18n-placeholder
                i18n-helpText
                label="1° codice"
                isRequired="true"
                inputId="recipe-first-code"
                helpText="5 caratteri"
                class="text-uppercase"
                [maxlength]="5"
              />
            </div>
            <div class="col-6 col-lg-3">
              <cup-input
                #input
                [formGroup]="form"
                i18n-label
                i18n-placeholder
                i18n-helpText
                label="2° codice"
                placeholder="Inserisci il 2° codice"
                name="secondCode"
                isRequired="true"
                inputId="recipe-second-code"
                helpText="10 caratteri"
                class="text-uppercase"
                [maxlength]="10"
              />
            </div>
          </div>

          <div class="d-flex gap-4">
            <button
              [loadingLabel]="loadingLabel()"
              class="content__btn"
              itButton="primary"
              type="submit"
              i18n
            >
              Conferma
            </button>
          </div>
        </div>
      </cup-full-layout>
    </form>
  `,
  standalone: true,
})
export class SearchRecipePageComponent extends NewReservationInputRefRegister {
  formBuilder = inject(NonNullableFormBuilder);
  recipesService = inject(RecipesService);
  router = inject(Router);
  errorHandler = inject(ErrorHandler);
  route = inject(ActivatedRoute);
  sessionStorageService = inject(SessionStorageService);
  form = this.formBuilder.group({
    firstCode: ["", [Validators.required, exactLengthValidator(5)]],
    secondCode: ["", [Validators.required, exactLengthValidator(10)]],
  });

  loadingLabel = signal("");

  async handleConfirm() {
    const { firstCode, secondCode } = this.form.controls;

    const { firstCodeRef, secondCodeRef } =
      this.newReservationService.controlRefs();

    const controls = [
      {
        type: "input",
        name: "firstCode",
        control: firstCode,
        ref: firstCodeRef,
      },
      {
        type: "input",
        name: "secondCode",
        control: secondCode,
        ref: secondCodeRef,
      },
    ];

    const isValid = validateForm(controls);

    if (!isValid) return;

    try {
      this.loadingLabel.set($localize`Carico la ricetta...`);

      const recipeId = firstCode.value
        .toUpperCase()
        .concat(secondCode.value.toUpperCase());
      const isDuplicate = this.newReservationService
        .allRecipes()
        ?.find((recipe) => recipe.nre === recipeId);

      if (isDuplicate) {
        this.loadingLabel.set("");
        return this.errorHandler.handleError(
          new ResponseClientError({
            title: $localize`Errore: ricetta già presente`,
            detail: $localize`La ricetta è già presente nell'elenco ricette.`,
          })
        );
      }

      const recipe = await lastValueFrom(
        this.recipesService.searchRecipe(
          recipeId,
          this.newReservationService.submittedFiscalCode()
        )
      );

      const selectedRecipes = this.newReservationService.selectedRecipes();

      const areLabRecipesSelected = !!selectedRecipes.find(
        (recipe) => recipe.laboratorio
      );

      const areSpecialistRecipesSelected = !!selectedRecipes.find(
        (recipe) => !recipe.laboratorio
      );

      const isNotLabRecipe = !recipe.laboratorio;

      const isLabRecipe = recipe.laboratorio;

      if (
        (isNotLabRecipe && areLabRecipesSelected) ||
        (isLabRecipe && areSpecialistRecipesSelected)
      ) {
        return this.errorHandler.handleError(
          new ResponseClientError({
            title: $localize`La ricetta non è stata caricata`,
            detail: $localize`La ricetta non fa parte dello stesso tipo di prestazione. È possibile prenotare gruppi di ricette che fanno parte di un’unica prestazione alla volta.`,
          })
        );
      }

      const userRecipe = {
        ...recipe,
        isDisabled: false,
        insertedByUser: true,
      };

      const submittedFiscalCode =
        this.newReservationService.submittedFiscalCode();

      this.newReservationService.userRecipes.update((recipes) => ({
        ...recipes,
        [submittedFiscalCode]: [
          ...(recipes[submittedFiscalCode] || []),
          userRecipe,
        ],
      }));

      const recipesControl = this.newReservationService.recipesControl;
      const currentRecipes = recipesControl.value;

      recipesControl.setValue([
        ...currentRecipes,
        JSON.stringify({
          id: userRecipe.nre,
          fiscalCode: submittedFiscalCode,
        }),
      ]);

      this.sessionStorageService.updateUserRecipes(
        this.newReservationService.userRecipes() || {}
      );

      this.loadingLabel.set("");

      void this.router.navigate([".."], {
        relativeTo: this.route,
      });
    } catch (error) {
      console.error(error);

      this.loadingLabel.set("");
    }
  }
}
