import { relativeRoutesPaths, routeLinks } from "@/classes/route-utils";
import { AccessibilityPageComponent } from "@/app/pages/accessibility-page/accessibility-page.component";
import { inject } from "@angular/core";
import { Router } from "@angular/router";

const accessibilityRoute = {
  path: relativeRoutesPaths.accessibility,
  component: AccessibilityPageComponent,
  data: {
    breadcrumbItems: [routeLinks.home, routeLinks.accessibility],
  },
};

export default accessibilityRoute;
