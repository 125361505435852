import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from "@angular/core";
import { BreadcrumbComponent } from "@/molecules/breadcrumb/breadcrumb.component";
import { UserService } from "@/services/user.service";
import { FullLayoutComponent } from "@/atoms/full-layout.component";
import { UpperCasePipe } from "@angular/common";
import { ItAlertComponent } from "design-angular-kit";
import { DataRecapPanelComponent } from "@/molecules/data-recap-panel/data-recap-panel.component";
import { DataEntry } from "@/types/data-entry.interface";
import { Action } from "@/types/action.interface";
import { absoluteRoutesPaths } from "@/classes/route-utils";
import { RouterLink } from "@angular/router";

@Component({
  selector: "cup-user-profile",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BreadcrumbComponent,
    FullLayoutComponent,
    UpperCasePipe,
    ItAlertComponent,
    DataRecapPanelComponent,
    RouterLink,
  ],
  template: `
    <cup-breadcrumb />

    <cup-full-layout containerClasses="pb-5" [colConfig]="{ xs: 12, lg: 8 }">
      <h1 class="h1 mb-3">{{ userService.userJwtFullName }}</h1>
      <p class="h4 mb-5">
        <abbr title="Codice Fiscale" i18n-title>CF</abbr>:
        {{ userService.userJwtFiscalCode | uppercase }}
      </p>

      <div class="d-flex flex-column gap-4">
        @if (userService.userHasContactData()) {
          <cup-data-recap-panel
            recapTitle="Dati contatto"
            i18n-recapTitle
            [action]="contactDataAction"
            [dataEntries]="userContactEntries()"
          />
        } @else {
          <it-alert color="info">
            <ng-container heading>Dati contatto non disponibili</ng-container>

            <div class="mt-3 d-flex flex-column gap-3">
              <p class="body">
                Vengono utilizzati dal Servizio Sanitario Regionale per inviarti
                comunicazioni su appuntamenti, referti e provvedimenti sanitari
              </p>

              <a
                class="body"
                [routerLink]="absoluteRoutesPaths.userContactData"
                i18n
                >Inserisci contatti</a
              >
            </div>
          </it-alert>
        }

        @if (userService.userHasPersonalData()) {
          <cup-data-recap-panel
            recapTitle="Dati anagrafici"
            i18n-recapTitle
            [dataEntries]="userProfileEntries()"
          />
        } @else {
          <it-alert color="warning">
            <ng-container heading i18n>Anagrafica non disponibile</ng-container>

            <div class="mt-3 d-flex flex-column gap-3">
              <p class="body" i18n>
                Non sei presente nell’anagrafica sanitaria regionale.
              </p>

              <a class="body" [href]="uslHref" target="_blank" i18n
                >Scopri come registrarti</a
              >
            </div>
          </it-alert>
        }
      </div>
    </cup-full-layout>
  `,
})
export class UserProfilePageComponent {
  userService = inject(UserService);

  readonly contactDataAction: Action = {
    type: "edit-contacts",
    href: absoluteRoutesPaths.userContactData,
    label: $localize`Modifica`,
    ariaLabel: $localize`Modifica dati di contatto`,
  };

  userContactEntries = computed<DataEntry[]>(() => {
    const contactData = this.userService.user()?.contactData;

    if (!contactData) return [];

    return [
      {
        label: $localize`Indirizzo email`,
        value: contactData.email,
        format: "none",
      },
      {
        label: $localize`Numero di cellulare`,
        value: contactData.mobile,
      },
    ];
  });

  userProfileEntries = computed<DataEntry[]>(() => {
    const personalData = this.userService.user()?.personalData;

    if (!personalData) return [];

    return [
      {
        label: $localize`Cognome`,
        value: personalData.cognome,
      },
      {
        label: $localize`Nome`,
        value: personalData.nome,
      },
      {
        label: $localize`Codice fiscale`,
        value: personalData.codiceFiscale,
        format: "uppercase",
      },
      {
        label: $localize`Sesso`,
        value: personalData.sesso,
      },
      {
        label: $localize`Data di nascita`,
        value: personalData.dataNascita,
        format: "date",
      },
      {
        label: $localize`Comune di nascita`,
        value: personalData.descrizioneComuneNascita,
      },
      {
        label: $localize`Indirizzo di residenza`,
        value: personalData.indirizzoResidenza,
      },
      {
        label: $localize`Indirizzo di domicilio`,
        value: personalData.indirizzoDomicilio,
      },
    ];
  });

  protected readonly absoluteRoutesPaths = absoluteRoutesPaths;

  readonly uslCodeHrefMap = {
    201: "https://www.uslumbria1.it/servizi/anagrafe-assistibili",
    202: "https://www.uslumbria2.it/servizi/elenco-cup-e-farmacup",
  } as const;

  get uslHref() {
    if (this.userService.userHasPersonalData()) {
      const uslCode = this.userService.user()?.personalData
        ?.codiceAslAssistenza as "201" | "202";

      return this.uslCodeHrefMap[uslCode];
    }

    // when no information about the usl is available, the link is selected
    // randomically
    const randomKey = `20${Math.round(Math.random() + 1)}` as "201" | "202";
    return this.uslCodeHrefMap[randomKey];
  }
}
