import { relativeRoutesPaths, routeLinks } from "@/classes/route-utils";
import { RecipeDetailPageComponent } from "@/app/pages/recipe-detail-page/recipe-detail-page.component";
import { ActivatedRouteSnapshot, Route } from "@angular/router";
import { inject } from "@angular/core";
import { RecipesService } from "@/services/recipes.service";
import { NewReservationService } from "@/services/new-reservation.service";

const recipeDetailRoute: Route = {
  path: relativeRoutesPaths.recipeDetailFromNewReservation,
  component: RecipeDetailPageComponent,
  resolve: {
    recipe: (activatedRouteSnapshot: ActivatedRouteSnapshot) => {
      const reservationService = inject(NewReservationService);

      return inject(RecipesService).getRecipeURL(
        activatedRouteSnapshot.params["recipe-id"],
        reservationService.submittedFiscalCode()
      );
    },
  },
  data: {
    breadcrumbItems: [
      routeLinks.home,
      routeLinks.myReservations,
      routeLinks.newReservation,
      routeLinks.newReservationRecipeDetail,
    ],
  },
};

export default recipeDetailRoute;
