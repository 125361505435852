import { FormControl } from "@angular/forms";

export default function validateForm(
  formControls: {
    control: FormControl;
    ref: HTMLElement;
  }[]
) {
  const isValid = formControls.every(({ control }) => control.valid);

  if (!isValid) {
    formControls.forEach(({ control }) => {
      control.markAsTouched();
      control.updateValueAndValidity();
    });
  }

  const invalidControl = formControls.find(({ control }) => control.invalid);

  if (invalidControl) {
    invalidControl.ref.focus();
    invalidControl.ref.classList.add("custom-focus");

    const removeCustomFocusClass = () => {
      invalidControl.ref.classList.remove("custom-focus");
      invalidControl.ref.removeEventListener("blur", removeCustomFocusClass);
    };

    invalidControl.ref.addEventListener("blur", removeCustomFocusClass);
  }

  return isValid;
}
