import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { RouterLink } from "@angular/router";

@Component({
  selector: "cup-table-link",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink],
  template: `
    <a [routerLink]="anchorHref()" class="btn link" i18n>{{ label() }}</a>
  `,
  standalone: true,
})
export class TableLinkComponent {
  anchorHref = input.required<string>();
  label = input.required<string>();
}
