import { Injectable, signal } from "@angular/core";

@Injectable()
export class StepperService {
  loadingLabel = signal("");

  resetLoadingLabel() {
    this.loadingLabel.set("");
  }
}
