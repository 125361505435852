import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  linkedSignal,
  OnInit,
  signal,
} from "@angular/core";
import { TableComponent } from "@/components/molecules/table.component";
import { InputSearchComponent } from "@/components/molecules/input-search.component";
import { ReservationFiltersComponent } from "@/molecules/reservation-filters.component";
import { HeroComponent } from "@/components/molecules/hero.component";
import { FullLayoutComponent } from "@/components/atoms/full-layout.component";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { BreadcrumbComponent } from "@/molecules/breadcrumb/breadcrumb.component";
import { ActivatedRoute } from "@angular/router";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { combineLatest, map, switchMap, takeUntil, tap } from "rxjs";
import { ReservationsHistoryResponseBody } from "@/types/api";
import { FormProviderService } from "@/services/form-provider.service";
import { FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { DatePipe, TitleCasePipe } from "@angular/common";
import {
  MapReservationDataPipe,
  ReservationDataMappingOptions,
} from "@/pipes/map-reservation-data.pipe";
import { ReservationService } from "@/services/reservation.service";
import { Destroyable } from "@/classes/destroyable";
import { filter } from "rxjs/operators";
import { FIRST_OF_THE_YEAR, YESTERDAY } from "@/utils/constants";
import { DeriveReservationPaymentStatusPipe } from "@/pipes/derive-reservation-payment-status.pipe";

@Component({
  selector: "cup-reservation-history",
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("filters", [
      state(
        "visible",
        style({
          transform: "none",
          opacity: 1,
        })
      ),
      transition(":enter", [
        style({
          transform: "translateY(4px)",
          opacity: 0,
        }),
        animate(
          "250ms ease-in-out",
          style({
            transform: "translateX(0)",
            opacity: 1,
          })
        ),
      ]),
      transition(":leave", [
        animate(
          "250ms ease-in-out",
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
  providers: [FormProviderService, DeriveReservationPaymentStatusPipe],
  imports: [
    TableComponent,
    InputSearchComponent,
    ReservationFiltersComponent,
    HeroComponent,
    FullLayoutComponent,
    BreadcrumbComponent,
    ReactiveFormsModule,
    MapReservationDataPipe,
    DatePipe,
    TitleCasePipe,
  ],
  styles: `
    @use "cup";

    cup-table {
      display: block;
      background-color: white;
      position: relative;
      z-index: 1;
    }

    cup-input-search {
      display: block;
      transition: margin-bottom 200ms ease-in-out;

      margin-bottom: cup.toRem(32);

      @include cup.media-breakpoint-up(lg) {
        margin-bottom: cup.toRem(48);
      }

      &[data-show-filters="true"] {
        margin-bottom: cup.toRem(216);

        @include cup.media-breakpoint-up(lg) {
          margin-bottom: cup.toRem(224);
        }
      }
    }

    cup-reservation-filters {
      display: block;
      position: absolute;
      width: 100%;
      top: cup.toRem(154);

      @include cup.media-breakpoint-up(lg) {
        top: cup.toRem(104);
      }
    }

    .filters-wrapper {
      position: relative;
    }
  `,
  template: `
    <cup-breadcrumb />

    <cup-hero
      i18n-heroTitle
      i18n-heroCopy
      heroTitle="Storico prenotazioni"
      heroCopy="In questa sezione puoi consultare le prenotazioni di visite specialistiche o esami di laboratorio con data appuntamento antecedente ad oggi."
    />

    <div class="filters-wrapper" [formGroup]="form">
      <cup-full-layout>
        <cup-input-search
          showFiltersButton="true"
          formControlName="searchKey"
          [isResetBtnDisabled]="isResetBtnDisabled()"
          (reset)="handleReset()"
          [attr.data-show-filters]="showFilters()"
          [showFilters]="showFilters()"
          (search)="handleSearch()"
          (toggleFilters)="handleToggleFilters()"
        />
      </cup-full-layout>

      @if (showFilters()) {
        <cup-reservation-filters
          [@filters]="'visible'"
          [services]="services()"
        />
      }
    </div>

    @let tableData = linkedReservationData();
    @let submittedValues = submittedFormValue();

    <cup-full-layout colClass="d-flex flex-column gap-2 mb-4">
      <h2 class="h3" i18n>Risultati ricerca</h2>
      @if (submittedValues.searchKey) {
        <p i18n>
          Risultati per testo <strong>"{{ submittedValues.searchKey }}"</strong>
        </p>
      }

      @let formData = formValue();

      @let from = formData?.from;
      @let to = formData?.to;
      @let serviceType = formData?.serviceType;

      @if (from && to) {
        <p i18n>
          Prenotazioni dal
          <strong>{{ from | date: "dd/MM/yyyy" }}</strong> al
          <strong>{{ to | date: "dd/MM/yyyy" }}</strong>
        </p>
      }

      @if (serviceType) {
        <p i18n>
          Tipologia di prestazione:
          <strong>{{ serviceType | titlecase }}</strong>
        </p>
      }
    </cup-full-layout>

    @if (tableData) {
      <cup-table
        [data]="dataConfig() | mapReservationData"
        [currentPage]="currentPage()"
        (pageChange)="currentPage.set($event)"
      />
    }

    <ng-template #link let-reservationId></ng-template>
  `,
  standalone: true,
})
export class ReservationHistoryPageComponent
  extends Destroyable
  implements OnInit
{
  route = inject(ActivatedRoute);
  formBuilder = inject(FormBuilder);
  formProviderService = inject(FormProviderService);
  reservationService = inject(ReservationService);
  currentPage = signal(0);

  form = this.formBuilder.nonNullable.group({
    searchKey: "",
    serviceType: "",
    from: "2000-01-01", //FIRST_OF_THE_YEAR.split("T")[0],
    to: YESTERDAY.split("T")[0],
  });

  formValue = toSignal(this.form.valueChanges);
  serviceType = toSignal(this.form.controls.serviceType.valueChanges);

  isResetBtnDisabled = computed(
    () => !Object.values(this.submittedFormValue).length
  );

  data = toSignal<ReservationsHistoryResponseBody>(
    this.route.data.pipe(map((routeData) => routeData["reservations"]))
  );

  dataConfig = computed<ReservationDataMappingOptions>(() => ({
    type: "history",
    data: this.data(),
    additionalFilters: [
      {
        value: this.serviceType(),
        type: "full-text",
      },
      {
        value: this.submittedFormValue().searchKey,
        type: "full-text",
      },
    ],
  }));

  resetSearchKey = effect(() => {
    if (!this.formValue()?.searchKey) {
      this.submittedFormValue.update((v) => ({
        ...v,
        searchKey: "",
      }));
    }
  });

  submittedFormValue = signal<typeof this.form.value>({});
  showFilters = signal(false);

  linkedReservationData = linkedSignal(() => this.data());

  services = computed<string[]>(() => {
    const reservations = this.data();
    if (!reservations) return [];

    return reservations.appuntamenti.reduce<string[]>(
      (acc, cur) => [
        ...acc,
        ...(acc.find(
          (reservation) =>
            reservation === cur.unitaErogante.descrizioneDisciplina
        )
          ? []
          : [cur.unitaErogante.descrizioneDisciplina]),
      ],
      []
    );
  });

  constructor() {
    super();

    toObservable(this.serviceType)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.currentPage.set(0));
  }

  ngOnInit() {
    this.formProviderService.initServiceForm(this.form);

    combineLatest([
      this.form.controls.from.valueChanges,
      this.form.controls.to.valueChanges,
    ])
      .pipe(
        tap(() => this.currentPage.set(0)),
        filter(
          ([from, to]) =>
            !!from && !!to && new Date(to).getTime() >= new Date(from).getTime()
        ),
        switchMap((params) => this.filterByDateRange(params)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  async filterByDateRange([from, to]: [string, string]) {
    if (!from || !to) return;

    const response = await new Promise<ReservationsHistoryResponseBody>(
      (resolve) => {
        this.reservationService
          .getReservationHistory({
            ...(from && { from: new Date(from).toISOString().split(".")[0] }),
            to: to
              ? new Date(`${to}T23:59:59`).toISOString().split(".")[0]
              : YESTERDAY,
          })
          .subscribe((response) => resolve(response));
      }
    );

    this.linkedReservationData.set(response);
  }

  async handleSearch() {
    const { serviceType, searchKey, from, to } = this.form.value;

    this.currentPage.set(0);

    this.submittedFormValue.set({
      serviceType,
      searchKey,
      from,
      to,
    });
  }

  handleReset() {
    this.submittedFormValue.set({});
    this.linkedReservationData.set(this.data());
  }

  handleToggleFilters() {
    this.showFilters.update((show) => !show);
  }
}
