import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  OnInit,
  signal,
} from "@angular/core";
import { NewReservationService } from "@/services/new-reservation.service";
import { AppointmentCardComponent } from "@/molecules/appointment-card/appointment-card.component";
import { InputComponent } from "@/atoms/input/input.component";
import { FormBuilder, FormControl, FormsModule } from "@angular/forms";
import { ItButtonDirective, ItCheckboxComponent } from "design-angular-kit";
import { DatePipe, TitleCasePipe } from "@angular/common";
import { takeUntil } from "rxjs";
import { GoogleMapPipe } from "@/pipes/google-map.pipe";
import { Destroyable } from "@/classes/destroyable";
import { CupAlertComponent } from "@/molecules/alert/cup-alert.component";
import { CheckboxComponent } from "@/atoms/checkbox/checkbox.component";
import { Agenda, Calendario } from "@/types/api";
import { ONE_DAY } from "@/utils/constants";
import { StepperService } from "@/services/stepper.service";
import { AppointmentReservationService } from "@/services/appointment-reservation.service";
import { LoadingService } from "@/services/loading.service";

@Component({
  selector: "cup-appointment-step",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [AppointmentReservationService],
  imports: [
    AppointmentCardComponent,
    InputComponent,
    ItCheckboxComponent,
    FormsModule,
    DatePipe,
    TitleCasePipe,
    ItButtonDirective,
    GoogleMapPipe,
    CupAlertComponent,
    CheckboxComponent,
  ],
  styles: `
    @use "cup";

    .title {
      @include cup.media-breakpoint-up(lg) {
        margin-bottom: cup.toRem(24);
      }
    }

    .load-more-btn {
      margin: cup.toRem(24) auto 0;
      display: block;
      width: 100%;

      @include cup.media-breakpoint-up(lg) {
        width: auto;
      }
    }

    .appointments-grid {
      display: grid;
      gap: cup.toRem(24);
      grid-template-columns: 1fr;
      @include cup.media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  `,
  template: `
    @let appointments = newReservationService.pageAppointments();

    @let appointmentsAvailabe =
      appointmentReservationService.areAppointmentsAvailable();

    @let waitingListReservation =
      newReservationService.waitingListReservation();

    @let appointmentsAvailable =
      newReservationService.filteredAppointments().length > 0;

    @let isUserDistrictSelected =
      newReservationService.appointmentsFilters().isUserDistrictOnly;

    @let waitingListReservationExist = !!waitingListReservation;

    <h2 class="title h3 mb-3">
      <ng-container i18n>Prenota appuntamento</ng-container>&colon;
      {{ newReservationService.mappedRecipeCategory() }}
    </h2>

    <div class="row gap-4 align-items-end gap-lg-0">
      @if (appointmentsAvailable) {
        <div class="col-12 col-lg-4">
          <cup-input
            type="date"
            [min]="tomorrow"
            i18n-label
            label="Seleziona data"
            inputId="appointamentDate"
            name="date"
            [formGroup]="appointmentFiltersForm"
          />
        </div>
      }

      @if (!appointmentReservationService.isNonRegionalUser()) {
        <div
          class="col-12 col-lg-5"
          [class.col-lg-12]="!appointmentsAvailable"
          [class.offset-lg-3]="appointmentsAvailabe"
        >
          <it-checkbox
            [ngModel]="userDistrictOnly()"
            (ngModelChange)="handleDistrictChange($event)"
            [label]="districtLabel"
            toggle="true"
          />
        </div>
      }
    </div>

    <div class="mt-4 mb-5">
      @let alert = appointmentReservationService.alertData();

      @if (!isSearchingAvailability() && alert) {
        <cup-alert
          color="warning"
          class="d-block mb-4"
          [action]="alert.action"
          [headingText]="alert.title"
          [copyText]="alert.message"
        />
      }

      @if (
        appointmentReservationService.showWaitingListCheckbox() &&
        waitingListReservation
      ) {
        <cup-checkbox
          #input
          inputId="waitingList"
          name="waitingList"
          [value]="waitingListReservation.availableHours[0].value"
          [isRequired]="true"
          [formGroup]="newReservationService.reservation"
          i18n-label
          label="Voglio aggiungermi ad una lista d'attesa"
        />
      }
    </div>

    @for (appointmentsPage of appointments; track appointmentsPage.date) {
      @let length = appointmentsPage.availableAppointments.length;

      <h3 aria-live="polite" class="mt-4">
        {{ appointmentsPage.date | date: "dd MMMM yyyy" | titlecase }}
      </h3>

      <p class="mt-2 mb-4">
        {{ length }}

        @if (length === 1) {
          <ng-container i18n>struttura disponibile</ng-container>
        } @else {
          <ng-container i18n>strutture disponibili</ng-container>
        }
      </p>

      <div class="appointments-grid">
        @for (
          appointment of appointmentsPage.availableAppointments;
          track appointment.id
        ) {
          <cup-appointment-card
            controlName="appointment"
            [formGroup]="newReservationService.reservation"
            [cardTitle]="appointment.building"
            [location]="appointment.location"
            address="{{ appointment.address }} - {{ appointment.city }}"
            [mapHref]="
              appointment.address + ', ' + appointment.city | googleMap
            "
            [date]="appointment.date"
            [availableHours]="appointment.availableHours"
          />
        }
      </div>
    }

    @if (
      !newReservationService.appointmentsFilters().date &&
      newReservationService.appointmentsIndex() <
        newReservationService.filteredAppointments().length - 1
    ) {
      <button
        (click)="loadMore()"
        class="load-more-btn"
        itButton="outline-primary"
        i18n
      >
        Mostra altre date
      </button>
    }
  `,
})
export class AppointmentStepComponent extends Destroyable implements OnInit {
  newReservationService = inject(NewReservationService);
  appointmentReservationService = inject(AppointmentReservationService);
  stepperService = inject(StepperService);
  loadingService = inject(LoadingService);
  formBuilder = inject(FormBuilder);
  isSearchingAvailability = signal(false);

  appointmentFiltersForm = this.formBuilder.nonNullable.group({
    date: [this.newReservationService.appointmentsFilters().date],
  });

  userDistrictOnly = computed(
    () => this.newReservationService.appointmentsFilters().isUserDistrictOnly
  );

  searchAvailabilityOnDistrictChange = effect(async () => {
    const isUserDistrictOnly = this.userDistrictOnly();
    this.isSearchingAvailability.set(true);

    const id = setTimeout(() => {
      this.stepperService.loadingLabel.set(
        isUserDistrictOnly
          ? $localize`Ricerco le disponibilità per il distretto dell'utente...`
          : $localize`Ricerco le disponibilità per l'intera regione...`
      );
    }, 200);

    await this.newReservationService.updateReservationAvailability(
      isUserDistrictOnly
    );

    this.newReservationService.sessionStorageService.updateReservationUserDistrict(
      isUserDistrictOnly
    );

    this.isSearchingAvailability.set(false);
    this.stepperService.resetLoadingLabel();
    clearTimeout(id);
  });

  ngOnInit() {
    this.updateFiltersStateOnDateChange();
  }

  get tomorrow() {
    return new Date(Date.now() + ONE_DAY).toISOString().split("T")[0];
  }

  get dateControl() {
    return this.appointmentFiltersForm.get("date") as FormControl<string>;
  }

  get districtLabel() {
    return $localize`Solo strutture del mio distretto ${this.newReservationService.userDistrict()?.descrizione}`;
  }

  updateFiltersStateOnDateChange() {
    this.dateControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((date) => {
        this.newReservationService.sessionStorageService.updateReservationAppointmentDateFilter(
          date
        );

        this.newReservationService.appointmentsFilters.update((filters) => ({
          ...filters,
          date,
        }));
      });
  }

  handleDistrictChange(isUserDistrictOnly: boolean) {
    this.newReservationService.appointmentsFilters.update((filters) => ({
      date: filters.date,
      isUserDistrictOnly,
    }));
  }

  loadMore() {
    this.newReservationService.appointmentsIndex.update((i) => i + 1);
  }
}

export interface AppointmentValue {
  agenda: Agenda;
  calendar: Calendario;
}
