import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  viewChild,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CheckboxInputComponent } from "@/atoms/checkbox-input/checkbox-input.component";
import { DatePipe } from "@angular/common";
import { ActionButtonComponent } from "@/atoms/action-button/action-button.component";
import { ItBadgeDirective } from "design-angular-kit";
import { Ricetta } from "@/types/api";
import { CheckboxBase } from "@/classes/checkbox-base";

@Component({
  selector: "cup-recipe-card",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CheckboxInputComponent,
    DatePipe,
    ActionButtonComponent,
    ItBadgeDirective,
  ],
  styles: `
    @use "cup";

    .card-checkbox {
      position: absolute;
      top: cup.toRem(24);
      right: cup.toRem(24);
    }

    cup-action-button[data-delete] {
      position: absolute;
      bottom: cup.toRem(24);
      left: cup.toRem(24);

      + cup-action-button {
        left: cup.toRem(144);
      }
    }

    cup-action-button {
      position: absolute;
      bottom: cup.toRem(24);
      left: cup.toRem(24);
    }
  `,
  template: `
    @let data = recipe();

    <div class="h-100 position-relative">
      <div
        class="cup-reservation-card text-body"
        (click)="checkboxInput.handleChange()"
        [class.cup-reservation-card--disabled]="checkboxInput.isDisabled()"
        [class.cup-reservation-card--checked]="checkboxInput.isChecked()"
      >
        <span class="d-flex flex-column">
          <span class="h5" [id]="recipeTitleId" i18n
            ><label [for]="inputId()"
              >Ricetta nr&period; {{ data.nre }}</label
            ></span
          >

          <span [id]="recipeVisitsId" class="mt-1 text-secondary">
            @if (data.laboratorio) {
              <ng-container i18n>Esami di laboratorio</ng-container>
            } @else {
              @for (visit of data.prestazioni; track visit.prescrizione1) {
                @if (!$last) {
                  {{ visit.prescrizione1 }}
                  <br />
                } @else {
                  {{ visit.prescrizione1 }}
                }
              }
            }
          </span>

          @if (data.insertedByUser) {
            <span
              class="mt-2 py-1 px-3 align-self-start"
              itBadge="primary"
              i18n
            >
              Inserita manualmente
            </span>
          }
        </span>
        <span class="d-flex flex-column gap-1">
          <span class="text-secondary">
            <ng-container i18n> Data di emissione</ng-container>&colon;
            <strong>{{ data.dataCompilazione | date: "dd/MM/yyyy" }}</strong>
          </span>

          <span class="text-secondary">
            <ng-container i18n>Autore</ng-container>&colon;
            <strong>{{ doctorName }}</strong>
          </span>
        </span>
      </div>

      <cup-checkbox-input
        class="card-checkbox"
        #checkboxInput
        [isDisabled]="isDisabled()"
        [name]="name()"
        [value]="value()"
        [ariaLabelledby]="recipeTitleId"
        [ariaDescribedby]="recipeVisitsId"
        [formGroup]="formGroup()"
        [inputId]="inputId()"
      />

      @if (showRemoveButton()) {
        <cup-action-button
          data-delete
          [action]="{
            severity: 'high',
            type: 'remove-recipe',
            label: removeRecipeLabel,
            icon: 'delete',
            isIconOnRight: false,
            ariaLabel: removeRecipeActionLabel,
          }"
          (actionClick)="removeRecipe.emit()"
        />
      }

      <cup-action-button
        [action]="{
          type: 'view-detail',
          label: viewRecipeLabel,
          icon: 'arrow-right',
          isIconOnRight: true,
          ariaLabel: actionLabel,
        }"
        href="dettaglio-ricetta/{{ data.nre }}"
      />
    </div>
  `,
})
export class RecipeCardComponent extends CheckboxBase {
  recipe = input.required<Ricetta>();
  checkboxInput = viewChild.required(CheckboxInputComponent);

  showRemoveButton = input(false);
  removeRecipe = output<void>();

  get actionLabel() {
    return `${$localize`Visualizza ricetta per`} ${this.servicesPrescriptions.join(" ")}`;
  }

  get removeRecipeActionLabel() {
    return $localize`Rimuovi la ricetta ${this.recipe().nre}`;
  }

  get servicesPrescriptions() {
    return this.recipe().prestazioni.map((p) => p.prescrizione1);
  }

  get doctorName() {
    const { nomeMed, cognomeMed } = this.recipe();

    return `${nomeMed} ${cognomeMed}`;
  }

  readonly viewRecipeLabel = $localize`Visualizza`;
  readonly removeRecipeLabel = $localize`Rimuovi`;

  get recipeTitleId() {
    return `recipe-${this.recipe().nre}-title`;
  }

  get recipeVisitsId() {
    return `recipe-${this.recipe().nre}-details`;
  }

  override get inputRef() {
    return this.checkboxInput().inputRef;
  }
}
