import { Pipe, PipeTransform } from "@angular/core";
import {
  ONE_DAY,
  ONE_HOUR,
  ONE_MINUTE,
  ONE_SECOND,
  ONE_WEEK,
} from "@/utils/constants";

export type ChangeDateType = "second" | "minute" | "hour" | "day" | "week";

@Pipe({ standalone: true, name: "changeDate" })
export class ChangeDatePipe implements PipeTransform {
  transform(value: string, amount: number, type: ChangeDateType = "day") {
    const originalDate = new Date(value);
    const time = originalDate.getTime();

    switch (type) {
      case "second":
        return new Date(time + amount * ONE_SECOND).toISOString();

      case "minute":
        return new Date(time + amount * ONE_MINUTE).toISOString();

      case "hour":
        return new Date(time + amount * ONE_HOUR).toISOString();

      case "day":
        return new Date(time + amount * ONE_DAY).toISOString();

      case "week":
        return new Date(time + amount * ONE_WEEK).toISOString();
    }
  }
}
