import {
  AfterViewInit,
  Directive,
  inject,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { NewReservationService } from "@/services/new-reservation.service";
import { Destroyable } from "@/classes/destroyable";
import { startWith, takeUntil } from "rxjs";

@Directive()
export class NewReservationInputRefRegister
  extends Destroyable
  implements AfterViewInit
{
  newReservationService = inject(NewReservationService);

  @ViewChildren("input")
  inputRefs!: QueryList<unknown>;

  ngAfterViewInit() {
    this.inputRefs.changes
      .pipe(startWith(this.inputRefs), takeUntil(this.destroy$))
      .subscribe((changes) => {
        changes.forEach((ref: { inputRef: HTMLInputElement }) => {
          this.newReservationService.controlRefs.update((refs) => ({
            ...refs,
            [`${ref.inputRef.name}Ref`]: ref.inputRef,
          }));
        });
      });
  }
}
