import { $localize } from "@angular/localize/init";

const priorityMap = {
  U: $localize`Urgente`,
  B: $localize`Breve`,
  D: $localize`Differibile`,
  P: $localize`Programmabile`,
};

export default priorityMap;
