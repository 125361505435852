import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { DatePipe, TitleCasePipe } from "@angular/common";
import { ItIconComponent } from "design-angular-kit";
import { RouterLink } from "@angular/router";
import {
  PaymentBadgeComponent,
  PaymentStatus,
} from "@/atoms/payment-badge/payment-badge.component";

@Component({
  selector: "cup-table-mobile-card",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DatePipe,
    ItIconComponent,
    RouterLink,
    PaymentBadgeComponent,
    TitleCasePipe,
  ],
  styles: `
    @use "cup";

    a {
      text-decoration: none;
    }

    .payment-badge {
      display: block;
      margin: cup.toRem(24) 0;
    }

    .service-name {
      margin-bottom: cup.toRem(16);
    }

    [itBadge] {
      margin: cup.toRem(24) 0;
      padding: cup.toRem(4) cup.toRem(16);
    }
  `,
  template: `
    <h3 class="h5 text-success service-name">
      {{ serviceName() | titlecase }}
    </h3>

    <p class="text-with-icon mb-0 pb-2">
      <it-icon name="calendar" color="primary" />
      {{
        paymentStatus() === "waiting-list"
          ? dateTime()
          : (dateTime() | date: "dd MMMM yyyy, ore h:mm")
      }}
    </p>

    <p class="text-with-icon mb-0">
      <it-icon name="map-marker" color="primary" />
      {{ location() | titlecase }}
    </p>

    <cup-payment-badge
      class="payment-badge"
      [paymentStatus]="paymentStatus()"
    />

    <a
      [routerLink]="id().toString()"
      class="btn link d-flex align-items-center gap-2 text-start"
    >
      Visualizza Dettaglio
      <it-icon name="arrow-right" color="primary" />
    </a>
  `,
})
export class TableMobileCardComponent {
  id = input.required<number>();
  serviceName = input.required<string>();
  dateTime = input.required<string>();
  location = input.required<string>();
  paymentStatus = input.required<PaymentStatus>();
}
