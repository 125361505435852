import { relativeRoutesPaths, routeLinks } from "@/classes/route-utils";
import { ReservationDetailPageComponent } from "@/app/pages/reservation-detail-page/reservation-detail-page.component";
import { ActivatedRouteSnapshot, Route } from "@angular/router";
import { inject } from "@angular/core";
import { ReservationService } from "@/services/reservation.service";

const otherReservationsDetailRoute: Route = {
  path: relativeRoutesPaths.othersReservationsDetails,
  component: ReservationDetailPageComponent,
  resolve: {
    reservation: (route: ActivatedRouteSnapshot) => {
      const fiscalCode = route.queryParamMap.get("cf");
      const reservationNumber = route.paramMap.get("id");
      const reservationService = inject(ReservationService);

      if (!fiscalCode || !reservationNumber) {
        return;
      }

      return reservationService.getReservationDetail({
        cf: fiscalCode,
        codPrenotazione: reservationNumber,
      });
    },
    breadcrumbItems: (route: ActivatedRouteSnapshot) => [
      routeLinks.home,
      routeLinks.othersReservations,
      routeLinks.reservationHistoryDetail(+route.params["id"]),
    ],
  },
};

export default otherReservationsDetailRoute;
