import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from "@angular/core";
import { BreadcrumbComponent } from "@/molecules/breadcrumb/breadcrumb.component";
import { FullLayoutComponent } from "@/atoms/full-layout.component";

@Component({
  selector: "cup-accessibility",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BreadcrumbComponent, FullLayoutComponent],
  template: `
    <cup-breadcrumb />

    <cup-full-layout containerClasses="pb-5" [colConfig]="{ xs: 12, lg: 12 }">
      <h1 class="h1 mb-3" i18n>Accessibilità</h1>
      <div class="d-flex flex-column gap-4">
        <div class="mt-3 d-flex flex-column gap-3">
          <p class="body" i18n>
            Al fine di ottemperare agli adempimenti in tema di accessibilità di
            cui alla Legge sulle "Disposizioni per favorire l'accesso dei
            soggetti disabili agli strumenti informatici",
            <a
              href="https://www.normattiva.it/uri-res/N2Ls?urn:nir:stato:legge:2004-01-09;4!vig=2025-02-15"
              target="_blank"
              >Legge 4 del 9 gennaio 2004</a
            >
            e delle
            <a
              href="https://docs.italia.it/AgID/documenti-in-consultazione/lg-accessibilita-docs/it/stabile/index.html"
              target="_blank"
              >Linee guida sull'accessibilità degli strumenti informatici</a
            >.
          </p>
          <p class="body" i18n>
            L'attenzione posta a garantire tali requisiti ha consentito
            assicurare la conformità del sito ai medesimi.
          </p>
          <p class="body" i18n>
            E' nostra cura garantire nel tempo questo stato eseguendo
            periodicamente le verifiche di conformità prescritte dalle norme.
          </p>
          <h2 class="h2 mb-3" i18n>Stato di conformità</h2>
          <p class="body" i18n>
            Il sito web "CUP Umbria" è conforme ai requisiti previsti
            dall'appendice A della norma UNI CEI EN 301549.
          </p>
          <p class="body" i18n>
            Informazioni in merito sono disponibili al link
            <a
              href="https://form.agid.gov.it/view/589f9110-056a-11f0-b364-8f72f43da62b"
              target="_blank"
              >dichiarazione di accessibilità</a
            >
            pubblicata nel sito AGID.
          </p>
          <h2 class="h2 mb-3" i18n>Il tuo contributo è importante</h2>
          <p class="body" i18n>
            Ci scusiamo fin d'ora per eventuali problemi di accessibilità che
            potranno essere segnalati utilizzando il form
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeOPik38eCpDrC1DQ9_jJa84PjASSXm_4uX3fZqpod1KvB1zQ/viewform"
              data-anchor="?usp=pp_url"
              target="_blank"
              >Feedback Accessibilità</a
            >.
          </p>
        </div>
      </div>
    </cup-full-layout>
  `,
})
export class AccessibilityPageComponent {}
