import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
  viewChild,
} from "@angular/core";
import { FullLayoutComponent } from "@/components/atoms/full-layout.component";
import { HeroComponent } from "@/components/molecules/hero.component";
import { Action } from "@/types/action.interface";
import {
  ItButtonDirective,
  ItCardComponent,
  ItIconComponent,
  ItModalComponent,
  ItNotificationService,
} from "design-angular-kit";
import {
  PaymentBadgeComponent,
  PaymentStatus,
} from "@/components/atoms/payment-badge/payment-badge.component";
import { DatePipe, NgTemplateOutlet, TitleCasePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ChangeDatePipe } from "@/pipes/change-date/change-date";
import { ReservationDetailSectionComponent } from "@/molecules/reservation-detail-section.component";
import { toSignal } from "@angular/core/rxjs-interop";
import { lastValueFrom, map, takeUntil } from "rxjs";
import { GoogleMapPipe } from "@/pipes/google-map.pipe";
import { absoluteRoutesPaths } from "@/classes/route-utils";
import { BreadcrumbComponent } from "@/molecules/breadcrumb/breadcrumb.component";
import { ReservationDetailResponseBody } from "@/types/api";
import { DeriveReservationPaymentStatusPipe } from "@/pipes/derive-reservation-payment-status.pipe";
import { ReservationService } from "@/services/reservation.service";
import downloadBlob from "@/utils/functions/download-blob";
import { $localize } from "@angular/localize/init";
import priorityMap from "@/utils/priority-map";
import { ButtonComponent } from "@/atoms/button/button.component";
import { Destroyable } from "@/classes/destroyable";

@Component({
  selector: "cup-reservation-detail",
  providers: [DeriveReservationPaymentStatusPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FullLayoutComponent,
    HeroComponent,
    PaymentBadgeComponent,
    DatePipe,
    ButtonComponent,
    ItIconComponent,
    ItCardComponent,
    ReservationDetailSectionComponent,
    NgTemplateOutlet,
    ChangeDatePipe,
    TitleCasePipe,
    GoogleMapPipe,
    ItModalComponent,
    ItButtonDirective,
    BreadcrumbComponent,
    DeriveReservationPaymentStatusPipe,
  ],
  styles: `
    @use "cup";

    .document-card {
      display: flex;
      align-items: center;
      gap: cup.toRem(16);
      color: cup.$color-text-primary;
      margin-bottom: 0;
    }
  `,
  templateUrl: "./reservation-detail-page.component.html",
  standalone: true,
})
export class ReservationDetailPageComponent extends Destroyable {
  /* Injected Services */
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  reservationService = inject(ReservationService);
  notificationService = inject(ItNotificationService);
  derivePaymentStatus = inject(DeriveReservationPaymentStatusPipe);
  loadingMessage = signal("");

  cf = toSignal(
    this.activatedRoute.queryParams.pipe(
      map((params) => params["cf"]),
      takeUntil(this.destroy$)
    )
  );

  readonly documentsStatusMap: Record<
    PaymentStatus,
    { label: string; handleClick(): void }[]
  > = {
    paid: [
      {
        label: this.reservationService.reservationAttachmentLabel,
        handleClick: () => this.downloadAttachment(),
      },
      {
        label: $localize`Documento fiscale`,
        handleClick: () => this.downloadPaymentReceipt(),
      },
    ],
    "to-pay": [
      {
        label: this.reservationService.reservationAttachmentLabel,
        handleClick: () => this.downloadAttachment(),
      },
      {
        label: $localize`Avviso di pagamento`,
        handleClick: () => this.downloadPaymentNotice(),
      },
    ],
    "waiting-list": [],
  };

  readonly heroActionsConfig: {
    main: Record<PaymentStatus, Action[]>;
    side: Record<PaymentStatus, Action[]>;
  } = {
    main: {
      paid: [
        {
          label: $localize`Scarica prenotazione`,
          onClick: () => this.downloadAttachment(),
        },
      ],
      "to-pay": [
        {
          label: $localize`Paga online`,
          onClick: () =>
            this.reservationService.payReservationOnline(
              this.reservationNumber(),
              this.cf()
            ),
        },
        {
          relevance: "secondary-task",
          label: $localize`Scarica prenotazione`,
          onClick: () => this.downloadAttachment(),
        },
      ],
      "waiting-list": [
        {
          label: $localize`Scarica promemoria`,
          onClick: () => this.downloadAttachment(),
        },
      ],
    },
    side: {
      paid: [
        {
          label: $localize`Scarica ricevuta di pagamento`,
          icon: "download",
          onClick: () => this.downloadPaymentReceipt(),
        },
        {
          label: $localize`Annulla prenotazione`,
          icon: "delete",
          severity: "high",
          onClick: () => this.openCancelReservationModal(),
        },
      ],
      "to-pay": [
        {
          label: $localize`Scarica avviso di pagamento`,
          icon: "download",
          onClick: () => this.downloadPaymentNotice(),
        },
        {
          label: $localize`Annulla prenotazione`,
          icon: "delete",
          severity: "high",
          onClick: () => this.openCancelReservationModal(),
        },
      ],
      "waiting-list": [
        {
          label: $localize`Annulla richiesta`,
          icon: "delete",
          severity: "high",
          onClick: () => this.openCancelReservationModal(),
        },
      ],
    },
  };

  downloadAttachment() {
    this.reservationService.downloadReservationAttachment(
      this.reservationNumber()
    );
  }

  /* Signals */
  paymentStatus = computed(() => {
    const appuntamento = this.data()?.appuntamento;
    if (!appuntamento) return null;
    return this.derivePaymentStatus.transform(appuntamento)?.status;
  });

  heroActions = computed(() => {
    const status = this.paymentStatus();
    if (!status) return [];
    return this.heroActionsConfig.main[status];
  });

  heroSideActions = computed(() => {
    const status = this.paymentStatus();
    if (!status) return [];
    return this.heroActionsConfig.side[status];
  });

  modal = viewChild<ItModalComponent>("cancelReservationModal");
  data = toSignal<ReservationDetailResponseBody>(
    this.activatedRoute.data.pipe(map(({ reservation }) => reservation))
  );

  reservationNumber = computed(() => {
    return this.data()?.appuntamento.appuntamento;
  });

  reservationDocuments = computed(() => {
    const status = this.paymentStatus();

    if (!status) {
      return [
        {
          label: this.reservationService.reservationAttachmentLabel,
          handleClick: () => this.downloadAttachment(),
        },
      ];
    }

    return this.documentsStatusMap[status];
  });

  /* Class getters */
  get priority() {
    const reservationData = this.data();
    const [impegnativa] = reservationData?.appuntamento?.impegnative || [];
    if (!impegnativa || !this.isPriorityKey(impegnativa.priorita)) return "";
    return priorityMap[impegnativa.priorita];
  }

  get subtitle() {
    const reservationData = this.data();

    if (!reservationData) {
      throw new Error("Data should be available");
    }

    return $localize`N. Prenotazione: ${reservationData.appuntamento.appuntamento}`;
  }

  get mapAddress() {
    const reservationData = this.data();

    if (!reservationData) return "";

    const {
      appuntamento: { unitaErogante },
    } = reservationData;

    return `${unitaErogante.indirizzo} ${unitaErogante.descrizioneComune} ${unitaErogante.cap}`;
  }

  /* Class methods */
  private isPriorityKey(
    priorityCode: string
  ): priorityCode is keyof typeof priorityMap {
    return Object.keys(priorityMap).includes(priorityCode);
  }

  downloadPaymentNotice() {
    const num = this.reservationNumber();

    if (!num) {
      throw new Error(`Reservation number not found.`);
    }

    this.reservationService
      .downloadPaymentNotice(num, this.cf())
      .subscribe((blob) => downloadBlob($localize`avviso_` + num, blob));
  }

  openCancelReservationModal() {
    this.modal()?.show();
  }

  async downloadPaymentReceipt() {
    const num = this.reservationNumber();

    if (!num) {
      return;
    }

    this.reservationService
      .downloadPaymentReceipt(num, this.cf())
      .subscribe((file) => downloadBlob($localize`ricevuta_` + num, file));
  }

  async handleCancelReservation() {
    try {
      const term =
        this.paymentStatus() === "waiting-list"
          ? $localize`richiesta`
          : $localize`prenotazione`;

      this.loadingMessage.set($localize`Cancello la ${term}...`);
      const reservationNumber = this.reservationNumber();

      if (!reservationNumber) {
        return;
      }

      await lastValueFrom(
        this.reservationService.cancelReservation(reservationNumber, this.cf())
      );

      this.notificationService.success(
        $localize`Operazione completata`,
        $localize`La ${term} è stata cancellata come da te richiesto.`
      );

      this.loadingMessage.set(
        $localize`Ricarico la lista delle prenotazioni...`
      );

      await this.router.navigateByUrl(absoluteRoutesPaths.userReservations, {
        state: {
          refreshData: true,
        },
      });

      this.modal()?.hide();
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingMessage.set("");
    }
  }
}
