import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { AlertColor } from "design-angular-kit";
import { ActionButtonComponent } from "@/atoms/action-button/action-button.component";

@Component({
  selector: "cup-alert",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ActionButtonComponent],
  styles: `
    .alert-heading {
      font-size: 1.75rem;
      font-weight: 600;
    }
  `,
  template: `
    @let text = copyText();
    @let $action = action();

    <div role="alert" class="alert alert-warning">
      <h3 class="alert-heading">
        {{ headingText() }}
      </h3>

      @if (text) {
        <p class="mt-3">
          {{ copyText() }}
        </p>
      }

      @if ($action) {
        <cup-action-button
          class="d-inline-block mt-3"
          (actionClick)="$action.onClick()"
          [action]="{ label: $action.label }"
        />
      }
    </div>
  `,
})
export class CupAlertComponent {
  headingText = input.required<string>();
  copyText = input<string>();
  color = input<AlertColor>("success");
  action = input<{ label: string; onClick(): void }>();
}
