import { Route } from "@angular/router";
import { relativeRoutesPaths, routeLinks } from "@/classes/route-utils";
import { ReservationConfirmedPageComponent } from "@/app/pages/reservation-confirmed-page/reservation-confirmed-page.component";
import hasValidToken from "../guards/has-valid-token.guard";

const newReservationConfirmedRoute: Route = {
  path: relativeRoutesPaths.reservationConfirmed,
  component: ReservationConfirmedPageComponent,
  canActivate: [hasValidToken],
  data: {
    breadcrumbItems: [
      routeLinks.home,
      routeLinks.myReservations,
      routeLinks.newReservation,
      routeLinks.reservationConfirmed,
    ],
  },
};

export default newReservationConfirmedRoute;
