import { computed, effect, Injectable, signal } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  loadingCache = signal<
    Record<
      number,
      {
        timeoutId: number | null;
        isLoading: boolean;
        isCompleted: boolean;
      }
    >
  >({});

  isAppLoading = computed(() => {
    return Object.values(this.loadingCache()).reduce(
      (acc, request) => acc || request.isLoading,
      false
    );
  });

  isLoadingComplete = computed(() => {
    const values = Object.values(this.loadingCache());

    return (
      values.length > 0 &&
      Object.values(this.loadingCache()).reduce(
        (acc, request) => acc && request.isCompleted,
        true
      )
    );
  });

  constructor() {
    effect(() => {
      if (this.isLoadingComplete()) {
        window.setTimeout(() => {
          this.loadingCache.set({});
        }, 1500);
      }
    });
  }

  deleteKeyFromCache(key: number) {
    this.loadingCache.update((c) =>
      Object.entries(c).reduce((acc, [k, value]) => {
        if (+k === key) return acc;
        return { ...acc, [k]: value };
      }, {})
    );
  }

  loadingOn(key: number) {
    const id = window.setTimeout(() => {
      this.loadingCache.update((c) => ({
        ...c,
        [key]: { timeoutId: null, isLoading: true, isCompleted: false },
      }));
    }, 500);

    this.loadingCache.update((c) => ({
      ...c,
      [key]: { timeoutId: id, isLoading: false, isCompleted: false },
    }));
  }

  loadingOff(key: number) {
    const item = this.loadingCache()[key];
    const timeoutId = item?.timeoutId;

    if (timeoutId) {
      clearTimeout(timeoutId);
      this.deleteKeyFromCache(key);
      return;
    }

    this.loadingCache.update((c) => ({
      ...c,
      [key]: {
        ...c[key],
        isLoading: false,
        isCompleted: true,
      },
    }));
  }
}
