import { Directive, signal, viewChild } from "@angular/core";
import { ItModalComponent } from "design-angular-kit";
import { toObservable } from "@angular/core/rxjs-interop";
import { filter, firstValueFrom } from "rxjs";

@Directive()
export class ModalBase {
  modalComponent = viewChild<ItModalComponent>("modal");
  status = signal<ModalStatus | null>(null);
  payload = signal<object | null>(null);
  userAction: Promise<ModalStatus>;

  public openModal() {
    this.modalComponent()?.toggle();
  }

  public closeModal() {
    this.modalComponent()?.hide();
  }

  updateStatus(status: ModalStatus, payload?: object) {
    this.status.set(status);

    if (payload) {
      this.payload.set(payload);
    }
  }

  constructor() {
    this.userAction = firstValueFrom(
      toObservable(this.status).pipe(filter((status) => status !== null))
    );
  }
}

type ModalStatus = "canceled" | "submitted";
