import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  TemplateRef,
  viewChild,
} from "@angular/core";
import { DataRecapPanelComponent } from "@/molecules/data-recap-panel/data-recap-panel.component";
import RecapData from "@/types/recap-data.interface";
import { Action } from "@/types/action.interface";
import { NewReservationService } from "@/services/new-reservation.service";
import priorityMap from "@/utils/priority-map";
import { AppointmentValue } from "@/organisms/new-reservation/appointment-step/appointment-step.component";

@Component({
  selector: "cup-recap-step",
  imports: [DataRecapPanelComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="row">
      <div class="col-12 col-lg-9">
        <div class="d-flex gap-4 flex-column">
          @for (data of recapData(); track data.id) {
            <cup-data-recap-panel
              (actionClick)="handleAction($event)"
              [action]="data.action"
              [dataEntries]="data.entries"
              [recapTitle]="data.title"
              [recapId]="data.id"
            />
          }
        </div>
      </div>
    </div>

    <ng-template #waitingListTemplate>
      <p class="body fw-bold">
        <strong class="text-danger"
          >Questa non è una prenotazione di un appuntamento</strong
        >
        ma una richiesta di inserimento in lista d’attesa. Riceverai via SMS i
        dati dell'appuntamento non appena disponibile.
      </p>
    </ng-template>
  `,
  standalone: true,
})
export class RecapStepComponent implements AfterViewInit {
  waitingList = viewChild.required<TemplateRef<void>>("waitingListTemplate");
  readonly RECAP_ACTION_TYPES = ["edit-appointment-data"];

  service = inject(NewReservationService);
  recapData = signal<RecapData[]>([]);

  ngAfterViewInit() {
    try {
      const isWaitingList =
        !!this.service.reservation.controls.waitingList.value;

      const appointment = JSON.parse(
        isWaitingList
          ? this.service.reservation.controls.waitingList.value
          : this.service.reservation.controls.appointment.value
      ) as AppointmentValue;

      const recipes = this.service.getRecipesIds();

      const step: RecapData = isWaitingList
        ? {
            id: "applicant-waiting-list",
            title: $localize`Lista d'attesa`,
            entries: [
              {
                label: "Note",
                template: this.waitingList(),
              },
            ],
          }
        : {
            id: "appointment-data-recap",
            title: $localize`Appuntamento`,
            action: {
              label: $localize`Modifica`,
              ariaLabel: $localize`Modifica i dati dell'appuntamento`,
              type: "edit-appointment-data",
            },
            entries: [
              ...(appointment.calendar.dataDisponibilita
                ? [
                    {
                      label: $localize`Data`,
                      value: new Date(
                        appointment.calendar.dataDisponibilita
                      ).toISOString(),
                      format: "date",
                    } as const,
                  ]
                : []),
              ...(appointment.calendar.ora
                ? [
                    {
                      label: $localize`Ora`,
                      value: appointment.calendar.ora,
                    },
                  ]
                : []),
              ...(appointment.agenda.desStruttura
                ? [
                    {
                      label: $localize`Struttura`,
                      value: appointment.agenda.desStruttura,
                    },
                  ]
                : []),
              ...(appointment.agenda.indStruttura
                ? [
                    {
                      label: $localize`Indirizzo`,
                      value: `${appointment.agenda.indStruttura} – ${appointment.agenda.desComune}`,
                    },
                  ]
                : []),
              ...(appointment.agenda.desUnitaErogante
                ? [
                    {
                      label: $localize`Descrizione`,
                      value: appointment.agenda.desUnitaErogante || "",
                    },
                  ]
                : []),
            ],
          };

      this.recapData.set([
        ...recipes.map((nre, index) => {
          const recipe = this.service.allRecipes().find((r) => r.nre === nre)!;

          return {
            id: `service-data-recap-${index}`,
            title: $localize`Prestazione`,
            entries: [
              {
                label: $localize`Tipologia`,
                value: recipe.laboratorio
                  ? $localize`Esami di laboratorio`
                  : recipe.prestazioni.map((p) => p.prescrizione1).join(" "),
              },
              ...(recipe.classePriorita
                ? [
                    {
                      label: $localize`Priorità`,
                      value: `${
                        priorityMap[
                          recipe.classePriorita as keyof typeof priorityMap
                        ]
                      } (${recipe.classePriorita})`,
                    },
                  ]
                : []),
            ],
          };
        }),
        step,
        {
          id: "applicant-data-recap",
          title: $localize`Richiedente`,
          entries: [
            {
              label: $localize`Nome`,
              value: this.service.verifiedUserData()?.nome || "",
            },
            {
              label: $localize`Cognome`,
              value: this.service.verifiedUserData()?.cognome || "",
            },
          ],
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  isRecapStepAction(action: Action): action is Action<RecapStepAction> {
    return !!action.type && this.RECAP_ACTION_TYPES.includes(action.type);
  }

  handleAction(action: Action) {
    if (!this.isRecapStepAction(action)) {
      throw new Error("Invalid action");
    }

    switch (action.type) {
      case "edit-appointment-data": {
        this.service.currentStepId.set("appointment");
        break;
      }
    }
  }
}

type RecapStepAction = "edit-appointment-data";
