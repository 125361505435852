import { ChangeDetectionStrategy, Component } from "@angular/core";
import { CheckIconComponent } from "@/atoms/icons/check-icon.component";
import { ReactiveFormsModule } from "@angular/forms";
import { InputRefDirective } from "@/directives/input-ref.directive";
import { fadeInAnimation } from "@/animations/icon-fade-in";
import { CheckboxBase } from "@/classes/checkbox-base";

@Component({
  selector: "cup-checkbox-input",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [fadeInAnimation],
  imports: [CheckIconComponent, ReactiveFormsModule, InputRefDirective],
  template: `
    <button
      tabindex="0"
      role="checkbox"
      class="checkbox-button"
      cupInputRef
      [id]="inputId()"
      [name]="inputId()"
      [value]="value()"
      (change)="handleChange()"
      (click)="handleChange()"
      [disabled]="isDisabled()"
      [attr.aria-checked]="isChecked()"
      [attr.aria-required]="isRequired()"
      [attr.aria-describedby]="ariaDescribedBy"
      [attr.aria-labelledby]="ariaLabelledby()"
    >
      @if (isChecked()) {
        <cup-check-icon
          [hostStyle]="{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }"
          [@fadeIn]="'visible'"
          [fontSize]="13"
          color="white"
        />
      }
    </button>
  `,
})
export class CheckboxInputComponent extends CheckboxBase {}
