import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from "@angular/core";
import { ItButtonDirective } from "design-angular-kit";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { AsyncPipe } from "@angular/common";
import { relativeRoutesPaths } from "@/classes/route-utils";
import { NewReservationService } from "@/services/new-reservation.service";
import { Destroyable } from "@/classes/destroyable";
import { RecipesGridComponent } from "@/molecules/recipes-grid/recipes-grid.component";
import { map, startWith, takeUntil } from "rxjs";
import { SessionStorageService } from "@/services/session-storage.service";

@Component({
  selector: "cup-recipes-list-step",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ItButtonDirective, RouterLink, AsyncPipe, RecipesGridComponent],
  styles: `
    @use "cup";

    p {
      margin-top: cup.toRem(16);

      @include cup.media-breakpoint-up(lg) {
        margin-top: cup.toRem(8);
      }
    }

    .add-recipe-btn {
      width: 100%;
      align-self: flex-start;

      @include cup.media-breakpoint-up(sm) {
        width: auto;
      }
    }
  `,
  template: `
    <div class="mb-4">
      @if (newReservationService.target.value === "other") {
        <h2 class="h3" i18n>
          Ricette <abbr title="Codice Fiscale" i18n-title>CF</abbr>:
          {{ newReservationService.submittedFiscalCode() }}
        </h2>

        @let fullName = newReservationService.verifiedUserFullName;

        <p i18n>
          @if (fullName) {
            Seleziona una ricetta per
            <span class="text-capitalize">
              {{
                newReservationService.verifiedUserFullName.toLowerCase()
              }}&period;</span
            >
          } @else {
            Seleziona una ricetta per l'utente
            {{ newReservationService.submittedFiscalCode() }}
          }
        </p>
      } @else {
        <h2 class="h3" i18n>
          Carica le ricette manualmente o scegli tra quelle presenti
        </h2>

        @let count = newReservationService.allRecipes()?.length || 0;

        @if (count > 0) {
          <p>
            {{ count }}
            <ng-container i18n>ricette disponibili</ng-container>
          </p>
        } @else {
          <p i18n>Nessuna ricetta disponibile</p>
        }
      }
    </div>

    <div class="d-flex gap-lg-5 gap-4 flex-column">
      <a
        class="add-recipe-btn"
        [routerLink]="relativeRoutesPaths.newReservationUploadRecipe"
        itButton="outline-primary"
        i18n
        >Carica una ricetta</a
      >

      @let userRecipesCount = userRecipes().length;
      @let labRecipesCount = labRecipes().length;
      @let specialistRecipesCount = specialistRecipes().length;

      @if (userRecipesCount > 0) {
        <cup-recipes-grid
          [fiscalCode]="newReservationService.submittedFiscalCode()"
          [formGroup]="newReservationService.reservation"
          [recipes]="userRecipes()"
          i18n-gridTitle
          i18n-gridDescription
          gridTitle="Ricette aggiunte manualmente"
          gridDescription="Queste ricette sono state aggiunte manualmente dalla pagina “Carica una ricetta”."
        />
      }

      @if (isUserReservationTarget$ | async) {
        @if (labRecipesCount > 0) {
          <cup-recipes-grid
            [fiscalCode]="newReservationService.submittedFiscalCode()"
            [formGroup]="newReservationService.reservation"
            [recipes]="labRecipes()"
            [gridTitle]="labRecipesTitle()"
            i18n-gridDescription
            gridDescription="Solo per gli esami di laboratorio puoi selezionare più ricette insieme per effettuare una singola prenotazione."
          />
        }

        @if (specialistRecipesCount > 0) {
          <cup-recipes-grid
            [fiscalCode]="newReservationService.submittedFiscalCode()"
            [formGroup]="newReservationService.reservation"
            [recipes]="specialistRecipes()"
            [gridTitle]="specialistRecipesTitle()"
            i18n-gridDescription
            gridDescription="Per le visite specialistiche puoi selezionare al massimo una ricetta per effettuare la prenotazione."
          />
        }
      }
    </div>
  `,
})
export class RecipesListStepComponent extends Destroyable {
  protected readonly relativeRoutesPaths = relativeRoutesPaths;

  route = inject(ActivatedRoute);
  router = inject(Router);
  newReservationService = inject(NewReservationService);
  sessionStorageService = inject(SessionStorageService);

  isUserReservationTarget$ =
    this.newReservationService.target.valueChanges.pipe(
      startWith(this.newReservationService.target.value),
      map((target) => target === "me"),
      takeUntil(this.destroy$)
    );

  userRecipes = computed(() => {
    const selectedFiscalCode = this.newReservationService.submittedFiscalCode();

    const userRecipes =
      this.newReservationService.userRecipes()[selectedFiscalCode];

    return (userRecipes || []).map((recipe) => ({
      ...recipe,
      showRemoveButton: true,
      removeRecipe: () => {
        this.newReservationService.userRecipes.update((recipes) => ({
          ...recipes,
          [selectedFiscalCode]: recipes[selectedFiscalCode].filter(
            (r) => r.nre !== recipe.nre
          ),
        }));

        const currentRecipesValue =
          this.newReservationService.recipesControl.value;

        this.newReservationService.recipesControl.setValue(
          currentRecipesValue.filter(
            (value) => JSON.parse(value).id !== recipe.nre
          )
        );

        this.sessionStorageService.updateUserRecipes(
          this.newReservationService.userRecipes()
        );
      },
    }));
  });

  specialistRecipes = computed(() => {
    return this.newReservationService
      .allRecipes()
      .filter((recipe) => !recipe.laboratorio && !recipe.insertedByUser)
      .map((recipe) => ({
        ...recipe,
        isDisabled: !!this.newReservationService
          .recipesValue()
          ?.find((nre) => recipe?.nre !== nre),
      }));
  });

  labRecipes = computed(() => {
    return this.newReservationService
      .allRecipes()
      .filter((recipe) => recipe.laboratorio && !recipe.insertedByUser)
      .map((recipe) => ({
        ...recipe,
        isDisabled:
          this.newReservationService.lastRecipe()?.nre !== recipe.nre &&
          !!this.newReservationService.recipesValue()?.find((nre) => {
            const recipe = this.newReservationService
              .allRecipes()
              .find((r) => r.nre === nre);

            if (!recipe) return false;

            return !recipe.laboratorio;
          }),
      }));
  });

  labRecipesTitle = computed(() => {
    const isOne = this.labRecipes().length === 1;
    return $localize`Esami di laboratorio (${this.labRecipes().length} ricett${isOne ? "a" : "e"})`;
  });

  specialistRecipesTitle = computed(() => {
    const isOne = this.specialistRecipes().length === 1;
    return $localize`Visite specialistiche (${this.specialistRecipes().length} ricett${isOne ? "a" : "e"})`;
  });
}
