import { relativeRoutesPaths } from "@/classes/route-utils";
import { ReservationProviderComponent } from "@/organisms/new-reservation/reservation-provider/reservation-provider.component";
import { inject } from "@angular/core";
import { RecipesService } from "@/services/recipes.service";

import hasValidToken from "../guards/has-valid-token.guard";
import { Route } from "@angular/router";
import { NewReservationService } from "@/services/new-reservation.service";
import { StepperService } from "@/services/stepper.service";

const newReservationProviderRoute: Route = {
  path: relativeRoutesPaths.newReservation,
  component: ReservationProviderComponent,
  providers: [NewReservationService, StepperService],
  resolve: {
    recipes: () => inject(RecipesService).getAllRecipes(),
  },
  canActivate: [hasValidToken],
  loadChildren: () => import("./new-reservation.routes"),
};

export default newReservationProviderRoute;
