import { Injectable } from "@angular/core";
import { StepItem } from "@/types/step-item.interface";
import { NewReservationStepId } from "@/app/pages/new-reservation-page/new-reservation-page.component";
import {
  Ricetta,
  SearchReservationAvailabilityResponse,
  StartReservationResponse,
  VerifyAndUpdateAssistitoResponseBody,
} from "@/types/api";

@Injectable({
  providedIn: "root",
})
export class SessionStorageService {
  readonly SESSION_STORAGE_KEYS = {
    NEW_RESERVATION: {
      TARGET: "reservationTarget",
      RESERVATION: "reservation",
      STEPS: "steps",
      STEP_ID: "stepId",
      EXPIRE_DATE: "reservationExpireDate",
      VERIFIED_USER: "verifiedUserData",
      START_RESERVATION: "startReservation",
      RESERVATION_AVAILABILITY: "reservationAvailability",
      APPOINTMENT_DATE_FILTER: "dateFilter",
      USER_RECIPES: "userRecipes",
      USER_DISTRICT: "userDistrict",
      SUBMITTED_FISCAL_CODE: "submittedFiscalCode",
    },
    ACCESS_TOKEN: "access_token",
    SESSION_ID: "sessionId",
  };

  parseJson(value: string | null) {
    return value ? JSON.parse(value) : null;
  }

  get reservationAppointmentDateFilter() {
    return sessionStorage.getItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.APPOINTMENT_DATE_FILTER
    );
  }

  get reservationUserDistrict() {
    return sessionStorage.getItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.USER_DISTRICT
    );
  }

  get startReservationResponse() {
    return sessionStorage.getItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.START_RESERVATION
    );
  }

  get reservationAvailabilityResponse() {
    return sessionStorage.getItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.RESERVATION_AVAILABILITY
    );
  }

  get reservationUserRecipes() {
    return sessionStorage.getItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.USER_RECIPES
    );
  }

  get accessToken() {
    return sessionStorage.getItem(this.SESSION_STORAGE_KEYS.ACCESS_TOKEN);
  }

  get sessionId() {
    return sessionStorage.getItem(this.SESSION_STORAGE_KEYS.SESSION_ID);
  }

  get submittedFiscalCode() {
    return sessionStorage.getItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.SUBMITTED_FISCAL_CODE
    );
  }

  get reservation() {
    const value = sessionStorage.getItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.RESERVATION
    );

    return this.parseJson(value);
  }

  get reservationTarget() {
    return sessionStorage.getItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.TARGET
    );
  }

  get reservationSteps() {
    const value = sessionStorage.getItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.STEPS
    );

    return this.parseJson(value);
  }

  get reservationStepId() {
    return sessionStorage.getItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.STEP_ID
    ) as NewReservationStepId | null;
  }

  get reservationExpireDate() {
    const value = sessionStorage.getItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.EXPIRE_DATE
    );

    return this.parseJson(value);
  }

  get verifiedUser() {
    const value = sessionStorage.getItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.VERIFIED_USER
    );

    return this.parseJson(value);
  }

  updateSubmittedReservationFiscalCode(fiscalCode: string) {
    sessionStorage.setItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.SUBMITTED_FISCAL_CODE,
      fiscalCode
    );
  }

  clearReservationStorage() {
    for (const itemKey of Object.values(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION
    )) {
      sessionStorage.removeItem(itemKey);
    }
  }

  updateSessionId(sessionId: number) {
    sessionStorage.setItem(
      this.SESSION_STORAGE_KEYS.SESSION_ID,
      sessionId.toString()
    );
  }

  updateReservationStorageExpireDate() {
    sessionStorage.setItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.EXPIRE_DATE,
      new Date(Date.now() + 3_600 * 1_000).getTime().toString()
    );
  }

  updateReservationStepsStorage(steps: StepItem<NewReservationStepId>[]) {
    sessionStorage.setItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.STEPS,
      JSON.stringify(
        steps.map((step) => ({ id: step.id, isDone: step.isDone }))
      )
    );
  }

  updateStepIdStorage(stepId: NewReservationStepId) {
    sessionStorage.setItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.STEP_ID,
      stepId
    );
  }

  updateReservationStorage(formData: Record<string, unknown>) {
    sessionStorage.setItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.RESERVATION,
      JSON.stringify(formData)
    );
  }

  updateVerifiedUserData(userData: VerifyAndUpdateAssistitoResponseBody) {
    sessionStorage.setItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.VERIFIED_USER,
      JSON.stringify(userData)
    );
  }

  updateTarget(target: string) {
    sessionStorage.setItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.TARGET,
      target
    );
  }

  updateStartReservationResponse(response: StartReservationResponse) {
    sessionStorage.setItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.START_RESERVATION,
      JSON.stringify(response)
    );
  }

  updateAvailabilityResponse(response: SearchReservationAvailabilityResponse) {
    sessionStorage.setItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.RESERVATION_AVAILABILITY,
      JSON.stringify(response)
    );
  }

  updateUserRecipes(response: Record<string, Ricetta[]>) {
    sessionStorage.setItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.USER_RECIPES,
      JSON.stringify(response)
    );
  }

  updateReservationUserDistrict(isUserDistrictOnly: boolean) {
    sessionStorage.setItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.USER_DISTRICT,
      isUserDistrictOnly.toString()
    );
  }

  updateReservationAppointmentDateFilter(date: string) {
    sessionStorage.setItem(
      this.SESSION_STORAGE_KEYS.NEW_RESERVATION.APPOINTMENT_DATE_FILTER,
      date
    );
  }
}
