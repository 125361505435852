import { relativeRoutesPaths, routeLinks } from "@/classes/route-utils";
import { UserReservationsPageComponent } from "@/app/pages/user-reservations-page/user-reservations-page.component";
import { inject } from "@angular/core";
import { ReservationService } from "@/services/reservation.service";
import hasValidToken from "../guards/has-valid-token.guard";
import { Router } from "@angular/router";
import { NgHttpCachingService } from "ng-http-caching";

const userReservationsRoute = {
  path: relativeRoutesPaths.userReservations,
  component: UserReservationsPageComponent,
  resolve: {
    reservations: () => {
      const router = inject(Router);
      const cachingService = inject(NgHttpCachingService);

      const shouldRefreshReservations =
        router.getCurrentNavigation()?.extras?.state?.["refreshData"];

      if (shouldRefreshReservations) {
        cachingService.clearCacheByTag("user-reservation-list");
      }

      return inject(ReservationService).getAllReservations();
    },
  },
  canActivate: [hasValidToken],
  data: {
    breadcrumbItems: [routeLinks.home, routeLinks.myReservations],
  },
};

export default userReservationsRoute;
