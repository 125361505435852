import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  signal,
  ViewEncapsulation,
} from "@angular/core";
import {
  ItButtonDirective,
  ItIconComponent,
  ItInputComponent,
  ItProgressButtonComponent,
} from "design-angular-kit";
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { toObservable } from "@angular/core/rxjs-interop";
import { takeUntil } from "rxjs";
import { Destroyable } from "@/classes/destroyable";
import { ButtonComponent } from "@/atoms/button/button.component";

@Component({
  imports: [
    ItInputComponent,
    ItIconComponent,
    ButtonComponent,
    ItButtonDirective,
    FormsModule,
    ItProgressButtonComponent,
  ],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputSearchComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: "cup-input-search",
  styles: `
    @use "cup";

    .input-search__wrapper {
      display: flex;
      flex-direction: column;
      gap: cup.toRem(24);
      margin-bottom: cup.toRem(32);

      @include cup.media-breakpoint-up(lg) {
        margin-bottom: cup.toRem(48);
        flex-direction: row;
      }
    }

    .input-search__filters-btn {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      padding: cup.toRem(12) cup.toRem(8);
    }
  `,
  template: `
    <form
      (ngSubmit)="search.emit()"
      class="input-search__wrapper"
      [attr.data-show-filters]="showFilters()"
    >
      <it-input
        name="searchKey"
        [disabled]="isDisabled()"
        [ngModel]="searchKey()"
        (ngModelChange)="searchKey.set($event)"
        class="search-input flex-grow-3"
        [label]="label()"
        [validationMode]="false"
      >
        <it-icon name="search" size="sm" color="primary" appendText />
      </it-input>

      @if (buttonLabel()) {
        <button
          class="flex-grow-1"
          type="submit"
          itButton="primary"
          [disabled]="!searchKey()"
          [progress]="isLoading()"
        >
          {{ buttonLabel() }}
        </button>
      }

      @if (showFiltersButton()) {
        @let isShowingFilters = showFilters();

        <button
          (click)="toggleFilters.emit()"
          [attr.aria-controls]="filterButtonAriaControls()"
          [attr.aria-expanded]="isShowingFilters"
          type="button"
          class="input-search__filters-btn"
          icon="funnel"
          relevance="primary-task"
          [iconColor]="isShowingFilters ? 'white' : 'secondary'"
          [itButton]="isShowingFilters ? 'secondary' : 'outline-secondary'"
          i18n
        >
          Altri filtri
        </button>
      }

      @if (showResetButton()) {
        <button
          type="reset"
          [disabled]="isResetBtnDisabled()"
          itButton="outline-secondary"
          class="flex-grow-1 justify-content-center"
          i18n
        >
          Resetta
        </button>
      }
    </form>
  `,
})
export class InputSearchComponent
  extends Destroyable
  implements ControlValueAccessor
{
  filterButtonAriaControls = input<string>("");

  isLoading = input(false);

  showFiltersButton = input(false, {
    transform: booleanAttribute,
  });

  showFilters = input(false, {
    transform: booleanAttribute,
  });

  isResetBtnDisabled = input(false, {
    transform: booleanAttribute,
  });

  showResetButton = input(true, {
    transform: booleanAttribute,
  });

  label = input<string>($localize`Chiave di ricerca`);
  buttonLabel = input<string>($localize`Ricerca`);
  placeholder = input<string>();
  searchKey = signal("");
  searchKey$ = toObservable(this.searchKey);
  isDisabled = signal(false);

  onTouched!: () => void;

  registerOnChange(fn: (v: string) => void) {
    this.searchKey$.pipe(takeUntil(this.destroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled.set(isDisabled);
  }

  writeValue(value: string) {
    this.searchKey.set(value);
  }

  search = output<void>();
  toggleFilters = output<void>();
}
