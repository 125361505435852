import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  numberAttribute,
  signal,
  viewChild,
} from "@angular/core";
import { ItButtonDirective, ItModalComponent } from "design-angular-kit";
import { Subject, take } from "rxjs";
import { Destroyable } from "@/classes/destroyable";
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { CodeInputModule } from "angular-code-input";
import { OtpAbortError, OtpResendCodeError } from "@/classes/otp-error";

@Component({
  selector: "cup-contact-otp-modal",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ContactOtpModalComponent,
    },
  ],
  imports: [ItButtonDirective, ItModalComponent, CodeInputModule, FormsModule],
  template: `
    <it-modal
      [focus]="true"
      [closeButton]="false"
      [keyboard]="false"
      (hideEvent)="$event.stopPropagation()"
      backdrop="static"
      #otpModal
      alignment="centered"
    >
      <ng-container modalTitle i18n>
        Inserisci il codice di verifica
      </ng-container>

      <ng-container>
        <div class="d-flex flex-column align-items-center gap-3">
          <ng-content></ng-content>

          <p i18n>
            @let count = counter();

            Validità OTP:
            <strong>
              {{ count > 0 ? counter() + " secondi" : "scaduto" }}
            </strong>
          </p>

          <code-input
            [codeLength]="codeLength()"
            [code]="otpCode()"
            (codeChanged)="otpCode.set($event)"
          />

          <div class="d-flex flex-column flex-lg-row gap-3">
            <p i18n>Non hai ricevuto il codice?</p>

            <button
              (click)="requestNewOtpSubject.next()"
              class="link bg-transparent border-0 p-0"
            >
              Invia nuovo codice
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container footer>
        <button
          (click)="otpModal.hide(); abortSubject.next()"
          itButton="outline-primary"
          type="button"
          i18n
        >
          Annulla autorizzazione
        </button>

        <button
          (click)="confirmSubject.next()"
          [disabled]="otpCode().length < 4"
          itButton="primary"
          type="button"
          i18n
        >
          Conferma
        </button>
      </ng-container>
    </it-modal>
  `,
})
export class ContactOtpModalComponent
  extends Destroyable
  implements ControlValueAccessor
{
  counter = signal(0);
  otpCode = signal("");
  modal = viewChild<ItModalComponent>("otpModal");
  intervalId = signal(0);

  codeLength = input(4, {
    transform: numberAttribute,
  });
  abortSubject = new Subject<void>();
  requestNewOtpSubject = new Subject<void>();
  confirmSubject = new Subject<void>();

  readonly OTP_SECONDS_TIMEOUT = 60;

  onTouched!: () => void;
  onChange!: (value: string) => void;

  initFlow<T>(confirmCallback: () => Promise<T>) {
    const modal = this.modal()!;

    return new Promise<T>((resolve, reject) => {
      this.resetOtpCounter();
      modal.show();

      this.confirmSubject.pipe(take(1)).subscribe(async () => {
        const response = await confirmCallback();
        modal.hide();
        setTimeout(() => resolve(response), 500);
      });

      this.abortSubject.pipe(take(1)).subscribe(() => {
        reject(new OtpAbortError());
      });

      this.requestNewOtpSubject.pipe(take(1)).subscribe(() => {
        reject(new OtpResendCodeError());
      });
    });
  }

  readonly otpCodeChange = effect(() => {
    this.onChange(this.otpCode());
  });

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  registerOnChange(fn: (value: string) => void) {
    this.onChange = fn;
  }

  writeValue(value: string) {
    this.otpCode.set(value);
  }

  resetOtpCounter() {
    this.counter.set(this.OTP_SECONDS_TIMEOUT);

    const id = this.intervalId();

    if (id) {
      clearInterval(id);
      this.intervalId.set(0);
    }

    this.intervalId.set(
      window.setInterval(() => {
        if (this.counter() > 0) {
          return this.counter.update((c) => c - 1);
        }

        clearInterval(this.intervalId());
      }, 1000)
    );
  }
}
