import { inject } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { Router } from "@angular/router";

export default function hasValidToken() {
  const authService = inject(OAuthService);

  if (authService.hasValidAccessToken()) {
    return true;
  }

  void inject(Router).navigateByUrl("/");
  return false;
}
