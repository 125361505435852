import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { BadgeColor, ItBadgeDirective } from "design-angular-kit";

@Component({
  selector: "cup-payment-badge",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styles: `
    @use "cup";

    .badge {
      padding: cup.toRem(4) cup.toRem(16);
    }
  `,
  template: `
    @let status = paymentStatus();

    @if (status) {
      <span class="badge" [itBadge]="badgeColor" i18n="Stato del pagamento">
        {paymentStatus(), select,
          paid {Pagato}
          to-pay {Da pagare}
          waiting-list {Lista d'attesa}
        }
      </span>
    }
  `,
  imports: [ItBadgeDirective],
})
export class PaymentBadgeComponent {
  get badgeColor() {
    const statusColorMap: Record<string, BadgeColor> = {
      "to-pay": "warning",
      paid: "primary",
      "waiting-list": "secondary",
    };

    return statusColorMap[this.paymentStatus()];
  }

  paymentStatus = input.required<PaymentStatus>();
}

export type PaymentStatus = "paid" | "to-pay" | "waiting-list";
