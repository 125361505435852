import { inject, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "googleMap",
  standalone: true,
})
export class GoogleMapPipe implements PipeTransform {
  sanitizer = inject(DomSanitizer);

  transform(value: string, output?: "embed") {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://maps.google.com/maps?q=${encodeURIComponent(value)}${output ? `&output=${output}` : ""}`
    );
  }
}
