import { Route } from "@angular/router";
import { relativeRoutesPaths, routeLinks } from "@/classes/route-utils";
import { UserProfilePageComponent } from "@/app/pages/user-profile-page/user-profile-page.component";
import userResolver from "../resolvers/user.resolver";
import hasValidToken from "../guards/has-valid-token.guard";

const userProfileRoute: Route = {
  path: relativeRoutesPaths.userProfile,
  component: UserProfilePageComponent,
  data: {
    breadcrumbItems: [routeLinks.home, routeLinks.userProfile],
  },
  canActivate: [hasValidToken],
  resolve: {
    user: userResolver,
  },
};

export default userProfileRoute;
