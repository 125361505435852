export class ResponseClientError extends Error {
  title: string;
  detail: string;
  status?: number;

  constructor({ title, detail, status }: ResponseError) {
    super();
    this.detail = detail;
    this.title = title;
    this.status = status;
  }
}

interface ResponseError {
  title: string;
  detail: string;
  status?: number;
}
