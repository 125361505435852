import { AbstractControl } from "@angular/forms";

export default function exactLengthValidator(expectedLength: number) {
  return (control: AbstractControl) => {
    return control.value.length !== expectedLength
      ? {
          exactLength: {
            expectedValue: expectedLength,
            actualValue: control.value.length,
          },
        }
      : null;
  };
}
