import { relativeRoutesPaths, routeLinks } from "@/classes/route-utils";
import { ReservationDetailPageComponent } from "@/app/pages/reservation-detail-page/reservation-detail-page.component";
import { ActivatedRouteSnapshot } from "@angular/router";
import { inject } from "@angular/core";
import { ReservationService } from "@/services/reservation.service";
import { UserService } from "@/services/user.service";
import hasValidToken from "../guards/has-valid-token.guard";

const reservationHistoryDetailRoute = {
  path: relativeRoutesPaths.reservationHistoryDetail,
  component: ReservationDetailPageComponent,
  canActivate: [hasValidToken],
  resolve: {
    reservation: (route: ActivatedRouteSnapshot) => {
      const reservationService = inject(ReservationService);
      const userService = inject(UserService);

      return reservationService.getReservationDetail({
        cf: userService.userJwtFiscalCode,
        codPrenotazione: route.params["id"],
      });
    },
    breadcrumbItems: (route: ActivatedRouteSnapshot) => [
      routeLinks.home,
      routeLinks.myReservations,
      routeLinks.reservationHistory,
      routeLinks.reservationHistoryDetail(+route.params["id"]),
    ],
  },
};

export default reservationHistoryDetailRoute;
