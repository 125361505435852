import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
} from "@angular/core";
import { ItButtonDirective, ItIconComponent } from "design-angular-kit";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { BreadcrumbComponent } from "@/molecules/breadcrumb/breadcrumb.component";
import { SessionStorageService } from "@/services/session-storage.service";
import { NgHttpCachingService } from "ng-http-caching";
import { ReservationService } from "@/services/reservation.service";
import { toSignal } from "@angular/core/rxjs-interop";
import { map } from "rxjs";
import { VerifyAndUpdateAssistitoResponseBody } from "@/types/api";
import { absoluteRoutesPaths } from "@/classes/route-utils";

@Component({
  selector: "cup-confirm-reservation",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ItButtonDirective,
    ItIconComponent,
    RouterLink,
    BreadcrumbComponent,
  ],
  styles: `
    @use "cup";
    @import "common";

    .reservation-confirmed {
      @extend .container-xxl;

      display: flex;
      padding-bottom: cup.toRem(48);
      gap: cup.toRem(16);
      flex-direction: column;

      @include cup.media-breakpoint-up(lg) {
        padding-bottom: cup.toRem(218);
      }
    }
  `,
  template: `
    @let waitingList = isInWaitingList();
    @let isUserReservation = isReservationForUser();
    @let userData = verifiedUserData();

    <cup-breadcrumb />

    <div class="reservation-confirmed">
      <div class="d-flex gap-3 align-items-center">
        <it-icon size="lg" name="check-circle" color="primary" />

        @if (waitingList) {
          <h1 class="h1" i18n>Richiesta effettuata</h1>
        } @else {
          <h1 class="h1" i18n>Prenotazione effettuata</h1>
        }
      </div>

      @if (waitingList) {
        @if (isUserReservation) {
          <p class="lead text-secondary" i18n>
            La tua richiesta di inserimento in lista d’attesa sarà ora visibile
            dalla sezione
            <abbr title="Centro Unico di Prenotazione" i18n-title>CUP</abbr> “Le
            mie Prenotazioni”.
          </p>
        } @else {
          <p class="lead text-secondary" i18n>
            La richiesta di inserimento in lista d'attesa per
            {{ userData.nome }} {{ userData.cognome }} sarà ora visibile dalla
            sua sezione
            <abbr title="Centro Unico di Prenotazione" i18n-title>CUP</abbr> “Le
            mie Prenotazioni”.
          </p>
        }
      } @else {
        @if (isUserReservation) {
          <p class="lead text-secondary" i18n>
            Ricordati di portare con te la ricetta, la prenotazione e la
            ricevuta di pagamento. La tua prenotazione sarà comunque visibile
            dalla sezione
            <abbr title="Centro Unico di Prenotazione" i18n-title>CUP</abbr> “Le
            mie Prenotazioni”.
          </p>
        } @else {
          <p class="lead text-secondary" i18n>
            Ricorda a {{ userData.nome }} {{ userData.cognome }} di portare con
            se la ricetta, la prenotazione e la ricevuta di pagamento. La
            prenotazione per {{ userData.nome }} {{ userData.cognome }} sarà
            comunque visibile dalla sua sezione
            <abbr title="Centro Unico di Prenotazione" i18n-title>CUP</abbr> “Le
            mie Prenotazioni”.
          </p>
        }
      }

      @if (waitingList) {
        <button
          (click)="
            reservationService.downloadReservationAttachment(
              reservationNumber()
            )
          "
          itButton="primary"
          class="align-self-lg-start"
          i18n
        >
          Scarica promemoria
        </button>
      } @else {
        <div class="d-flex gap-3">
          <button
            (click)="
              reservationService.payReservationOnline(reservationNumber())
            "
            itButton="primary"
            class="flex-grow-1 flex-lg-grow-0 align-self-lg-start"
            i18n
          >
            Paga online
          </button>
          <button
            (click)="
              reservationService.downloadReservationAttachment(
                reservationNumber()
              )
            "
            itButton="outline-primary"
            class="flex-grow-1 flex-lg-grow-0 align-self-lg-start"
            i18n
          >
            Scarica prenotazione
          </button>
        </div>
      }

      <p class="d-lg-none text-center" i18n>Oppure</p>

      <a class="d-lg-none" routerLink="/" itButton="outline-primary" i18n
        >Torna alla home</a
      >
    </div>
  `,
  standalone: true,
})
export class ReservationConfirmedPageComponent implements OnInit {
  route = inject(ActivatedRoute);
  reservationNumber = toSignal<string>(
    this.route.params.pipe(map((params) => params["id"]))
  );

  router = inject(Router);

  cachingService = inject(NgHttpCachingService);
  reservationService = inject(ReservationService);
  sessionStorageService = inject(SessionStorageService);

  verifiedUserData = signal<VerifyAndUpdateAssistitoResponseBody>(
    {} as VerifyAndUpdateAssistitoResponseBody
  );
  isInWaitingList = signal(false);
  isReservationForUser = signal(true);

  ngOnInit() {
    if (!this.sessionStorageService.reservation) {
      return void this.router.navigate([absoluteRoutesPaths.newReservation]);
    }

    this.isInWaitingList.set(
      !!this.sessionStorageService.reservation.waitingList
    );

    this.isReservationForUser.set(
      this.sessionStorageService.reservationTarget === "me"
    );

    this.verifiedUserData.set(this.sessionStorageService.verifiedUser);

    this.cachingService.clearCacheByTag("user-reservation-list");

    this.sessionStorageService.clearReservationStorage();
  }
}
