import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from "@angular/core";
import { DataEntry } from "@/types/data-entry.interface";
import { Action } from "@/types/action.interface";
import { ActionButtonComponent } from "@/atoms/action-button/action-button.component";
import {
  DatePipe,
  NgTemplateOutlet,
  TitleCasePipe,
  UpperCasePipe,
} from "@angular/common";

@Component({
  selector: "cup-data-recap-panel",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ActionButtonComponent,
    DatePipe,
    TitleCasePipe,
    NgTemplateOutlet,
    UpperCasePipe,
  ],
  styles: `
    @use "cup";

    .data-recap-panel {
      padding: cup.toRem(16);
      border-radius: cup.toRem(4);
      border: cup.toRem(1) solid cup.$color-border-subtle;

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: cup.toRem(16);
        border-bottom: cup.toRem(1) solid cup.$color-border-subtle;
      }

      dl {
        margin: 0;
      }

      &__data-entry {
        display: flex;
        gap: cup.toRem(2);
        flex-direction: column;
        padding: cup.toRem(16) 0;
        border-bottom: cup.toRem(1) solid cup.$color-border-subtle;

        &:last-of-type {
          padding-bottom: 0;
          border-bottom: none;
        }

        dt {
          font-weight: 400;
        }

        dd {
          margin: 0;
          font-weight: 600;
        }
      }
    }
  `,
  template: `
    @let actionBtn = action();

    <section class="data-recap-panel" [attr.aria-labelledby]="recapTitle()">
      <header class="data-recap-panel__header">
        <h2 class="h4" [id]="recapId() || recapTitle()">{{ recapTitle() }}</h2>

        @if (actionBtn) {
          <cup-action-button
            (actionClick)="actionClick.emit($event)"
            [action]="actionBtn"
          />
        }
      </header>
      <dl>
        @for (entry of dataEntries(); track entry.label) {
          <div class="data-recap-panel__data-entry">
            <dt>{{ entry.label }}</dt>
            <dd>
              @if (entry.template) {
                <ng-container *ngTemplateOutlet="entry.template"></ng-container>
              } @else {
                @switch (entry.format) {
                  @case ("date") {
                    {{ entry.value | date: "EEEE dd MMMM YYYY" | titlecase }}
                  }
                  @case ("uppercase") {
                    {{ entry.value | uppercase }}
                  }
                  @case ("none") {
                    {{ entry.value }}
                  }
                  @default {
                    {{ entry.value | titlecase }}
                  }
                }
              }
            </dd>
          </div>
        }
      </dl>
    </section>
  `,
  standalone: true,
})
export class DataRecapPanelComponent {
  action = input<Action>();
  actionClick = output<Action>();
  recapId = input<string>();
  recapTitle = input.required<string>();
  dataEntries = input.required<DataEntry[]>();
}
