import { ChangeDetectionStrategy, Component, input } from "@angular/core";

@Component({
  selector: "cup-reservation-detail-section",
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <section class="d-flex gap-3 flex-column">
      <h3 i18n>{{ sectionTitle() }}</h3>
      <ng-content></ng-content>
    </section>
  `,
  standalone: true,
})
export class ReservationDetailSectionComponent {
  sectionTitle = input.required<string>();
}
