export default function downloadBlob(fileName: string, fileBlob?: Blob | null) {
  if (!fileBlob) return;
  const objectUrl = URL.createObjectURL(fileBlob);
  const anchor = document.createElement("a");
  anchor.download = fileName;
  anchor.href = objectUrl;
  anchor.click();
  anchor.remove();
  URL.revokeObjectURL(objectUrl);
}
