import { routeLinks } from "@/classes/route-utils";
import { NewReservationPageComponent } from "@/app/pages/new-reservation-page/new-reservation-page.component";
import userResolver from "../resolvers/user.resolver";

const newReservationRoute = {
  path: "",
  component: NewReservationPageComponent,
  data: {
    breadcrumbItems: [
      routeLinks.home,
      routeLinks.myReservations,
      routeLinks.newReservation,
    ],
  },
  resolve: {
    user: userResolver,
  },
};

export default newReservationRoute;
