import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ItButtonDirective, ItModalComponent } from "design-angular-kit";
import { ModalBase } from "@/classes/modal-base.class";
import { RouterLink } from "@angular/router";
import { relativeRoutesPaths } from "@/classes/route-utils";

@Component({
  selector: "cup-missing-contacts-modal",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ItButtonDirective, ItModalComponent, RouterLink],
  styles: ``,
  template: `
    <it-modal
      #modal="itModal"
      alignment="centered"
      (hideEvent)="updateStatus('canceled')"
    >
      <ng-container modalTitle i18n>
        Dati di contatto non presenti
      </ng-container>

      <p i18n>
        Se vuoi accedere alla lista d'attesa è necessario fornire i propri
        contatti per ricevere eventuali comunicazioni.
      </p>

      <ng-container footer>
        <button
          itButton="outline-primary"
          type="button"
          (click)="modal.hide()"
          i18n
        >
          Annulla
        </button>
        <a
          [routerLink]="relativeRoutesPaths.newReservationAddContacts"
          (click)="modal.hide()"
          itButton="primary"
          type="button"
          i18n
        >
          Inserisci contatti
        </a>
      </ng-container>
    </it-modal>
  `,
})
export class MissingContactsModalComponent extends ModalBase {
  protected readonly relativeRoutesPaths = relativeRoutesPaths;
}
