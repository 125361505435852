import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  viewChild,
} from "@angular/core";
import { HeroComponent } from "@/molecules/hero.component";
import { FullLayoutComponent } from "@/atoms/full-layout.component";
import { StepperComponent } from "@/molecules/stepper.component";
import { NewReservationService } from "@/services/new-reservation.service";
import { BreadcrumbComponent } from "@/molecules/breadcrumb/breadcrumb.component";
import { Router } from "@angular/router";
import { ReservationService } from "@/services/reservation.service";
import { lastValueFrom } from "rxjs";
import { absoluteRoutesPaths } from "@/classes/route-utils";
import { AppointmentValue } from "@/organisms/new-reservation/appointment-step/appointment-step.component";
import { StepperService } from "@/services/stepper.service";
import { $localize } from "@angular/localize/init";
import { NgHttpCachingService } from "ng-http-caching";

export type NewReservationStepId =
  | "applicant"
  | "recipes-list"
  | "appointment"
  | "recap";

@Component({
  selector: "cup-new-reservation",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    HeroComponent,
    FullLayoutComponent,
    StepperComponent,
    BreadcrumbComponent,
  ],
  styles: `
    @use "cup";

    .stepper-wrapper {
      margin-top: cup.toRem(24);

      @include cup.media-breakpoint-up(lg) {
        margin-top: cup.toRem(48);
      }
    }
  `,
  template: `
    <cup-breadcrumb />

    <cup-hero
      i18n-heroCopy
      i18n-heroTitle
      showHr="false"
      heroCopy="Inserisci le informazioni necessarie per prenotare una visita o degli esami. Potrai ricontrollare tutti i dati nel riepilogo, prima di inviare la richiesta."
      heroTitle="Nuova prenotazione"
      [colConfig]="{ xs: 12, lg: 7 }"
    />

    <div class="stepper-wrapper">
      <cup-full-layout>
        <cup-stepper
          [(currentStepId)]="newReservationService.currentStepId"
          [(steps)]="newReservationService.steps"
          (submitData)="handleSubmit()"
          (resetData)="handleReset()"
        />
      </cup-full-layout>
    </div>
  `,
})
export class NewReservationPageComponent implements AfterViewInit {
  newReservationService = inject(NewReservationService);
  reservationService = inject(ReservationService);
  router = inject(Router);
  stepperService = inject(StepperService);
  stepperComponent = viewChild.required(StepperComponent);
  cachingService = inject(NgHttpCachingService);

  ngAfterViewInit() {
    this.goToNextStepAfterAddingContacts();
  }

  goToNextStepAfterAddingContacts() {
    const contactsAddedAt = this.newReservationService.contactsAddedAt();
    if (!contactsAddedAt) return;

    // reset state when contacts were already added and the time diff is greater
    // than one second
    if (new Date().getTime() - contactsAddedAt >= 1000) {
      return this.newReservationService.contactsAddedAt.set(null);
    }

    void this.stepperComponent().handleNextStep();
  }

  handleReset() {
    this.newReservationService.reservation.reset();
    this.newReservationService.userRecipes.set({});
    this.newReservationService.submittedFiscalCode.set("");
    this.newReservationService.contactsAddedAt.set(null);
    this.newReservationService.verifiedUserData.set(null);
    this.newReservationService.startReservationResponse.set(null);
    this.newReservationService.availabilityResponse.set(null);
    this.cachingService.clearCacheByTag("startReservation");
    this.cachingService.clearCacheByTag("reservationRecipes");
    this.cachingService.clearCacheByTag("reservationAvailability");
  }

  async handleSubmit() {
    const {
      recipes,
      appointment: reservation,
      waitingList,
    } = this.newReservationService.reservation.value;

    const userData = this.newReservationService.verifiedUserData();

    if (!recipes) {
      console.error("Recipes not found");
      return;
    }

    if (!userData?.codiceFiscale) {
      console.error("User fiscal code not found");
      return;
    }

    const appointment = waitingList || reservation;

    if (!appointment) {
      console.error("Appointment not found");
      return;
    }

    try {
      const { agenda, calendar } = JSON.parse(appointment) as AppointmentValue;

      const payload = {
        agenda,
        calendar,
        cfAssistito: userData.codiceFiscale,
        nreList: this.newReservationService.getRecipesIds(),
      };

      this.stepperService.loadingLabel.set(
        waitingList
          ? $localize`Effettuo la richiesta...`
          : $localize`Effettuo la prenotazione...`
      );

      const reservationId = await lastValueFrom(
        this.reservationService.confirmReservation(payload)
      );

      void this.router.navigate([
        absoluteRoutesPaths.newReservationConfirmed(reservationId),
      ]);
    } finally {
      this.stepperService.resetLoadingLabel();
    }
  }
}
