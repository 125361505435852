import { Route } from "@angular/router";
import { relativeRoutesPaths, routeLinks } from "@/classes/route-utils";
import { ReservationHistoryPageComponent } from "@/app/pages/reservation-history-page/reservation-history-page.component";
import { inject } from "@angular/core";
import { ReservationService } from "@/services/reservation.service";
import hasValidToken from "../guards/has-valid-token.guard";

const reservationHistoryRoute: Route = {
  path: relativeRoutesPaths.reservationHistory,
  component: ReservationHistoryPageComponent,
  resolve: {
    reservations: () => inject(ReservationService).getReservationHistory(),
  },
  canActivate: [hasValidToken],
  data: {
    breadcrumbItems: [
      routeLinks.home,
      routeLinks.myReservations,
      routeLinks.reservationHistory,
    ],
  },
};

export default reservationHistoryRoute;
