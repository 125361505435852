import {
  ChangeDetectionStrategy,
  Component,
  inject,
  viewChild,
  viewChildren,
} from "@angular/core";
import { BreadcrumbComponent } from "@/molecules/breadcrumb/breadcrumb.component";
import { HeroComponent } from "@/molecules/hero.component";
import { InputComponent } from "@/atoms/input/input.component";
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ActionButtonComponent } from "@/atoms/action-button/action-button.component";
import { Action } from "@/types/action.interface";
import { ItButtonDirective, ItModalComponent } from "design-angular-kit";
import italianFiscalCodeValidator from "@/utils/functions/italian-fiscal-code.validator";
import { FormProviderService } from "@/services/form-provider.service";
import { Router } from "@angular/router";
import { ReservationService } from "@/services/reservation.service";
import { lastValueFrom } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { absoluteRoutesPaths } from "@/classes/route-utils";

@Component({
  selector: "cup-others-reservations-page",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BreadcrumbComponent,
    HeroComponent,
    InputComponent,
    ActionButtonComponent,
    ItButtonDirective,
    FormsModule,
    ReactiveFormsModule,
    ItModalComponent,
  ],
  providers: [FormProviderService],
  styles: ``,
  template: `
    <cup-breadcrumb />

    <cup-hero
      i18n-heroTitle
      i18n-heroCopy
      heroTitle="Prenotazioni di altri"
      heroCopy="Puoi pagare online o annullare la prenotazione, stampare la prenotazione, l’allegato preparazione, il documento fiscale o l’avviso di pagamento."
      showHr="true"
    />

    <form
      (ngSubmit)="handleSubmit()"
      class="container-xxl d-flex flex-column gap-5 pb-5"
    >
      <h2 class="h3" i18n>Cerca la prenotazione</h2>

      <div class="row gap-5 gap-lg-0">
        <div class="col-lg-6 col-12">
          <cup-input
            #inputFiscalCode
            [formGroup]="formProvider.form"
            name="fiscalCode"
            label="Codice fiscale"
            inputId="user-fiscal-code"
            placeholder="Inserisci codice fiscale"
            [maxlength]="16"
          />
        </div>
        <div class="col-lg-6 col-12">
          <cup-input
            #inputNumber
            [formGroup]="formProvider.form"
            name="number"
            i18n-label
            i18n-placeholder
            inputId="reservation-number"
            label="Numero prenotazione"
            placeholder="Inserisci il numero di prenotazione"
            [maxlength]="12"
          />
        </div>
      </div>

      <cup-action-button
        [action]="noNumberAction"
        (actionClick)="reservationNumberModal.show()"
      />

      <button type="submit" class="align-self-lg-start" itButton="primary" i18n>
        Cerca la prenotazione
      </button>
    </form>

    <it-modal
      class="mobile-fullscreen-modal"
      #reservationNumberModal="itModal"
      size="xl"
      alignment="centered"
    >
      <ng-container modalTitle i18n
        >Dove trovare il numero di prenotazione</ng-container
      >

      <p i18n class="mb-4">
        Il numero prenotazione si trova nel primo dei fogli della prenotazione o
        nell'SMS ricevuto. Di seguito è indicato dove si trova il numero di
        prenotazione a seconda che sia stato prodotto il vecchio o il nuovo
        documento di prenotazione
      </p>

      <img alt="" src="/assets/reservation-number.jpg" width="100%" />
    </it-modal>

    <it-modal #modal="itModal" alignment="centered">
      <ng-container modalTitle i18n>
        Non è stata trovata nessuna prenotazione
      </ng-container>

      <p i18n>
        Ti suggeriamo di controllare che i dati richiesti siano stati inseriti
        correttamente.
      </p>
    </it-modal>
  `,
})
export class OthersReservationsPageComponent {
  modal = viewChild.required<ItModalComponent>("modal");
  inputs = viewChildren<InputComponent>("inputFiscalCode, inputNumber");
  router = inject(Router);
  formProvider = inject(FormProviderService);
  reservationService = inject(ReservationService);
  noNumberAction: Action = {
    label: $localize`Non hai il numero di prenotazione?`,
    type: "no-number",
    relevance: "primary-task",
  };

  formBuilder = inject(FormBuilder);

  constructor() {
    this.formProvider.initServiceForm(
      this.formBuilder.nonNullable.group({
        fiscalCode: ["", [Validators.required, italianFiscalCodeValidator]],
        number: ["", [Validators.required]],
      })
    );
  }

  async handleSubmit() {
    const { form } = this.formProvider;

    if (!form) return;

    Object.values(form.controls).forEach((control) => {
      control.markAsTouched();
      control.updateValueAndValidity();
    });

    const firstInvalidControl = this.inputs().find((input) => {
      const name = input.name();
      const control = form.get(name);
      return control?.invalid;
    });

    if (form.invalid) {
      firstInvalidControl?.inputRef?.focus();
      return;
    }

    const { number, fiscalCode: cf } = form.value;

    try {
      await lastValueFrom(
        this.reservationService.getReservationDetail({
          cf,
          codPrenotazione: number,
        })
      );

      void this.router.navigate(
        [absoluteRoutesPaths.othersReservationDetail.replace(":id", number)],
        {
          queryParams: {
            cf,
          },
        }
      );
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        switch (e.status) {
          case 404:
            this.modal().show();
            break;
        }
      }
    }
  }
}
