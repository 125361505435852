import {
  computed,
  ErrorHandler,
  inject,
  Injectable,
  signal,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NewReservationStepId } from "@/app/pages/new-reservation-page/new-reservation-page.component";
import { StepItem } from "@/types/step-item.interface";
import { ApplicantStepComponent } from "@/organisms/new-reservation/applicant-step/applicant-step.component";
import { RecipesListStepComponent } from "@/organisms/new-reservation/recipes-list-step/recipes-list-step.component";
import { ActivatedRoute } from "@angular/router";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import {
  combineLatest,
  lastValueFrom,
  map,
  merge,
  startWith,
  takeUntil,
  tap,
} from "rxjs";
import validateForm from "@/utils/functions/validate-form";
import italianFiscalCodeValidator from "@/utils/functions/italian-fiscal-code.validator";
import { AppointmentStepComponent } from "@/organisms/new-reservation/appointment-step/appointment-step.component";
import Appointment from "@/types/appointment.interface";
import { RecapStepComponent } from "@/organisms/new-reservation/recap-step/recap-step.component";
import { Destroyable } from "@/classes/destroyable";
import { SessionStorageService } from "@/services/session-storage.service";
import { MissingContactsModalComponent } from "@/molecules/missing-contacts-modal/missing-contacts-modal.component";
import { UserService, VerifyAndUpdatePayload } from "@/services/user.service";
import {
  AgendaCalendario,
  Ricetta,
  SearchReservationAvailabilityResponse,
  StartReservationResponse,
  VerifyAndUpdateAssistitoResponseBody,
} from "@/types/api";
import { ReservationService } from "@/services/reservation.service";
import { StepperService } from "@/services/stepper.service";
import AppointmentsPage from "@/types/appointments-page.interface";
import { ResponseClientError } from "@/classes/errors";

@Injectable()
export class NewReservationService extends Destroyable {
  userService = inject(UserService);
  formBuilder = inject(FormBuilder);
  activatedRoute = inject(ActivatedRoute);
  stepperService = inject(StepperService);
  reservationService = inject(ReservationService);
  sessionStorageService = inject(SessionStorageService);
  errorHandler = inject(ErrorHandler);

  selectedNreList: string[] = [];
  selectedTarget = "me";

  reservation: FormGroup<{
    fiscalCode: FormControl<string>;
    email: FormControl<string>;
    phoneNumber: FormControl<string>;
    privacy: FormControl<boolean>;
    appointment: FormControl<string>;
    waitingList: FormControl<string>;
    // recipes is an array of JSON objects { id: string, fiscalCode: string }
    recipes: FormControl<string[]>;
  }> = this.formBuilder.nonNullable.group({
    fiscalCode: this.formBuilder.nonNullable.control("", {
      validators: [Validators.required, italianFiscalCodeValidator],
    }),
    email: [
      "",
      {
        validators: [Validators.required, Validators.email],
      },
    ],
    waitingList: [""],
    phoneNumber: ["", [Validators.required]],
    privacy: [
      false,
      { validators: [Validators.requiredTrue], updateOn: "change" },
    ],
    appointment: ["", [Validators.required]],
    recipes: this.formBuilder.nonNullable.control<string[]>([]),
  });

  target: FormControl<"me" | "other"> = this.formBuilder.nonNullable.control(
    "me",
    {
      validators: [Validators.required],
    }
  );

  userRecipes = signal<Record<string, Ricetta[]>>({});
  submittedFiscalCode = signal("");
  contactsAddedAt = signal<number | null>(null);
  verifiedUserData = signal<VerifyAndUpdateAssistitoResponseBody | null>(null);
  startReservationResponse = signal<StartReservationResponse | null>(null);
  availabilityResponse = signal<SearchReservationAvailabilityResponse | null>(
    null
  );
  appointmentsIndex = signal(0);
  appointmentsFilters = signal({
    date: "",
    isUserDistrictOnly: true,
  });
  currentStepId = signal<NewReservationStepId>("applicant");
  controlRefs = signal<Record<string, HTMLElement>>({});
  steps = signal<StepItem<NewReservationStepId>[]>([
    {
      id: "applicant",
      isDone: false,
      isFirstStep: true,
      nextStep: "recipes-list",
      title: $localize`Richiedente`,
      component: ApplicantStepComponent,
      validateStep: () => this.validateApplicantStep(),
    },
    {
      id: "recipes-list",
      isDone: false,
      prevStep: "applicant",
      nextStep: "appointment",
      title: $localize`Lista ricette`,
      component: RecipesListStepComponent,
      validateStep: () => this.validateRecipesListStep(),
      disableStep$: () =>
        this.recipesControl.valueChanges.pipe(
          startWith(this.recipesControl.value),
          map((recipes) => !recipes.length)
        ),
    },
    {
      id: "appointment",
      isDone: false,
      nextStep: "recap",
      prevStep: "recipes-list",
      component: AppointmentStepComponent,
      title: $localize`Appuntamento`,
      showModal: () =>
        this.reservation.controls.waitingList.value !== "" &&
        !this.userService.userHasContactData(),
      modal: {
        component: MissingContactsModalComponent,
      },
      disableStep$: () =>
        combineLatest([
          this.reservation.controls.waitingList.valueChanges.pipe(
            startWith(this.reservation.controls.waitingList.value)
          ),
          this.reservation.controls.appointment.valueChanges.pipe(
            startWith(this.reservation.controls.appointment.value)
          ),
        ]).pipe(
          map(([waitingList, appointment]) => !waitingList && !appointment)
        ),
    },
    {
      id: "recap",
      isDone: false,
      prevStep: "appointment",
      title: $localize`Riepilogo`,
      component: RecapStepComponent,
    },
  ]);

  recipesValue = toSignal(
    this.recipesControl.valueChanges.pipe(
      startWith(this.recipesControl.value),
      map((value) =>
        value
          .map((item) => JSON.parse(item))
          .filter((item) => item.fiscalCode === this.submittedFiscalCode())
          .map((item) => item.id)
      )
    )
  );

  lastRecipe = computed(() => {
    const selectedRecipes = this.recipesValue();

    if (!selectedRecipes) return null;

    const lastRecipeId = selectedRecipes.at(-1);

    if (!lastRecipeId) return null;

    return (
      this.allRecipes().find((recipe) => recipe.nre === lastRecipeId) || null
    );
  });

  mappedRecipeCategory = computed(() => {
    const lastRecipe = this.lastRecipe();
    if (!lastRecipe) return "";

    return lastRecipe.laboratorio
      ? $localize`Esami di laboratorio`
      : $localize`Visita specialistica`;
  });

  userDistrict = computed(() => {
    const data = this.startReservationResponse()!;
    return data.ambiti.find((a) => a.code === data.selectedAmbito);
  });

  regionDistrict = computed(() => {
    const data = this.startReservationResponse()!;

    if (this.verifiedUserData()?.fuoriRegione) {
      return this.userDistrict();
    }

    return data.ambiti.find((a) => a.code !== data.selectedAmbito);
  });

  cupRecipes = toSignal<Ricetta[]>(
    this.activatedRoute.data.pipe(
      map((data) => data["recipes"] as Ricetta[]),
      map((recipes) =>
        recipes.map((r) => ({
          ...r,
          isDisabled: false,
          insertedByUser: false,
        }))
      )
    )
  );

  allAppointments = computed<AppointmentsPage[]>(() => {
    const availability = this.availabilityResponse();

    if (!availability) return [];

    return Object.entries({
      ...availability.calendario,
      ...Object.entries(availability.tutela).reduce(
        (acc, [date, buildingsObj]) => ({
          ...acc,
          [date]: Object.entries(buildingsObj).reduce(
            (acc, [agendaId, appointmentsObj]) => ({
              ...acc,
              [agendaId]: {
                ...appointmentsObj,
                isWaitingList: true,
              },
            }),
            {}
          ),
        }),
        {} as Record<string, Record<string, AgendaCalendario>>
      ),
    }).map(([date, buildingsObj]) => ({
      date,
      availableAppointments: Object.entries(buildingsObj).map<Appointment>(
        ([agendaId, { agendeBean: agenda, calendari, isWaitingList }]) => {
          const hoursSet = new Set();

          return {
            id: agendaId,
            date,
            address: `${agenda.indStruttura || $localize`n.d.`}`,
            availableHours: calendari
              .map((c) => ({
                label: c.ora,
                value: JSON.stringify({
                  agenda,
                  calendar: c,
                }),
              }))
              .filter((item) => {
                if (hoursSet.has(item.label)) return false;
                hoursSet.add(item.label);
                return true;
              }),
            city:
              agenda.desComune || $localize`Descrizione comune non disponibile`,
            building:
              agenda.desStruttura ||
              $localize`Descrizione struttura non disponibile`,
            district: agenda.distrettis?.join(" ") || "",
            location: agenda.desAgenda,
            isWaitingList: !!isWaitingList,
          };
        }
      ),
    }));
  });

  waitingListReservation = computed(() => {
    return this.allAppointments()
      .flatMap((a) => a.availableAppointments)
      .find((a) => a.isWaitingList);
  });

  filteredAppointments = computed(() => {
    return this.allAppointments().filter(
      (a) => !a.availableAppointments.find((app) => app.isWaitingList)
    );
  });

  pageAppointments = computed<AppointmentsPage[]>(() => {
    const { date } = this.appointmentsFilters();

    if (date) {
      return this.filteredAppointments().filter(
        (a) => a.date.split("T")[0] === date
      );
    }

    return this.filteredAppointments().reduce<AppointmentsPage[]>(
      (acc, cur, i) => [
        ...acc,
        ...(i <= this.appointmentsIndex() ? [cur] : []),
      ],
      []
    );
  });

  allRecipes = computed<Ricetta[]>(() => {
    return [
      ...(this.userRecipes()[this.submittedFiscalCode()] || []),
      ...(this.cupRecipes() || []),
    ];
  });

  get recipesControl(): FormControl<string[]> {
    return this.reservation.get("recipes") as FormControl;
  }

  constructor() {
    super();
    this.restoreDataFromSessionStorage();
    this.synchronizeSessionStorage();
  }

  private synchronizeSessionStorage() {
    const steps$ = toObservable(this.steps).pipe(takeUntil(this.destroy$));
    const currentStepId$ = toObservable(this.currentStepId).pipe(
      takeUntil(this.destroy$)
    );

    const reservation$ = this.reservation.valueChanges.pipe(
      takeUntil(this.destroy$)
    );

    const target$ = this.target.valueChanges.pipe(
      startWith(this.target.value),
      takeUntil(this.destroy$)
    );

    steps$.subscribe((steps) =>
      this.sessionStorageService.updateReservationStepsStorage(steps)
    );

    target$.subscribe((target) => {
      this.sessionStorageService.updateTarget(target);
    });

    currentStepId$.subscribe((id) =>
      this.sessionStorageService.updateStepIdStorage(id)
    );

    reservation$.subscribe((reservation) => {
      this.sessionStorageService.updateReservationStorage(reservation);
    });

    merge(steps$, currentStepId$, reservation$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() =>
        this.sessionStorageService.updateReservationStorageExpireDate()
      );
  }

  private clearReservationData() {
    const expireDate = this.sessionStorageService.reservationExpireDate;

    if (expireDate && Date.now() >= +expireDate) {
      this.sessionStorageService.clearReservationStorage();
    }
  }

  private restoreDataFromSessionStorage() {
    this.clearReservationData();

    const {
      reservationAppointmentDateFilter: appointmentFilterDate,
      reservationUserDistrict: userDistrict,
      reservationTarget: storedReservationTarget,
      verifiedUser: storedUserVerifiedData,
      reservation: savedReservationFormValue,
      reservationSteps,
      reservationStepId: savedStepId,
      reservationAvailabilityResponse: storedAvailability,
      startReservationResponse: startReservationResponse,
      reservationUserRecipes: userRecipes,
      submittedFiscalCode,
    } = this.sessionStorageService;

    const steps: StepItem<NewReservationStepId>[] = reservationSteps;

    this.appointmentsFilters.set({
      date: appointmentFilterDate || "",
      isUserDistrictOnly: userDistrict === "true",
    });

    if (submittedFiscalCode) {
      this.submittedFiscalCode.set(submittedFiscalCode);
    }

    if (userRecipes) {
      this.userRecipes.set(JSON.parse(userRecipes));
    }

    if (storedAvailability) {
      this.availabilityResponse.set(JSON.parse(storedAvailability));
    }

    if (startReservationResponse) {
      this.startReservationResponse.set(JSON.parse(startReservationResponse));
    }

    if (storedReservationTarget) {
      this.target.setValue(storedReservationTarget as "me" | "other");
    }

    if (storedUserVerifiedData) {
      this.verifiedUserData.set(storedUserVerifiedData);
    }

    if (savedReservationFormValue) {
      this.reservation.setValue(savedReservationFormValue);
    }

    if (savedStepId) {
      this.currentStepId.set(savedStepId);
    }

    if (steps) {
      this.steps.update((reservationSteps) =>
        reservationSteps.map((step) => ({
          ...step,
          isDone:
            steps.find((savedStep) => savedStep.id === step.id)?.isDone ||
            false,
        }))
      );
    }
  }

  get verifiedUserFullName() {
    const data = this.verifiedUserData();
    if (!data) return "";

    const { nome, cognome } = data;

    if (!nome || !cognome) return "";

    return `${nome} ${cognome}`;
  }

  selectedRecipes = computed(() =>
    this.getRecipesIds()
      .map((id) => {
        return this.allRecipes().find((recipe) => recipe.nre === id);
      })
      .filter((recipe) => !!recipe)
  );

  getRecipesIds() {
    return this.recipesControl.value
      .map((item) => JSON.parse(item))
      .filter((item) => item.fiscalCode === this.submittedFiscalCode())
      .map((item) => item.id);
  }

  async validateApplicantStep() {
    const { emailRef, fiscalCodeRef, phoneNumberRef, applicantPrivacyRef } =
      this.controlRefs();

    const { email, fiscalCode, phoneNumber, privacy } =
      this.reservation.controls;

    const stepControls = [
      {
        name: "fiscalCode",
        type: "input",
        control: fiscalCode,
        ref: fiscalCodeRef,
      },
      {
        name: "phoneNumber",
        type: "input",
        control: phoneNumber,
        ref: phoneNumberRef,
      },
      {
        name: "email",
        type: "input",
        control: email,
        ref: emailRef,
      },
      {
        type: "checkbox",
        name: "privacy",
        control: privacy,
        ref: applicantPrivacyRef,
      },
    ];

    try {
      if (this.selectedTarget !== this.target.value) {
        this.recipesControl.setValue([]);
      }

      this.selectedTarget = this.target.value;

      if (this.target.value === "me") {
        await lastValueFrom(
          this.verifyUser({
            email: this.userService.user()?.contactData?.email || "",
            telefono: this.userService.user()?.contactData?.mobile || "",
            codiceFiscale: this.userService.userJwtFiscalCode,
          })
        );

        this.submittedFiscalCode.set(this.userService.userJwtFiscalCode);

        this.sessionStorageService.updateSubmittedReservationFiscalCode(
          this.userService.userJwtFiscalCode
        );

        this.userRecipes.update((recipes) => ({
          ...recipes,
          [this.userService.userJwtFiscalCode]: [
            ...(this.userRecipes()[this.userService.userJwtFiscalCode] || []),
          ],
        }));

        stepControls.forEach(({ control }) => control.markAsUntouched());
      } else {
        const isFormValid = validateForm(stepControls);

        if (!isFormValid) {
          throw new Error("Form is not valid");
        }

        if (fiscalCode.value === this.userService.userJwtFiscalCode) {
          this.errorHandler.handleError(
            new ResponseClientError({
              title: $localize`Codice fiscale non valido`,
              detail: $localize`Il codice fiscale inserito coincide con quello della tua utenza. Prova con un altro codice fiscale oppure procedi prenotando per te stesso.`,
            })
          );

          throw new Error("Invalid fiscal code");
        }

        await lastValueFrom(
          this.verifyUser({
            email: email.value,
            telefono: phoneNumber.value,
            codiceFiscale: fiscalCode.value,
          })
        );

        this.submittedFiscalCode.set(fiscalCode.value);
        this.sessionStorageService.updateSubmittedReservationFiscalCode(
          fiscalCode.value
        );

        this.userRecipes.update((recipes) => ({
          ...recipes,
          [fiscalCode.value]: [...(this.userRecipes()[fiscalCode.value] || [])],
        }));
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }

      return false;
    }

    return true;
  }

  resetSelectedAppointment() {
    this.reservation.controls.waitingList.setValue("");
    this.reservation.controls.appointment.setValue("");
  }

  async validateRecipesListStep() {
    const nreList = this.reservation.controls.recipes.value;

    const isSelectedRecipesListSet = this.selectedNreList.length > 0;

    const isDifferentRecipesList =
      isSelectedRecipesListSet &&
      !!nreList.find((nre) => !this.selectedNreList.includes(nre));

    if (isDifferentRecipesList) {
      this.resetSelectedAppointment();
    }

    this.selectedNreList = nreList;

    const fiscalCode = this.verifiedUserData()?.codiceFiscale;

    if (!fiscalCode) {
      console.error("Verified user fiscal code not found");
      return false;
    }

    if (!this.selectedNreList) {
      console.error("Recipes not found");
      return false;
    }

    try {
      this.stepperService.loadingLabel.set(
        $localize`Cerco le disponibilità per la ricetta selezionata...`
      );

      const response = await lastValueFrom(
        this.reservationService.startReservation({
          nreList: this.getRecipesIds(),
          fiscalCode,
        })
      );

      this.startReservationResponse.set(response);
      this.sessionStorageService.updateStartReservationResponse(response);
      await this.updateReservationAvailability(
        !this.verifiedUserData()?.fuoriRegione
      );

      this.appointmentsFilters.set({
        date: "",
        isUserDistrictOnly: true,
      });

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.stepperService.resetLoadingLabel();
    }
  }

  verifyUser(payload: VerifyAndUpdatePayload) {
    return this.userService.verifyAndUpdate(payload).pipe(
      tap((response) => {
        this.verifiedUserData.set(response);
        this.sessionStorageService.updateVerifiedUserData(response);
      })
    );
  }

  async updateReservationAvailability(isUserDistrictOnly: boolean) {
    try {
      const availabilityResponse = await lastValueFrom(
        this.reservationService.searchReservationAvailability({
          nreList: this.getRecipesIds(),
          cfAssistito: this.verifiedUserData()!.codiceFiscale,
          codAmbito: isUserDistrictOnly
            ? this.userDistrict()!.code
            : this.regionDistrict()!.code,
          dataInizio: this.startReservationResponse()!.dataInizioPrenotazione,
        })
      );

      this.availabilityResponse.set(availabilityResponse);

      this.sessionStorageService.updateAvailabilityResponse(
        availabilityResponse
      );
    } catch (error) {
      console.error(error);
    }
  }
}
