export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_WEEK = ONE_DAY * 7;
export const YESTERDAY = `${
  new Date(new Date().getTime() - ONE_DAY).toISOString().split("T")[0]
}T23:59:59`;

export const FIRST_OF_THE_YEAR = `${new Date().getFullYear()}-01-01T00:00:00`;
