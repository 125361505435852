export default function base64ToBlob(
  base64: string,
  contentType = "application/pdf"
) {
  const binary = atob(base64);
  const array = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    array[i] = binary.charCodeAt(i);
  }
  return new Blob([array], { type: contentType });
}
