import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { GridColDirective } from "@/atoms/grid-col.component";
import { RecipeCardComponent } from "@/molecules/recipe-card/recipe-card.component";
import { FormGroup } from "@angular/forms";
import { Ricetta } from "@/types/api";
import StringifyObjectPipe from "@/pipes/stringify-object.pipe";

@Component({
  selector: "cup-recipes-grid",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [GridColDirective, RecipeCardComponent, StringifyObjectPipe],
  styles: `
    @use "cup";

    .receipt-row {
      margin-top: cup.toRem(16);
      row-gap: cup.toRem(16);

      @include cup.media-breakpoint-up(lg) {
        margin-top: cup.toRem(24);
        row-gap: cup.toRem(24);
      }
    }
  `,
  template: ` <h3 class="h4">{{ gridTitle() }}</h3>

    @let description = gridDescription();

    @if (description) {
      <p class="mt-2">{{ description }}</p>
    }

    <div class="receipt-row row">
      @for (recipe of recipes(); track recipe.nre) {
        <div cupGridCol [config]="{ xs: 12, lg: 4 }">
          <cup-recipe-card
            name="recipes"
            [isDisabled]="recipe.isDisabled"
            [formGroup]="formGroup()"
            [showRemoveButton]="!!recipe.showRemoveButton"
            (removeRecipe)="recipe.removeRecipe?.()"
            [value]="
              { id: recipe.nre, fiscalCode: fiscalCode() } | stringifyObject
            "
            [inputId]="'recipe' + recipe.nre"
            [recipe]="recipe"
          />
        </div>
      }
    </div>`,
})
export class RecipesGridComponent {
  fiscalCode = input.required<string>();
  formGroup = input.required<FormGroup>();
  gridTitle = input.required<string>();
  gridDescription = input<string>();
  recipes =
    input.required<
      (Ricetta & { showRemoveButton?: boolean; removeRecipe?(): void })[]
    >();
}
