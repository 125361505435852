import { inject, Pipe, PipeTransform } from "@angular/core";
import {
  Appuntamento,
  ReservationsHistoryResponseBody,
  ReservationsResponseBody,
} from "@/types/api";
import ReservationDetailModel from "@/types/reservation-detail-model.interface";
import { PaymentBadgeComponent } from "@/atoms/payment-badge/payment-badge.component";
import { TableLinkComponent } from "@/atoms/table-link/table-link.component";
import { TableMobileCardComponent } from "@/molecules/table-mobile-card.component";
import { UserService } from "@/services/user.service";
import { MappedTableData, TableSort } from "@/types/table-sort";
import { Router } from "@angular/router";
import stringifyObjectFields from "@/utils/functions/stringify-object-fields";
import { DatePipe } from "@angular/common";
import { DeriveReservationPaymentStatusPipe } from "@/pipes/derive-reservation-payment-status.pipe";

@Pipe({
  name: "mapReservationData",
  standalone: true,
})
export class MapReservationDataPipe implements PipeTransform {
  userService = inject(UserService);
  router = inject(Router);
  datePipe = inject(DatePipe);
  deriveReservationStatus = inject(DeriveReservationPaymentStatusPipe);

  isReservationHistory(
    data: unknown,
    type: "history" | "user"
  ): data is ReservationsHistoryResponseBody {
    return type === "history";
  }

  isReservationUser(
    data: unknown,
    type: "history" | "user"
  ): data is ReservationsResponseBody {
    return type === "user";
  }

  transform({
    type,
    sort,
    data,
    additionalFilters,
  }: ReservationDataMappingOptions): MappedTableData {
    let reservationData: Appuntamento[];

    if (this.isReservationHistory(data, type)) {
      reservationData = data.appuntamenti;
    } else if (this.isReservationUser(data, type)) {
      reservationData = [...data.tutela, ...data.appuntamenti.appuntamenti];
    } else {
      reservationData = [];
    }

    if (sort) {
      reservationData = reservationData.sort((a, b) => {
        switch (sort.type) {
          case "date": {
            const field = sort.field as keyof typeof a;
            const dateA = a[field];
            const dateB = b[field];

            if (typeof dateA !== "string" || typeof dateB !== "string") {
              break;
            }

            return sort.direction === "ASC"
              ? new Date(dateA).getTime() - new Date(dateB).getTime()
              : new Date(dateB).getTime() - new Date(dateA).getTime();
          }
        }

        return 0;
      });
    }

    let mappedRecords = reservationData.map<ReservationDetailModel>((item) => {
      const reservationStatus = this.deriveReservationStatus.transform(item);

      return {
        id: item.appuntamento,
        title: item.unitaErogante.descrizioneDisciplina,
        copy: "",
        ctaAction: null,
        actions: [],
        paymentStatus: reservationStatus?.status,
        paymentStatusFilter: reservationStatus?.label || "",
        applicant: this.userService.userJwtFullName,
        date: item.dataOraAppuntamento,
        formattedDate: this.datePipe.transform(
          item.dataOraAppuntamento,
          "dd/MM/yyyy, HH:mm"
        ),
        building: item.unitaErogante.descrizioneStrutturaErogante,
        address: item.unitaErogante.indirizzo,
        postalCode: item.unitaErogante.cap,
        city: item.unitaErogante.descrizioneComune,
        province: item.unitaErogante.siglaProvincia,
        location: item.unitaErogante.descrizioneUnitaErogante,
        documents: [],
      };
    });

    if (additionalFilters) {
      mappedRecords = mappedRecords.reduce<ReservationDetailModel[]>(
        (acc, cur) => {
          const stringifiedReservation = stringifyObjectFields(cur);
          const shouldAddCur = additionalFilters.every((filter) => {
            if (!filter.value) return true;

            switch (filter.type) {
              case "full-text":
                return stringifiedReservation
                  .toLowerCase()
                  .includes(filter.value.toLowerCase());
            }
          });

          if (!shouldAddCur) return acc;

          return [...acc, cur];
        },
        []
      );
    }

    return {
      desktop:
        mappedRecords.map((reservation) => ({
          id: reservation.id,
          columns: [
            { value: reservation.title, format: "titlecase" },
            {
              ...(reservation.paymentStatus !== "waiting-list"
                ? {
                    value: reservation.date,
                    format: "date",
                  }
                : {
                    value: $localize`In lista d'attesa`,
                  }),
            },
            { value: reservation.building, format: "titlecase" },
            { value: reservation.id.toString() },
            {
              component: PaymentBadgeComponent,
              inputs: { paymentStatus: reservation.paymentStatus },
            },
            {
              component: TableLinkComponent,
              inputs: {
                label: $localize`Visualizza`,
                anchorHref: `${this.router.url}/${reservation.id}`,
              },
            },
          ],
        })) || [],
      mobile: mappedRecords.map((reservation) => ({
        component: TableMobileCardComponent,
        inputs: {
          id: reservation.id,
          serviceName: reservation.title,
          dateTime:
            reservation.paymentStatus === "waiting-list"
              ? $localize`In lista d'attesa`
              : reservation.date,
          location: reservation.building,
          paymentStatus: reservation.paymentStatus,
        },
      })),
      headings: [
        { label: $localize`Prestazione` },
        { label: $localize`Data e ora` },
        { label: $localize`Struttura` },
        { label: $localize`N. prenotazione` },
        { label: $localize`Stato` },
        { label: $localize`Azioni` },
      ],
    };
  }
}

export interface ReservationDataMappingOptions {
  type: "history" | "user";
  data: unknown;
  sort?: TableSort;
  additionalFilters?: {
    type: "full-text";
    value?: string | null;
  }[];
}
