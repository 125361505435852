import { computed, effect, inject, Injectable, signal } from "@angular/core";
import { NewReservationService } from "@/services/new-reservation.service";
import { LoadingService } from "@/services/loading.service";

@Injectable()
export class AppointmentReservationService {
  newReservationService = inject(NewReservationService);
  loadingService = inject(LoadingService);

  readonly noAvailability = $localize`Nessuna disponibilità`;

  readonly alertStaticData = {
    waitingList: {
      title: this.noAvailability,
      message: $localize`Puoi inserirti in lista d’attesa, verrai contattato in caso di disponibilità.`,
    },
    noAvailability: {
      title: this.noAvailability,
      message: $localize`Non ci sono posti disponibili entro i tempi massimi previsti dalla priorità della ricetta.`,
    },
    noAvailabilityForDateSelected: {
      title: this.noAvailability,
      message: $localize`Nessuna disponibilità per la data selezionata. Cancella la data impostata o controlla la disponibilità in altre date.`,
    },
    extendResearch: {
      title: $localize`Non ci sono posti nel tuo distretto`,
      message: $localize`Prova ad estendere la ricerca all'intera regione.`,
      action: {
        label: $localize`Estendi ricerca`,
        onClick: () => {
          this.newReservationService.appointmentsFilters.update((filters) => ({
            ...filters,
            isUserDistrictOnly: false,
          }));
        },
      },
    },
  };

  showWaitingListCheckbox = signal(false);

  updateDistrictFilterEffect = effect(() => {
    const isAutomaticRegionalSearch =
      !!this.newReservationService.availabilityResponse()?.ricercaRegionaleAuto;

    if (
      !isAutomaticRegionalSearch ||
      !this.isFragileUser() ||
      this.areAppointmentsAvailable()
    ) {
      return;
    }

    this.newReservationService.appointmentsFilters.update((filters) => ({
      ...filters,
      isUserDistrictOnly: false,
    }));
  });

  updateShowWaitingListCheckboxEffect = effect(() => {
    if (this.isFragileUser()) {
      return this.showWaitingListCheckbox.set(!this.areAppointmentsAvailable());
    }

    if (this.isNonRegionalUser()) {
      return this.showWaitingListCheckbox.set(false);
    }

    return this.showWaitingListCheckbox.set(
      this.isShowingRegionalAvailability() && !this.areAppointmentsAvailable()
    );
  });

  alertData = computed<AlertData | null>(() => {
    if (this.loadingService.isAppLoading()) {
      return null;
    }

    if (this.isNonRegionalUser()) {
      return this.nonRegionalAlertData();
    }

    if (this.isFragileUser()) {
      return this.fragileUserAlertData();
    }

    if (!this.areAppointmentsAvailable()) {
      return this.nonFragileRegionalUserAlertData();
    }

    if (!this.arePageAppointmentsAvailable()) {
      if (this.isShowingRegionalAvailability()) {
        return this.alertStaticData.noAvailabilityForDateSelected;
      }

      return this.alertStaticData.extendResearch;
    }

    return null;
  });

  isNonRegionalUser = computed(
    () => !!this.newReservationService.verifiedUserData()?.fuoriRegione
  );

  isFragileUser = computed(
    () => !!this.newReservationService.verifiedUserData()?.fragile
  );

  areAppointmentsAvailable = computed(() => {
    const response = this.newReservationService.availabilityResponse();

    if (!response) return true;

    return Object.values(response.calendario).length > 0;
  });

  arePageAppointmentsAvailable = computed(() => {
    const appointments = this.newReservationService.pageAppointments();
    if (!appointments) return true;
    return appointments.length > 0;
  });

  isShowingRegionalAvailability = computed(
    () => !this.newReservationService.appointmentsFilters().isUserDistrictOnly
  );

  nonRegionalAlertData = computed(() => {
    /*
    if (
      this.isShowingRegionalAvailability() &&
      !this.areAppointmentsAvailable()
    ) {
    */
    if (!this.areAppointmentsAvailable()) {
      return this.alertStaticData.noAvailability;
    }

    return null;
  });

  fragileUserAlertData = computed(() => {
    if (!this.areAppointmentsAvailable()) {
      return this.alertStaticData.waitingList;
    }

    return null;
  });

  nonFragileRegionalUserAlertData = computed(() => {
    if (!this.areAppointmentsAvailable()) {
      if (this.isShowingRegionalAvailability()) {
        return this.alertStaticData.waitingList;
      }

      return this.alertStaticData.extendResearch;
    }

    return null;
  });
}

interface AlertData {
  title: string;
  message: string;
  action?: { label: string; onClick(): void };
}
