import { inject } from "@angular/core";
import { UserService } from "@/services/user.service";
import { catchError, forkJoin, map, of, tap } from "rxjs";
import UserPersonalData, { UserContactData, UserData } from "@/types/api";

export default function userResolver() {
  const userService = inject(UserService);

  return forkJoin([
    userService.getUserPersonalData().pipe(catchError(() => of(null))),
    userService.getUserContactData().pipe(catchError(() => of(null))),
  ]).pipe(
    map<[UserPersonalData | null, UserContactData | null], UserData>(
      ([personalData, contactData]) => ({
        contactData,
        personalData,
      })
    ),
    tap((user) => userService.user.set(user))
  );
}
