import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserService } from "@/services/user.service";
import { Ricetta } from "@/types/api";
import { NgHttpCachingHeaders } from "ng-http-caching";
import { ErrorService } from "@/services/error.service";
import { ResponseClientError } from "@/classes/errors";
import { SessionStorageService } from "@/services/session-storage.service";
import { takeUntil } from "rxjs";
import cancelRequest$ from "@/subjects/cancel-request";

@Injectable({
  providedIn: "root",
})
export class RecipesService {
  http = inject(HttpClient);
  userService = inject(UserService);
  errorService = inject(ErrorService);
  sessionStorageService = inject(SessionStorageService);

  getAllRecipes() {
    const sessionId = this.userService.sessionId();

    if (!sessionId) {
      throw new ResponseClientError(this.errorService.userNotLoggedError);
    }

    return this.http
      .post<Ricetta[]>(
        `fsepl/documents/recipes`,
        {
          patientId: this.userService.userJwtFiscalCode,
        },
        {
          params: { idSessione: sessionId },
          headers: {
            [NgHttpCachingHeaders.TAG]: "reservationRecipes",
          },
        }
      )
      .pipe(takeUntil(cancelRequest$));
  }

  getRecipeById(recipeId: string) {
    return this.http
      .post<Ricetta>(`cupservice/ricetta/ricerca/${recipeId}`, {
        idSessione: this.userService.sessionId(),
        cf: this.userService.userJwtFiscalCode,
        nre: recipeId,
      })
      .pipe(takeUntil(cancelRequest$));
  }

  getRecipeURL(recipeId: string, fiscalCode?: string) {
    return this.http
      .post(
        `cupservice/ricetta/promemoria`,
        {
          cf: fiscalCode || this.userService.userJwtFiscalCode,
          nre: recipeId,
        },
        {
          responseType: "text",
          headers: {
            [NgHttpCachingHeaders.DISALLOW_CACHE]: "1",
          },
        }
      )
      .pipe(takeUntil(cancelRequest$));
  }

  searchRecipe(nre: string, fiscalCode?: string) {
    return this.http
      .post<Omit<Ricetta, "isDisabled" | "insertedByUser">>(
        `cupservice/ricetta/ricerca/${nre}`,
        {
          idSessione: this.userService.sessionId(),
          cf: fiscalCode || this.userService.userJwtFiscalCode,
          nre,
        },
        {
          headers: {
            [NgHttpCachingHeaders.DISALLOW_CACHE]: "1",
          },
        }
      )
      .pipe(takeUntil(cancelRequest$));
  }
}
