import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  OnInit,
  viewChild,
} from "@angular/core";
import { BreadcrumbComponent } from "@/molecules/breadcrumb/breadcrumb.component";
import { HeroComponent } from "@/molecules/hero.component";
import { ActivatedRoute } from "@angular/router";
import { toSignal } from "@angular/core/rxjs-interop";
import { map } from "rxjs";
import { Action } from "@/types/action.interface";
import { FullLayoutComponent } from "@/atoms/full-layout.component";
import base64ToBlob from "@/utils/base64-to-blob";
import { SafeUrlPipe } from "@/pipes/safe-url.pipe";
import downloadBlob from "@/utils/functions/download-blob";
import { ItButtonDirective, ItIconComponent } from "design-angular-kit";
import { absoluteRoutesPaths } from "@/classes/route-utils";

@Component({
  selector: "cup-recipe-detail",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BreadcrumbComponent,
    HeroComponent,
    FullLayoutComponent,
    SafeUrlPipe,
    ItButtonDirective,
    ItIconComponent,
  ],
  styles: `
    @use "cup";

    iframe {
      aspect-ratio: 1/1;
      width: 100%;

      @include cup.media-breakpoint-up(lg) {
        aspect-ratio: 16/9;
      }
    }
  `,
  template: `
    <cup-breadcrumb />

    <cup-hero
      [heroTitle]="heroTitle() || ''"
      [sideActions]="actions"
      (actionClick)="handleAction($event)"
    />

    <cup-full-layout colClass="mb-5 d-flex flex-column gap-5">
      <iframe
        #iframe
        [src]="iframeUrl() | safeUrl"
        [title]="iframeTitle()"
      ></iframe>

      <a
        class="align-self-start gap-1"
        itButton="outline-secondary"
        [href]="absoluteRoutesPaths.newReservation"
      >
        <it-icon name="arrow-left" />
        Torna indietro
      </a>
    </cup-full-layout>
  `,
  standalone: true,
})
export class RecipeDetailPageComponent implements OnInit {
  heroTitle = toSignal(
    inject(ActivatedRoute).params.pipe(
      map((params) => $localize`Ricetta nr. ${params["recipe-id"]}`)
    )
  );

  iframeTitle = toSignal(
    inject(ActivatedRoute).params.pipe(
      map((params) => $localize`Ricetta nr. ${params["recipe-id"]}`)
    )
  );

  route = inject(ActivatedRoute);

  iframeElement = viewChild.required<HTMLIFrameElement>("iframe");

  recipeBlob = toSignal(
    this.route.data.pipe(map((data) => base64ToBlob(data["recipe"])))
  );

  iframeUrl = computed(() => URL.createObjectURL(this.recipeBlob()!));

  ngOnInit() {
    this.iframeElement().onload = () => URL.revokeObjectURL(this.iframeUrl());
  }

  readonly actions: Action<"download-pdf">[] = [
    {
      type: "download-pdf",
      label: $localize`Scarica PDF`,
      icon: "download",
    },
  ];

  handleAction(action: Action) {
    switch (action.type) {
      case "download-pdf":
        downloadBlob($localize`ricetta`, this.recipeBlob()!);
        break;
    }
  }

  protected readonly absoluteRoutesPaths = absoluteRoutesPaths;
}
