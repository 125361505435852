import { ChangeDetectionStrategy, Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { NewReservationService } from "@/services/new-reservation.service";

@Component({
  selector: "cup-reservation-provider",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [NewReservationService],
  imports: [RouterOutlet],
  template: ` <router-outlet /> `,
})
export class ReservationProviderComponent {}
