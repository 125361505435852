import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { ItRadioButtonComponent } from "design-angular-kit";
import Option from "@/types/option.interface";
import { ReactiveFormsModule } from "@angular/forms";
import { GridColDirective } from "@/atoms/grid-col.component";
import { toSignal } from "@angular/core/rxjs-interop";
import { startWith } from "rxjs";
import { InputComponent } from "@/atoms/input/input.component";
import { CheckboxComponent } from "@/atoms/checkbox/checkbox.component";
import { NewReservationInputRefRegister } from "@/classes/new-reservation-input-ref-register";
import { UserService } from "@/services/user.service";

@Component({
  selector: "cup-applicant-step",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    ItRadioButtonComponent,
    GridColDirective,
    InputComponent,
    CheckboxComponent,
  ],
  styles: `
    @use "cup";

    .inputs-wrapper {
      margin-top: cup.toRem(56);
      margin-bottom: cup.toRem(32);

      @include cup.media-breakpoint-down(lg) {
        gap: cup.toRem(59);
      }
    }
  `,
  template: `
    <h2 class="h3" i18n>Per chi devi prenotare?</h2>

    <div class="row mt-4 mt-lg-5">
      <div cupGridCol [config]="{ xs: 12, lg: 4 }">
        @for (option of radioOptions; track option.value) {
          <it-radio-button
            #radio
            group="true"
            [formControl]="newReservationService.target"
            [validationMode]="false"
            [value]="option.value"
            [label]="option.label"
          />
        }
      </div>
    </div>

    @if (isOtherTarget) {
      <div class="inputs-wrapper row">
        @for (input of inputs; track input.name) {
          <div class="col-12 col-lg-4">
            <cup-input
              #input
              [inputId]="input.id"
              [name]="input.name"
              [formGroup]="newReservationService.reservation"
              [placeholder]="input.placeholder"
              [label]="input.label"
              [isRequired]="input.isRequired"
            />
          </div>
        }
      </div>

      <cup-checkbox
        #input
        inputId="applicantPrivacy"
        name="privacy"
        [isRequired]="true"
        [formGroup]="newReservationService.reservation"
        i18n-label
        label="I dati di contatto richiesti verranno utilizzati esclusivamente per la gestione di questa prenotazione."
      />

      <p i18n class="small mt-4 mb-5">
        I campi contrassegnati con * sono obbligatori.
      </p>
    }
  `,
  standalone: true,
})
export class ApplicantStepComponent extends NewReservationInputRefRegister {
  reservationTarget = toSignal(
    this.newReservationService.target.valueChanges.pipe(
      startWith(this.newReservationService.target.value)
    )
  );

  userService = inject(UserService);

  get isOtherTarget() {
    return this.reservationTarget() === "other";
  }

  readonly radioOptions: Option[] = [
    {
      label: $localize`${this.userService.userJwtFullName} (per me)`,
      value: "me",
    },
    {
      label: $localize`Per un'altra persona`,
      value: "other",
    },
  ];

  readonly inputs = [
    {
      id: "applicantFiscalCode",
      type: "text",
      name: "fiscalCode",
      label: $localize`Codice fiscale`,
      placeholder: "",
      isRequired: true,
    },
    {
      id: "applicantPhoneNumber",
      type: "tel",
      name: "phoneNumber",
      label: $localize`Numero di telefono`,
      placeholder: "",
      isRequired: true,
    },
    {
      id: "applicantEmail",
      type: "email",
      name: "email",
      label: $localize`Indirizzo email`,
      placeholder: "",
      isRequired: true,
    },
  ];
}
