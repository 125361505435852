import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FullLayoutComponent } from "@/atoms/full-layout.component";
import { NgOptimizedImage } from "@angular/common";

@Component({
  selector: "cup-mobile-app-banner",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    @use "cup";

    .app-banner {
      display: flex;
      flex-direction: column;

      @include cup.media-breakpoint-up(lg) {
        flex-direction: row;
      }

      &__left {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: cup.toRem(24);
        background-color: cup.$color-background-primary-lighter;
        padding: cup.toRem(40) cup.toRem(16);

        @include cup.media-breakpoint-up(lg) {
          padding: cup.toRem(112) cup.toRem(48);
        }
      }

      &__right {
        position: relative;
        background-color: #105738;
        flex-basis: cup.toRem(375);

        img {
          object-fit: contain;
        }

        @include cup.media-breakpoint-up(lg) {
          flex: 1;
          flex-basis: auto;
        }
      }
    }
  `,
  template: `
    <cup-full-layout>
      <section class="app-banner">
        <div class="app-banner__left">
          <h2 class="h1" i18n>
            UmbriaFacile, l'app che offre l’accesso diretto ai servizi della
            Regione.
          </h2>

          <p i18n>
            Puoi trovare tutti i servizi sanitari come il medico di base, i
            referti, le prenotazioni ed esenzioni ticket, allerta meteo, bandi e
            certificati; accesso alle offerte di lavoro e gestione delle
            candidature, servizi di assistenza per disabili e categorie
            protette.
          </p>

          <div class="d-flex gap-4 flex-wrap">
            <a href="https://apps.apple.com/it/app/umbriafacile/id6670786848">
              <img
                width="144"
                height="48"
                ngSrc="/assets/umbria-facile-app-store.svg"
                alt="Scarica l'app su App Store"
                i18n-alt
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=it.regione.umbria.appunica&pcampaignid=web_share"
            >
              <img
                width="163"
                height="48"
                ngSrc="/assets/umbria-facile-play-store.svg"
                alt="Scarica l'app su Play Store"
                i18n-alt
              />
            </a>
          </div>
        </div>
        <div class="app-banner__right">
          <img
            fill
            alt=""
            i18n-alt
            ngSrc="/assets/umbria-facile-thumb.png"
            priority
          />
        </div>
      </section>
    </cup-full-layout>
  `,
  imports: [FullLayoutComponent, NgOptimizedImage],
  standalone: true,
})
export class MobileAppBannerComponent {}
