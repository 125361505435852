import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnInit,
  signal,
} from "@angular/core";
import { ActionButtonComponent } from "@/atoms/action-button/action-button.component";
import { DatePipe, TitleCasePipe } from "@angular/common";
import { SafeResourceUrl } from "@angular/platform-browser";
import { RadioChipComponent } from "@/atoms/radio-chip/radio-chip.component";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { merge, of, takeUntil } from "rxjs";
import { Destroyable } from "@/classes/destroyable";

@Component({
  selector: "cup-appointment-card",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ActionButtonComponent,
    DatePipe,
    RadioChipComponent,
    ReactiveFormsModule,
    TitleCasePipe,
  ],
  styles: `
    @use "cup";

    .cup-appointment-card {
      padding: cup.toRem(24) cup.toRem(19);
    }
  `,
  template: `
    <div class="cup-reservation-card cup-appointment-card gap-4">
      <div class="d-flex flex-column gap-1">
        <h3 class="h5">{{ cardTitle() | titlecase }}</h3>
        <p class="text-body">{{ location() | titlecase }}</p>
        <p class="text-body">{{ address() | titlecase }}</p>

        <cup-action-button
          [action]="{
            type: 'view-detail',
            label: directionsBtnLabel,
            ariaLabel: directionsBtnAriaLabel,
            icon: 'external-link',
            isIconOnRight: true,
          }"
          [isExternalHref]="true"
          [href]="mapHref()"
        />
      </div>

      <p [id]="hoursRoleGroupId" class="visually-hidden" i18n>
        Orari disponibili per la prenotazione nella struttura
        <em>{{ cardTitle() }}</em>
      </p>

      <div class="d-flex flex-column gap-3">
        <p class="h6" i18n>
          Orari disponibili il {{ date() | date: "dd MMMM yyyy" }}
        </p>

        <div
          class="d-flex gap-2 flex-wrap"
          role="radiogroup"
          [attr.aria-labelledby]="hoursRoleGroupId"
          [formGroup]="formGroup()"
        >
          @for (hour of availableHours(); track hour.value) {
            <cup-radio-chip
              [label]="hour.label"
              [value]="hour.value"
              [currentValue]="currentValue()"
              [formControlName]="controlName()"
            />
          }
        </div>
      </div>
    </div>
  `,
})
export class AppointmentCardComponent extends Destroyable implements OnInit {
  cardTitle = input.required<string>();
  location = input.required<string>();
  address = input.required<string>();
  mapHref = input.required<SafeResourceUrl>();
  availableHours = input.required<{ label: string; value: string }[]>();
  date = input.required<string>();
  formGroup = input.required<FormGroup>();
  controlName = input.required<string>();
  currentValue = signal<string>("");

  get hoursRoleGroupId() {
    return `${this.cardTitle().toLowerCase().replaceAll(" ", "-")}-appointment-hours`;
  }

  get control() {
    return this.formGroup().get(this.controlName()) as FormControl;
  }

  ngOnInit() {
    merge(this.control.valueChanges, of(this.control.value))
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.currentValue.set(value));
  }

  readonly directionsBtnLabel = $localize`Indicazioni`;

  get directionsBtnAriaLabel() {
    return $localize`Indicazioni stradali per ${this.cardTitle()}`;
  }
}
