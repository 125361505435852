import { relativeRoutesPaths, routeLinks } from "@/classes/route-utils";
import { SearchRecipePageComponent } from "@/app/pages/search-recipe-page/search-recipe-page.component";
import { Route } from "@angular/router";

const searchRecipeRoute: Route = {
  path: relativeRoutesPaths.newReservationUploadRecipe,
  component: SearchRecipePageComponent,
  data: {
    breadcrumbItems: [
      routeLinks.home,
      routeLinks.myReservations,
      routeLinks.newReservation,
      routeLinks.newReservationUploadRecipe,
    ],
  },
};

export default searchRecipeRoute;
