import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { ItButtonDirective } from "design-angular-kit";
import { ContainerRowComponent } from "@/atoms/container-row.component";
import { GridColDirective } from "@/atoms/grid-col.component";
import { RouterLink } from "@angular/router";
import { absoluteRoutesPaths } from "@/classes/route-utils";
import { UserService } from "@/services/user.service";
import { OAuthService } from "angular-oauth2-oidc";

@Component({
  selector: "cup-homepage-intro",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ItButtonDirective,
    ContainerRowComponent,
    GridColDirective,
    RouterLink,
  ],
  styles: `
    @use "cup";

    .intro__list {
      list-style-type: square;
      margin-left: cup.toRem(24);
    }

    .intro__left {
      display: flex;
      flex-direction: column;
      gap: cup.toRem(32);

      padding-bottom: cup.toRem(32);
      border-bottom: 1px solid cup.$color-border-subtle;

      @include cup.media-breakpoint-up(lg) {
        padding-right: cup.toRem(40);
        align-items: flex-start;
        border-right: 1px solid cup.$color-border-subtle;
        border-bottom: none;
      }
    }

    .intro__right {
      display: flex;
      flex-direction: column;
      gap: cup.toRem(24);
      padding-top: cup.toRem(32);

      @include cup.media-breakpoint-up(lg) {
        padding-left: cup.toRem(40);
        padding-top: 0;
        align-items: flex-start;
      }
    }

    .intro__advice {
      display: flex;
      flex-direction: column;
      gap: cup.toRem(32);

      padding-bottom: cup.toRem(32);

      @include cup.media-breakpoint-up(lg) {
        padding-right: cup.toRem(40);
        align-items: flex-start;
        border-bottom: none;
      }
    }
  `,
  template: `
    <section id="content-start" tabindex="0" cupContainerRow>
      <div cupGridCol class="intro__advice" [config]="{ lg: 12, xs: 12 }">
        <h1 class="h1" i18n>Avviso agli utenti</h1>
        <p i18n>
          Si informa che il giorno mercoledì 2 aprile 2025 dalle ore 21:30 alle
          ore 23:30 sarà effettuato un intervento di manutenzione straordinaria
          sul sistema PagoUmbria. Nella fascia oraria indicata i servizi di
          pagamento e stampa avviso di pagamento risulteranno non
          disponibili.<br />
          Ci scusiamo per il disagio.
        </p>
      </div>
      <div cupGridCol class="intro__left" [config]="{ lg: 8, xs: 12 }">
        <h1 class="h1" i18n>
          Accedi al portale
          <abbr title="Centro Unico di Prenotazione" i18n-title>CUP</abbr> per
          prenotare e gestire le tue visite ed esami specialistici.
        </h1>
        <p i18n>
          Per prenotare e gestire le tue prenotazioni puoi utilizzare il
          servizio accedendo al portale con
          <abbr title="Sistema Pubblico di identità Digitale" i18n-title
            >SPID</abbr
          >
          o <abbr title="Carta di Identità Elettronica">CIE</abbr>.
        </p>
        <p i18n>
          Dopo aver eseguito l'accesso potrai effettuare le seguenti operazioni:
        </p>
        <ul class="intro__list">
          <li i18n>Prenotare visite ed esami</li>
          <li i18n>Pagare una prenotazione</li>
          <li i18n>Annullare una prenotazione</li>
          <li i18n>
            Stampare una prenotazione e gli eventuali allegati della
            preparazione alla visita.
            <em
              >L'SMS con il PIN per accedere alla documentazione allegata non
              verrà più inviato. L'accesso alla documentazione sarà possibile
              solo con autenticazione tramite portale</em
            >
          </li>
          <li i18n>Stampare la ricevuta di pagamento</li>
          <li i18n>Stampare l’avviso di pagamento</li>
        </ul>

        @if (!user.isLogged()) {
          <button
            (click)="oauthService.initLoginFlow()"
            itButton="primary"
            i18n
          >
            Accedi al portale
            <abbr title="Centro Unico di Prenotazione">CUP</abbr>
          </button>
        } @else {
          <a
            [routerLink]="absoluteRoutesPaths.userReservations"
            itButton="primary"
            i18n
            >Vai alle tue prenotazioni</a
          >
        }
      </div>
      <div cupGridCol class="intro__right" [config]="{ lg: 4, xs: 12 }">
        <h2 class="h3" i18n>
          Devi gestire la prenotazione di un'altra persona?
        </h2>
        <p i18n>
          Per gestire la prenotazione puoi utilizzare il servizio accedendo al
          portale con
          <abbr title="Sistema Pubblico di identità Digitale" i18n-title
            >SPID</abbr
          >
          o <abbr title="Carta di Identità Elettronica">CIE</abbr>
          e devi avere a disposizione il codice fiscale ed il numero
          prenotazione.
        </p>
        <p i18n>Puoi fare le seguenti operazioni:</p>
        <ul class="intro__list">
          <li i18n>Pagare una prenotazione</li>
          <li i18n>Annullare una prenotazione</li>
          <li i18n>
            Stampare una prenotazione e gli eventuali allegati della
            preparazione alla visita.
            <em
              >L'SMS con il PIN per accedere alla documentazione allegata non
              verrà più inviato. L'accesso alla documentazione sarà possibile
              solo con autenticazione tramite portale</em
            >
          </li>
          <li i18n>Stampare la ricevuta di pagamento</li>
          <li i18n>Stampare l’avviso di pagamento</li>
        </ul>

        @if (!user.isLogged()) {
          <button
            (click)="oauthService.initLoginFlow()"
            itButton="outline-primary"
            i18n
          >
            Accedi al portale
            <abbr title="Centro Unico di Prenotazione">CUP</abbr>
          </button>
        } @else {
          <a
            [routerLink]="absoluteRoutesPaths.othersReservations"
            itButton="outline-primary"
            i18n
            >Cerca una prenotazione</a
          >
        }
      </div>
    </section>
  `,
  standalone: true,
})
export class HomepageIntroComponent {
  user = inject(UserService);
  oauthService = inject(OAuthService);

  protected readonly absoluteRoutesPaths = absoluteRoutesPaths;
}
