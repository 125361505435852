import { Route } from "@angular/router";
import { relativeRoutesPaths, routeLinks } from "@/classes/route-utils";
import { OthersReservationsPageComponent } from "@/app/pages/others-reservations-page/others-reservations-page.component";

const otherReservationsRoute: Route = {
  path: relativeRoutesPaths.othersReservations,
  component: OthersReservationsPageComponent,
  data: {
    breadcrumbItems: [routeLinks.home, routeLinks.othersReservations],
  },
};

export default otherReservationsRoute;
