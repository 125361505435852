import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  ViewEncapsulation,
} from "@angular/core";
import { ItAlertComponent } from "design-angular-kit";
import { HeroComponent } from "@/molecules/hero.component";
import { Action } from "@/types/action.interface";
import { absoluteRoutesPaths } from "@/classes/route-utils";
import { TableComponent } from "@/molecules/table.component";
import { FullLayoutComponent } from "@/atoms/full-layout.component";
import { InputSearchComponent } from "@/molecules/input-search.component";
import { BreadcrumbComponent } from "@/molecules/breadcrumb/breadcrumb.component";
import { HttpClient } from "@angular/common/http";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { map, takeUntil } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { MockService } from "@/services/mock.service";
import { ReservationsResponseBody } from "@/types/api";
import { FormsModule } from "@angular/forms";
import {
  MapReservationDataPipe,
  ReservationDataMappingOptions,
} from "@/pipes/map-reservation-data.pipe";
import { Destroyable } from "@/classes/destroyable";
import { DeriveReservationPaymentStatusPipe } from "@/pipes/derive-reservation-payment-status.pipe";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DeriveReservationPaymentStatusPipe],
  imports: [
    HeroComponent,
    TableComponent,
    FullLayoutComponent,
    InputSearchComponent,
    ItAlertComponent,
    BreadcrumbComponent,
    FormsModule,
    MapReservationDataPipe,
  ],
  styles: `
    @use "cup";

    cup-table div.table-wrapper {
      @include cup.media-breakpoint-up(lg) {
        padding-bottom: cup.toRem(48);
      }
    }

    .alert {
      display: block;
      margin-bottom: cup.toRem(128);

      @include cup.media-breakpoint-up(lg) {
        margin-bottom: cup.toRem(144);
      }
    }
  `,
  template: `
    @let tableData = data();
    @let error = mockService.error();

    <cup-breadcrumb />

    <cup-hero
      [colConfig]="{ xs: 12, lg: 9 }"
      i18n-heroCopy
      i18n-heroTitle
      heroTitle="Le mie prenotazioni"
      heroCopy="Prenota esami e visite specialistiche in convenzione oppure prenota visite private in strutture pubbliche (Intramoenia). Inoltre puoi pagare e annullare le prenotazioni, stampare i documenti correlati come la prenotazione, il documento fiscale e l’avviso di pagamento."
      [actions]="actions"
    />

    @if (tableData) {
      <cup-full-layout>
        <cup-input-search
          [(ngModel)]="searchKey"
          (reset)="submittedSearchKey.set('')"
          (search)="handleSearch()"
        />

        <h2 class="h3" i18n>Risultati ricerca</h2>

        @if (submittedSearchKey()) {
          <p class="mt-2" i18n>
            Risultati per testo <strong>"{{ submittedSearchKey() }}"</strong>
          </p>
        }
      </cup-full-layout>

      <div class="mt-4">
        <cup-table
          [currentPage]="currentPage()"
          (pageChange)="currentPage.set($event)"
          [data]="dataConfig() | mapReservationData"
        />
      </div>
    } @else if (error) {
      <cup-full-layout>
        <it-alert color="warning">
          <ng-container heading>{{ error.message }}</ng-container>
          <p class="mb-0 mt-3">{{ error.description }}</p>
        </it-alert>
      </cup-full-layout>
    } @else {
      <cup-full-layout>
        <it-alert i18n>Non sono presenti prenotazioni</it-alert>
      </cup-full-layout>
    }
  `,
  standalone: true,
})
export class UserReservationsPageComponent extends Destroyable {
  http = inject(HttpClient);
  route = inject(ActivatedRoute);
  mockService = inject(MockService);

  searchKey = signal("");
  currentPage = signal(0);
  submittedSearchKey = signal("");

  data = toSignal<ReservationsResponseBody>(
    this.route.data.pipe(map((routeData) => routeData["reservations"]))
  );

  resetEffect = effect(() => {
    if (!this.searchKey()) {
      this.submittedSearchKey.set("");
    }
  });

  dataConfig = computed<ReservationDataMappingOptions>(() => ({
    data: this.data(),
    type: "user",
    sort: {
      direction: "DESC",
      field: "dataOraAppuntamento",
      type: "date",
    },
    additionalFilters: [
      {
        type: "full-text",
        value: this.submittedSearchKey(),
      },
    ],
  }));

  get actions(): Action[] {
    return [
      {
        type: "new-reservation",
        label: $localize`Nuova prenotazione`,
        relevance: "primary-task",
        icon: "plus",
        href: absoluteRoutesPaths.newReservation,
      },
      {
        type: "reservations-history",
        label: $localize`Storico prenotazioni`,
        relevance: "secondary-task",
        href: absoluteRoutesPaths.reservationHistory,
      },
    ];
  }

  constructor() {
    super();
    this.resetPageOnSearchKeyChange();
  }

  resetPageOnSearchKeyChange() {
    toObservable(this.submittedSearchKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.currentPage.set(0));
  }

  handleSearch() {
    this.submittedSearchKey.set(this.searchKey());
  }
}
