import { Directive, input, OnInit, signal } from "@angular/core";
import { FormUtils } from "@/classes/form-utils";
import { toObservable } from "@angular/core/rxjs-interop";
import { skip, takeUntil } from "rxjs";

@Directive()
export class CheckboxBase extends FormUtils implements OnInit {
  isChecked = signal(false);
  value = input("");
  checkedChange$ = toObservable(this.isChecked);

  override ngOnInit() {
    super.ngOnInit();

    this.initCheckedState();
    this.updateCheckedState();
  }

  updateCheckedState() {
    this.checkedChange$
      .pipe(skip(1), takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.value()) {
          return this.control.setValue(this.isChecked());
        }

        const controlValue = this.control.value;

        if (controlValue instanceof Array) {
          if (this.isChecked()) {
            return this.control.setValue([...controlValue, this.value()]);
          }

          return this.control.setValue(
            controlValue.filter((v) => v !== this.value())
          );
        }

        if (this.isChecked()) {
          return this.control.setValue(this.value());
        }

        this.control.reset();
      });
  }

  initCheckedState() {
    const controlValue = this.control.value;

    if (this.value()) {
      if (controlValue instanceof Array) {
        return this.isChecked.set(controlValue.includes(this.value()));
      }

      return this.isChecked.set(controlValue === this.value());
    }

    this.isChecked.set(!!controlValue);
  }

  handleChange() {
    this.isChecked.update((isChecked) => !isChecked);
  }
}
