import { Injectable, signal } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  responseError = signal<{ title: string; detail: string } | null>(null);

  get userNotLoggedError() {
    return {
      title: $localize`Utente non loggato`,
      detail: $localize`Impossibile procedere con l'operazione, id sessione non trovato.`,
    };
  }
}
