import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  computed,
  inject,
  model,
  output,
  Type,
  viewChild,
  ViewContainerRef,
} from "@angular/core";
import { StepItemModal, Steps } from "@/types/step-item.interface";
import { ItButtonDirective, ItIconComponent } from "design-angular-kit";
import {
  AsyncPipe,
  DecimalPipe,
  NgComponentOutlet,
  NgTemplateOutlet,
} from "@angular/common";
import { Router } from "@angular/router";
import { toObservable } from "@angular/core/rxjs-interop";
import { filter, switchMap, takeUntil } from "rxjs";
import { Destroyable } from "@/classes/destroyable";
import { type ModalBase } from "@/classes/modal-base.class";
import { StepperService } from "@/services/stepper.service";
import { ButtonComponent } from "@/atoms/button/button.component";
import { SessionStorageService } from "@/services/session-storage.service";

@Component({
  selector: "cup-stepper",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ItButtonDirective,
    ItIconComponent,
    DecimalPipe,
    NgComponentOutlet,
    NgTemplateOutlet,
    ButtonComponent,
    AsyncPipe,
  ],
  exportAs: "stepper",
  styles: `
    @use "cup";

    .btn {
      padding: cup.toRem(16) cup.toRem(52);
    }

    button[itButton] {
      gap: cup.toRem(6);
    }

    button.steppers-btn-next.next-button {
      display: flex;
      align-items: center;
      gap: cup.toRem(10);
    }
  `,
  template: `
    @let currentActiveStep = activeStep();

    <ng-template #modal></ng-template>
    <ng-template #additionalModal></ng-template>

    <div class="steppers">
      <div class="steppers-header">
        <ol class="steppers-steps-list">
          @for (step of steps(); track step.id) {
            <li
              class="d-lg-flex justify-content-between align-items-center"
              [class.d-none]="step.id !== currentActiveStep?.id"
              [class.confirmed]="step.isDone"
              [class.active]="step.id === currentActiveStep?.id"
              [attr.aria-current]="
                step.id === currentActiveStep?.id ? 'step' : null
              "
            >
              <span>
                <span class="d-none d-lg-inline"
                  >{{ $index + 1 | number: "2.0-0" }}&period;</span
                >
                {{ step.title }}
              </span>

              <it-icon
                [class.invisible]="!step.isDone"
                name="check-circle"
                color="primary"
              />

              @if (step.isDone) {
                <span class="visually-hidden" i18n>Confermato</span>
              }
            </li>
          }
        </ol>

        <span class="steppers-index text-secondary" aria-hidden="true"
          >{{ stepIndex() + 1 }}/{{ steps().length }}</span
        >
      </div>

      <div class="steppers-content" aria-live="polite">
        @let component = currentActiveStep?.component;
        @let stepTemplate = currentActiveStep?.template;

        @if (component) {
          <ng-container
            *ngComponentOutlet="
              component;
              inputs: currentActiveStep?.inputs?.()
            "
          />
        } @else if (stepTemplate) {
          <ng-container
            *ngTemplateOutlet="
              stepTemplate;
              context: currentActiveStep?.context
            "
          />
        } @else {
          <ng-content></ng-content>
        }
      </div>

      <nav
        class="custom-steppers-nav justify-content-end"
        [class.justify-content-between]="!currentActiveStep?.isFirstStep"
      >
        @if (!currentActiveStep?.isFirstStep) {
          <div class="d-flex gap-3">
            <button
              itButton="outline-primary"
              type="button"
              class="steppers-btn-prev"
              i18n
              (click)="handlePrevStep()"
              [disabled]="!!loadingLabel"
            >
              <it-icon name="arrow-left" color="primary" />

              Indietro
            </button>

            <ng-container
              *ngTemplateOutlet="resetButton; context: { $implicit: true }"
            ></ng-container>
          </div>
        }

        @let loadingLabel = stepperService.loadingLabel();
        @let isStepDisabled = currentActiveStep?.disableStep$?.() | async;

        <div class="d-flex gap-3">
          @if (stepIndex() > 0) {
            <ng-container
              *ngTemplateOutlet="resetButton; context: { $implicit: false }"
            ></ng-container>
          }

          @if (currentActiveStep?.nextStep) {
            <button
              [class.ms-auto]="currentActiveStep?.isFirstStep"
              itButton="primary"
              type="button"
              i18n
              icon="arrow-right"
              iconPosition="right"
              class="next-button steppers-btn-next"
              [loadingLabel]="loadingLabel"
              [disabled]="!!loadingLabel || !!isStepDisabled"
              (click)="handleNextStep()"
            >
              Avanti
            </button>
          } @else {
            @let disableStepObservable =
              currentActiveStep?.disableStep$?.() | async;

            <button
              [class.ms-auto]="currentActiveStep?.isFirstStep"
              itButton="primary"
              type="button"
              class="next-button steppers-btn-confirm"
              i18n
              [loadingLabel]="loadingLabel"
              [disabled]="!!loadingLabel || !!disableStepObservable"
              (click)="handleNextStep()"
            >
              Conferma
            </button>
          }
        </div>
      </nav>
    </div>

    <ng-template #resetButton let-showOnMobile>
      <button
        [class.d-lg-none]="showOnMobile"
        [class.d-none]="!showOnMobile"
        [class.d-lg-block]="!showOnMobile"
        itButton="outline-secondary"
        iconColor="secondary"
        icon="refresh"
        type="button"
        [disabled]="!!loadingLabel"
        (click)="handleReset()"
      >
        Reset
      </button>
    </ng-template>
  `,
})
export class StepperComponent extends Destroyable {
  stepperService = inject(StepperService);
  sessionStorageService = inject(SessionStorageService);

  steps = model<Steps<string>>([]);

  modalTemplate = viewChild("modal", {
    read: ViewContainerRef,
  });

  additionalModalTemplate = viewChild("additionalModal", {
    read: ViewContainerRef,
  });

  submitData = output<void>();
  resetData = output<void>();

  currentStepId = model.required<string>();
  router = inject(Router);

  activeStep = computed(
    () => this.steps().find((step) => step.id === this.currentStepId()) || null
  );

  stepIndex = computed(() =>
    this.steps().findIndex((step) => this.activeStep()?.id === step.id)
  );

  nextStep = computed(() =>
    this.steps().find((step) => this.activeStep()?.nextStep === step.id)
  );

  prevStep = computed(() =>
    this.steps().find((step) => this.activeStep()?.prevStep === step.id)
  );

  constructor() {
    super();

    toObservable(this.activeStep)
      .pipe(
        filter(Boolean),
        switchMap((step) =>
          this.router.navigate([], {
            queryParams: {
              step: step.id,
            },
            queryParamsHandling: "merge",
            replaceUrl: true,
          })
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  handlePrevStep() {
    const step = this.prevStep();
    const activeStep = this.activeStep();

    if (!step || !activeStep) return;

    this.steps.update((steps) =>
      steps.map((step) => ({
        ...step,
        ...(activeStep.prevStep === step.id
          ? { isDone: false }
          : { isDone: step.isDone }),
      }))
    );

    this.currentStepId.set(step.id);
  }

  handleReset() {
    this.sessionStorageService.clearReservationStorage();

    this.steps.update((steps) =>
      steps.map((s) => ({
        ...s,
        isDone: false,
      }))
    );

    this.currentStepId.set(this.steps()[0].id);
    this.resetData.emit();
  }

  async handleNextStep() {
    const nextStep = this.nextStep();
    const currentStep = this.activeStep();

    if (!nextStep) {
      return this.submitData.emit();
    }

    const isStepValid = currentStep?.validateStep
      ? await currentStep.validateStep()
      : true;

    if (!currentStep || !isStepValid) {
      return;
    }

    if (currentStep?.showModal?.()) {
      const { isCanceled } = await this.handleStepModal();

      if (isCanceled) return;
    }

    this.steps.update((steps) =>
      steps.map((step) => ({
        ...step,
        ...(step.id === currentStep.id
          ? { isDone: true }
          : { isDone: step.isDone }),
      }))
    );

    this.currentStepId.update(() => nextStep.id);
  }

  async handleStepModal(): Promise<{ isCanceled: boolean }> {
    const currentStep = this.activeStep();
    const modalTemplateContainer = this.modalTemplate();
    const additionalModalTemplateContainer = this.additionalModalTemplate();

    if (!modalTemplateContainer || !currentStep?.modal) {
      return { isCanceled: false };
    }

    const { modal, additionalModal } = currentStep;

    const modalResponse = await this.handleModal(modal, modalTemplateContainer);

    if (modalResponse.status === "canceled") {
      return { isCanceled: true };
    }

    const { componentRef } = modalResponse;

    if (!additionalModalTemplateContainer || !additionalModal) {
      await this.handleModalSubmit(modal, componentRef);
      componentRef.instance.closeModal();
      return { isCanceled: false };
    }

    componentRef.instance.closeModal();

    return await new Promise<{ isCanceled: boolean }>((resolve) => {
      setTimeout(async () => {
        const modalResponse = await this.handleModal(
          additionalModal,
          additionalModalTemplateContainer
        );

        if (modalResponse.status === "canceled") {
          return resolve({ isCanceled: true });
        }

        const { componentRef } = modalResponse;

        await this.handleModalSubmit(additionalModal, componentRef);
        componentRef.instance.closeModal();
        resolve({ isCanceled: false });
      }, 600);
    });
  }

  async handleModalSubmit(
    modal: StepItemModal,
    componentRef: ComponentRef<ModalBase>
  ) {
    if (!modal.onModalSubmit) return;
    await modal.onModalSubmit?.(componentRef.instance.payload());
  }

  async handleModal(
    modal: StepItemModal,
    container: ViewContainerRef
  ): Promise<
    | { status: "canceled" }
    | { status: "success"; componentRef: ComponentRef<ModalBase> }
  > {
    if (
      !modal.component ||
      (modal.shouldOpenModal && !modal.shouldOpenModal())
    ) {
      return { status: "canceled" };
    }

    return await this.handleModalComponent(modal.component, container);
  }

  async handleModalComponent(
    modal: Type<unknown>,
    viewContainer: ViewContainerRef
  ): Promise<
    | {
        status: "canceled";
      }
    | {
        status: "success";
        componentRef: ComponentRef<ModalBase>;
      }
  > {
    viewContainer.clear();

    const componentRef = viewContainer.createComponent(
      modal
    ) as ComponentRef<ModalBase>;

    componentRef.changeDetectorRef.detectChanges();
    componentRef.instance.openModal();

    const action = await componentRef.instance.userAction;

    if (action === "canceled") return { status: "canceled" };

    return { status: "success", componentRef };
  }

  protected readonly sessionStorage = sessionStorage;
}
