import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MobileAppBannerComponent } from "@/organisms/mobile-app-banner/mobile-app-banner.component";
import { HomepageIntroComponent } from "@/organisms/homepage-intro/homepage-intro.component";

@Component({
  selector: "cup-homepage",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HomepageIntroComponent, MobileAppBannerComponent],
  styles: `
    @use "cup";
    @use "mixins";

    $space: 32;

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: cup.toRem($space);
      margin-top: cup.toRem(24);
      margin-bottom: cup.toRem($space);

      @include mixins.media-breakpoint-up(lg) {
        $space: 48;
        gap: cup.toRem($space);
        margin-top: cup.toRem(48);
        margin-bottom: cup.toRem($space);
      }
    }
  `,
  template: `
    <div class="wrapper">
      <cup-homepage-intro />
      <cup-mobile-app-banner />
    </div>
  `,
  standalone: true,
})
export class HomePageComponent {}
