import { inject, Injectable } from "@angular/core";
import { MockService } from "@/services/mock.service";
// import { environment } from "@/environments/environment";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { UserService } from "@/services/user.service";
import {
  Agenda,
  Calendario,
  ReservationDetailResponseBody,
  ReservationsHistoryResponseBody,
  ReservationsResponseBody,
  SearchReservationAvailabilityResponse,
  StartReservationResponse,
} from "@/types/api";
import { NgHttpCachingHeaders } from "ng-http-caching";
import downloadBlob from "@/utils/functions/download-blob";
import { lastValueFrom, map, takeUntil } from "rxjs";
import { $localize } from "@angular/localize/init";
import { YESTERDAY } from "@/utils/constants";
import cancelRequest$ from "@/subjects/cancel-request";

@Injectable({
  providedIn: "root",
})
export class ReservationService {
  mockService = inject(MockService);
  userService = inject(UserService);
  http = inject(HttpClient);
  router = inject(Router);

  readonly reservationAttachmentLabel = $localize`Prenotazione e allegato preparazione`;

  downloadPaymentReceipt(reservationNumber: number, cf?: string) {
    return this.http
      .post(
        `cupservice/pagamento/ricevuta`,
        {
          cf: cf || this.userService.userJwtFiscalCode,
          idSessione: this.userService.sessionId(),
          codPrenotazione: reservationNumber,
        },
        {
          headers: {
            [NgHttpCachingHeaders.DISALLOW_CACHE]: "1",
          },
          responseType: "blob",
        }
      )
      .pipe(takeUntil(cancelRequest$));
  }

  payReservation(reservationNumber: number, cf?: string) {
    return this.http
      .post(
        `cupservice/pagamento`,
        {
          cf: cf || this.userService.userJwtFiscalCode,
          idSessione: this.userService.sessionId(),
          codPrenotazione: reservationNumber,
        },
        {
          headers: {
            [NgHttpCachingHeaders.DISALLOW_CACHE]: "1",
          },
          responseType: "text",
        }
      )
      .pipe(takeUntil(cancelRequest$));
  }

  downloadPaymentNotice(reservationNumber: number, cf?: string) {
    return this.http
      .post(
        `cupservice/pagamento/avviso/${reservationNumber}`,
        {
          cf: cf || this.userService.userJwtFiscalCode,
          codPrenotazione: reservationNumber,
        },
        {
          headers: {
            [NgHttpCachingHeaders.DISALLOW_CACHE]: "1",
          },
          responseType: "blob",
        }
      )
      .pipe(takeUntil(cancelRequest$));
  }

  downloadReservationPdfFile(reservationNumber: number) {
    return this.http
      .get(`cupservice/prenotazione/download/${reservationNumber}`, {
        responseType: "blob",
        headers: {
          [NgHttpCachingHeaders.DISALLOW_CACHE]: "1",
        },
      })
      .pipe(takeUntil(cancelRequest$));
  }

  cancelReservation(reservationNumber: number, cf?: string) {
    return this.http
      .post(
        "cupservice/prenotazione/cancella",
        {
          cf: cf || this.userService.userJwtFiscalCode,
          codPrenotazione: reservationNumber,
          idSessione: this.userService.sessionId(),
        },
        {
          headers: {
            [NgHttpCachingHeaders.DISALLOW_CACHE]: "1",
          },
        }
      )
      .pipe(takeUntil(cancelRequest$));
  }

  getReservationDetail(payload: { codPrenotazione: string; cf: string }) {
    return this.http
      .post<ReservationDetailResponseBody>(
        "cupservice/prenotazione/dettaglio",
        payload
      )
      .pipe(takeUntil(cancelRequest$));
  }

  getReservationHistory(params?: { from?: string; to?: string }) {
    const sessionId = this.userService.sessionId();

    return this.http
      .post<ReservationsHistoryResponseBody>(
        "cupservice/prenotazione/storico",
        {
          idSessione: sessionId,
          ...(params
            ? { ...params }
            : {
                from: `2000-01-01T00:00:00`, //`${new Date().getFullYear()}-01-01T00:00:00`,
                to: YESTERDAY,
              }),
        }
      )
      .pipe(
        map((response) => ({
          ...response,
          appuntamenti: response.appuntamenti.filter((a) => !a.cancellato),
        })),
        takeUntil(cancelRequest$)
      );
  }

  searchReservationAvailability(data: {
    codAmbito: string;
    cfAssistito: string;
    dataInizio: string;
    nreList: string[];
  }) {
    return this.http
      .post<SearchReservationAvailabilityResponse>(
        "cupservice/prenotazione/ricerca-disponibilita",
        {
          ...data,
          idSessione: this.userService.sessionId(),
        },
        {
          headers: {
            [NgHttpCachingHeaders.TAG]: "reservationAvailability",
          },
        }
      )
      .pipe(takeUntil(cancelRequest$));
  }

  confirmReservation(payload: Omit<ConfirmReservationPayload, "idSessione">) {
    return this.http
      .post<number>(
        "cupservice/prenotazione/conferma",
        {
          ...payload,
          idSessione: this.userService.sessionId(),
        },
        {
          headers: {
            [NgHttpCachingHeaders.DISALLOW_CACHE]: "1",
          },
        }
      )
      .pipe(takeUntil(cancelRequest$));
  }

  startReservation({
    fiscalCode,
    nreList,
  }: {
    fiscalCode: string;
    nreList: string[];
  }) {
    return this.http
      .post<StartReservationResponse>(
        "cupservice/prenotazione/avvia",
        {
          idSessione: this.userService.sessionId(),
          cf: fiscalCode,
          nreList,
        },
        {
          headers: {
            [NgHttpCachingHeaders.TAG]: "startReservation",
          },
        }
      )
      .pipe(takeUntil(cancelRequest$));
  }

  getAllReservations() {
    // if (environment.MOCK_APIS.getAllReservations) {
    //   return this.mockService.getAllReservations();
    // }

    const sessionId = this.userService.sessionId();

    return this.http
      .post<ReservationsResponseBody>(
        "cupservice/prenotazione/lista",
        {
          from: `${new Date().toISOString().split("T")[0]}T00:00:00`,
          idSessione: sessionId,
        },
        {
          headers: {
            [NgHttpCachingHeaders.TAG]: "user-reservation-list",
          },
        }
      )
      .pipe(
        map((response) => ({
          ...response,
          appuntamenti: {
            appuntamenti: response.appuntamenti.appuntamenti.filter(
              (a) => !a.cancellato
            ),
          },
          tutela: response.tutela.filter((t) => !t.cancellato),
        })),
        takeUntil(cancelRequest$)
      );
  }

  downloadReservationAttachment(reservationNumber?: number | string) {
    if (!reservationNumber) {
      console.error("Reservation number not found");
      return;
    }

    this.downloadReservationPdfFile(+reservationNumber).subscribe((file) =>
      downloadBlob($localize`prenotazione_` + reservationNumber, file)
    );
  }

  async payReservationOnline(reservationNumber?: number | string, cf?: string) {
    if (!reservationNumber) {
      console.error("Reservation number not found");
      return;
    }

    try {
      const url = await lastValueFrom(
        this.payReservation(+reservationNumber, cf)
      );

      window.open(url, "popup", "width=600, height=600");
    } catch (error) {
      console.error(error);
    }
  }
}

interface ConfirmReservationPayload {
  agenda: Agenda;
  idSessione: string;
  calendar: Calendario;
  cfAssistito: string;
  nreList: string[];
}
