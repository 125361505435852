import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit,
} from "@angular/core";
import { ContainerRowComponent } from "@/atoms/container-row.component";
import { FiltersChipsCarouselComponent } from "./filters-chips-carousel.component";
import { ItInputComponent } from "design-angular-kit";
import { GridColDirective } from "@/atoms/grid-col.component";
import { FormProviderService } from "@/services/form-provider.service";
import { ReactiveFormsModule } from "@angular/forms";
import { toSignal } from "@angular/core/rxjs-interop";
import { map, startWith, takeUntil } from "rxjs";
import { Destroyable } from "@/classes/destroyable";
import { YESTERDAY } from "@/utils/constants";

@Component({
  selector: "cup-reservation-filters",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ContainerRowComponent,
    FiltersChipsCarouselComponent,
    ItInputComponent,
    GridColDirective,
    ReactiveFormsModule,
  ],
  styles: `
    @use "cup";

    .chips-wrapper {
      margin-top: cup.toRem(24);
      display: flex;
      flex-direction: column;
      gap: cup.toRem(16);
    }
  `,
  template: `
    <div cupContainerRow [formGroup]="formProviderService.form">
      <!--
      <div cupGridCol [config]="{ xs: 6, lg: 2 }">
        <it-input
          class="remove-form-group-margin"
          formControlName="from"
          [validationMode]="false"
          [maxDate]="fromMaxDate()"
          type="date"
          i18n-label="Filtro per la data iniziale"
          label="Data di inizio"
        />
      </div>
      <div cupGridCol [config]="{ xs: 6, lg: 2 }">
        <it-input
          class="remove-form-group-margin"
          formControlName="to"
          [validationMode]="false"
          [maxDate]="maxDate"
          type="date"
          i18n-label="Filtro per la data finale"
          label="Data di fine"
        />
      </div>
      -->
      <div cupGridCol [config]="{ xs: 12 }" class="chips-wrapper">
        <fieldset>
          <legend id="service-type" class="p-0 caption mb-3" i18n>
            Tipologia di prestazione
          </legend>
          <cup-filters-chips-carousel
            labelId="service-type"
            [services]="services()"
          />
        </fieldset>
      </div>
    </div>
  `,
})
export class ReservationFiltersComponent extends Destroyable implements OnInit {
  formProviderService = inject(FormProviderService);
  services = input.required<string[]>();

  maxDate = YESTERDAY.split("T")[0];

  get toDateControl() {
    return this.formProviderService.form.controls["to"];
  }

  get fromDateControl() {
    return this.formProviderService.form.controls["from"];
  }

  fromMaxDate = toSignal(
    this.toDateControl.valueChanges.pipe(
      startWith(this.toDateControl.value),
      map((value) => (value ? value.split("T")[0] : this.maxDate))
    )
  );

  ngOnInit() {
    this.toDateControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (
          this.fromDateControl.value &&
          new Date(value).getTime() <
            new Date(this.fromDateControl.value).getTime()
        ) {
          this.fromDateControl.setValue("");
        }
      });
  }
}
