<cup-breadcrumb />

@let reservationDetailData = data() ;

@if (reservationDetailData) {
  @let status =
    (reservationDetailData.appuntamento | deriveReservationPaymentStatus)?.status ;

  <cup-hero
    [heroTitle]="
      reservationDetailData.appuntamento.unitaErogante.descrizioneDisciplina
        | titlecase
    "
    [heroCopy]="priority"
    [actions]="heroActions()"
    [sideActions]="heroSideActions()"
    [heroSubTitle]="subtitle"
  />

  <cup-full-layout [colConfig]="{ lg: 8, xs: 12 }">
    <div class="d-flex flex-column gap-5 mb-5">
      @if (status) {
        <cup-reservation-detail-section i18n-sectionTitle sectionTitle="Stato">
          <cup-payment-badge [paymentStatus]="status" />

          @if (status === "to-pay") {
            <p i18n>
              Il pagamento può essere fatto fino a 24 ore prima dell'appuntamento.
            </p>
          }
        </cup-reservation-detail-section>
      }

      <cup-reservation-detail-section
        i18n-sectionTitle
        sectionTitle="Richiedente"
      >
        <p>
          {{
            reservationDetailData.assistito.nome +
            " " +
            reservationDetailData.assistito.cognome | titlecase
          }}
        </p>
      </cup-reservation-detail-section>

      @if (status === "waiting-list") {
        <ng-container
          *ngTemplateOutlet="
            waitingList;
            context: {
              $implicit: reservationDetailData.appuntamento.dataOraAppuntamento,
            }
          "
        />
      } @else {
        <ng-container
          *ngTemplateOutlet="
            confirmed;
            context: { $implicit: reservationDetailData }
          "
        />
      }
    </div>
  </cup-full-layout>

  <it-modal #cancelReservationModal alignment="centered">
    @let term =
      paymentStatus() === "waiting-list" ? "richiesta" : "prenotazione" ;
    <ng-container modalTitle i18n
    >Confermi di voler annullare la {{ term }}?
    </ng-container
    >

    <p i18n>
      Per annullare la {{ term }} premi sul pulsante “Conferma annullamento”.
    </p>

    <ng-container footer>
      @let label = loadingMessage() ;

      <button
        itButton="outline-primary"
        type="button"
        class="justify-content-center"
        data-bs-dismiss="modal"
        i18n
      >
        Chiudi
      </button>
      <button
        itButton="primary"
        type="button"
        class="justify-content-center"
        relevance="primary-task"
        [loadingLabel]="label"
        [disabled]="!!label"
        (click)="handleCancelReservation()"
      >
        Conferma annullamento
      </button>
    </ng-container>
  </it-modal>
}

<ng-template #waitingList let-date>
  <cup-reservation-detail-section
    i18n-sectionTitle
    sectionTitle="Data di inserimento in lista d'attesa"
  >
    <p>{{ date | date: "dd MMMM yyyy" | titlecase }}</p>
  </cup-reservation-detail-section>

  <cup-reservation-detail-section i18n-sectionTitle sectionTitle="Quando">
    <p i18n>
      Entro 120 giorni dalla data di inserimento in lista (non oltre il
      {{ date | changeDate: 120 | date: "dd MMMM yyyy" | titlecase }}).
    </p>
  </cup-reservation-detail-section>
</ng-template>

<ng-template #confirmed let-reservation>
  <cup-reservation-detail-section i18n-sectionTitle sectionTitle="Data e ora">
    <p>
      {{ reservation.appuntamento.dataOraAppuntamento | date: "dd MMMM yyyy" }},
      <ng-container i18n="Orario">ore</ng-container>
      {{ reservation.appuntamento.dataOraAppuntamento | date: "H:mm" }}
    </p>
  </cup-reservation-detail-section>

  <cup-reservation-detail-section i18n-sectionTitle sectionTitle="Struttura">
    <iframe
      [style.width.%]="100"
      height="415"
      [src]="mapAddress | googleMap: 'embed'"
    ></iframe>

    <p>
      {{
        reservation.appuntamento.unitaErogante.descrizioneStrutturaErogante
          | titlecase
      }}<br />
      {{ reservation.appuntamento.unitaErogante.indirizzo | titlecase }} -
      {{ reservation.appuntamento.unitaErogante.cap }}
      {{ reservation.appuntamento.unitaErogante.descrizioneComune | titlecase }}
      ({{ reservation.appuntamento.unitaErogante.siglaProvincia }})<br />
      <ng-container i18n>Presso</ng-container>
      {{
        reservation.appuntamento.unitaErogante.descrizioneUnitaErogante
          | titlecase
      }}
    </p>

    <a
      class="btn link d-flex align-items-center gap-2"
      [href]="mapAddress | googleMap"
      target="_blank"
    >
      <ng-container i18n>Indicazioni</ng-container>
      <it-icon color="primary" name="external-link" />
    </a>
  </cup-reservation-detail-section>

  <cup-reservation-detail-section i18n-sectionTitle sectionTitle="Documenti">
    @for (reservationDoc of reservationDocuments(); track $index) {
      <button
        class="bg-transparent border-0"
        tabindex="0"
        (click)="reservationDoc.handleClick()"
      >
        <it-card teaser="true" rounded="true" shadow="true">
          <div class="h6 card-title document-card">
            <it-icon color="primary" name="clip" />
            {{ reservationDoc.label }}
          </div>
        </it-card>
      </button>
    }
  </cup-reservation-detail-section>
</ng-template>
