import { Pipe, PipeTransform } from "@angular/core";
import { Appuntamento } from "@/types/api";
import { PaymentStatus } from "@/atoms/payment-badge/payment-badge.component";

const CODICE_STRUTTURA_EROGANTE_IN_LISTA_ATTESA = 319920;
const CODICE_UNITA_EROGANTE_IN_LISTA_ATTESA = 319943;
const CODICE_UNITA_EROGANTE_2_IN_LISTA_ATTESA = 319939;

@Pipe({
  standalone: true,
  name: "deriveReservationPaymentStatus",
})
export class DeriveReservationPaymentStatusPipe implements PipeTransform {
  transform(reservation: Appuntamento): {
    label: string;
    status: PaymentStatus;
  } | null {
    const {
      unitaErogante: {
        codiceStrutturaErogante,
        codiceUnitaErogante,
        descrizioneStrutturaErogante,
      },
    } = reservation;

    if (
      codiceStrutturaErogante === CODICE_STRUTTURA_EROGANTE_IN_LISTA_ATTESA ||
      codiceUnitaErogante === CODICE_UNITA_EROGANTE_IN_LISTA_ATTESA ||
      codiceUnitaErogante === CODICE_UNITA_EROGANTE_2_IN_LISTA_ATTESA ||
      descrizioneStrutturaErogante.includes("TUTELA")
    ) {
      return { status: "waiting-list", label: $localize`Lista d'attesa` };
    }

    if (reservation.pagato) {
      return { status: "paid", label: $localize`Pagato` };
    }

    if (reservation.pagabile) {
      return { status: "to-pay", label: $localize`Da pagare` };
    }

    return null;
  }
}
