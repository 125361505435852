import { ChangeDetectionStrategy, Component, viewChild } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormErrorsComponent } from "@/atoms/form-errors/form-errors.component";
import { fadeInAnimation } from "@/animations/icon-fade-in";
import { CheckboxBase } from "@/classes/checkbox-base";
import { CheckboxInputComponent } from "@/atoms/checkbox-input/checkbox-input.component";

@Component({
  selector: "cup-checkbox",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, FormErrorsComponent, CheckboxInputComponent],
  animations: [fadeInAnimation],
  template: `
    @let labelText = label();

    <div class="checkbox-wrapper">
      <cup-checkbox-input
        [value]="value()"
        [formGroup]="formGroup()"
        [name]="name()"
        [inputId]="inputId()"
      />

      @if (labelText) {
        <label class="label-lg" [attr.for]="inputId()">
          {{ labelText }}
          @if (isRequired()) {
            &ast;
          }
        </label>
      }
    </div>

    @if (isInvalid() && isTouched()) {
      <cup-form-errors [name]="name()" [formGroup]="formGroup()" />
    }
  `,
})
export class CheckboxComponent extends CheckboxBase {
  checkboxInput = viewChild.required(CheckboxInputComponent);

  override get inputRef() {
    return this.checkboxInput().inputRef;
  }
}
