import {
  ChangeDetectionStrategy,
  Component,
  ErrorHandler,
  inject,
  OnInit,
  viewChild,
} from "@angular/core";
import { BreadcrumbComponent } from "@/molecules/breadcrumb/breadcrumb.component";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ContactsFormComponent } from "@/molecules/contacts-form/contacts-form.component";
import { FullLayoutComponent } from "@/atoms/full-layout.component";
import { HeroComponent } from "@/molecules/hero.component";
import {
  ItButtonDirective,
  ItModalComponent,
  ItNotificationService,
} from "design-angular-kit";
import { UserService } from "@/services/user.service";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { absoluteRoutesPaths } from "@/classes/route-utils";
import { ContactOtpModalComponent } from "@/molecules/contact-otp-modal/contact-otp-modal.component";
import { lastValueFrom, Subject, take, tap } from "rxjs";
import { OtpVerifyResponse } from "@/types/api";
import { OtpAbortError, OtpResendCodeError } from "@/classes/otp-error";
import { $localize } from "@angular/localize/init";
import { NewReservationService } from "@/services/new-reservation.service";
import { ResponseClientError } from "@/classes/errors";

@Component({
  selector: "cup-user-contact-data-page",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BreadcrumbComponent,
    ReactiveFormsModule,
    ContactsFormComponent,
    FullLayoutComponent,
    HeroComponent,
    ItButtonDirective,
    RouterLink,
    ItModalComponent,
    ContactOtpModalComponent,
  ],
  styles: `
    @use "functions";
    @use "mixins";

    .contact-form-container {
      display: block;
      padding-bottom: functions.toRem(24);

      @include mixins.media-breakpoint-up(lg) {
        padding-bottom: functions.toRem(62);
      }
    }
  `,
  template: `
    <cup-breadcrumb />

    <cup-hero
      i18n-heroTitle
      heroTitle="Dati di contatto"
      [heroCopyTemplate]="
        userService.userHasContactDataFromSpid() ? heroCopy : undefined
      "
    />

    <ng-template #heroCopy i18n>
      Hai un'identità digitale e vuoi utilizare i dati di contatto dal tuo SPID?
      <a [routerLink]="absoluteRoutesPaths.userContactDataSPID">
        Recupera contatti
      </a>
    </ng-template>

    <cup-full-layout
      class="contact-form-container"
      [colConfig]="{ xs: 12, lg: 6 }"
    >
      <form
        (ngSubmit)="handleSubmit()"
        class="d-flex flex-column gap-lg-5 gap-4"
        [formGroup]="form"
      >
        <cup-contacts-form formControlName="contacts" />

        <div class="d-flex gap-3">
          @if (isNewReservationStep) {
            <a
              [routerLink]="absoluteRoutesPaths.newReservation"
              itButton="outline-secondary"
              i18n
              >Torna indietro</a
            >
          }

          <button
            #submitBtn
            type="submit"
            class="align-self-lg-start"
            itButton="primary"
            i18n
          >
            Salva contatti
          </button>
        </div>

        @if (!isNewReservationStep && userService.userHasContactData()) {
          <button
            type="button"
            class="align-self-lg-start"
            itButton="outline-secondary"
            i18n
            (click)="handleRemoveContactData()"
          >
            Non voglio più ricevere comunicazioni
          </button>
        }
      </form>
    </cup-full-layout>

    <ng-container [formGroup]="form">
      <cup-contact-otp-modal formControlName="phoneOtpCode" #phoneOtpModal>
        <p class="text-center" i18n>
          Ti è arrivato al numero di cellulare<br />
          <span class="text-primary">{{
            form.controls.contacts.value.phoneNumber
          }}</span>
        </p>
      </cup-contact-otp-modal>

      <cup-contact-otp-modal
        codeLength="6"
        formControlName="emailOtpCode"
        #emailOtpModal
      >
        <p class="text-center" i18n>
          Ti è arrivato all'indirizzo email<br />
          <span class="text-primary">{{
            form.controls.contacts.value.email
          }}</span>
        </p>
      </cup-contact-otp-modal>
    </ng-container>

    <it-modal
      alignment="centered"
      #confirmRevokeConsentModal
      (hideEvent)="contactModalStatusSubject.next('abort')"
      backdrop="static"
    >
      <ng-container modalTitle i18n>Revoca consenso</ng-container>
      <p i18n>
        Sei sicuro di non voler più ricevere comunicazioni dal Servizio
        Sanitario Regionale?
      </p>
      <ng-container footer>
        <button
          (click)="confirmRevokeConsentModal.hide()"
          itButton="outline-primary"
        >
          Annulla
        </button>
        <button
          (click)="contactModalStatusSubject.next('continue')"
          itButton="primary"
        >
          Conferma
        </button>
      </ng-container>
    </it-modal>
  `,
})
export class UserContactDataPageComponent implements OnInit {
  router = inject(Router);
  route = inject(ActivatedRoute);
  formBuilder = inject(FormBuilder);
  userService = inject(UserService);
  notificationService = inject(ItNotificationService);
  errorHandler = inject(ErrorHandler);
  newReservationService = inject(NewReservationService, {
    optional: true,
  });

  cupContacts = viewChild.required(ContactsFormComponent);
  phoneOTPModal = viewChild.required<ContactOtpModalComponent>("phoneOtpModal");
  emailOTPModal = viewChild.required<ContactOtpModalComponent>("emailOtpModal");

  revokeConsentModal = viewChild.required<ItModalComponent>(
    "confirmRevokeConsentModal"
  );

  get isNewReservationStep() {
    return !!this.route.snapshot.data["newReservation"];
  }

  contactModalStatusSubject = new Subject<"abort" | "continue">();

  form!: FormGroup<{
    contacts: FormControl<{
      email: string;
      emailOwner: string;
      phoneNumber: string;
      phoneNumberOwner: string;
    }>;
    phoneOtpCode: FormControl<string>;
    emailOtpCode: FormControl<string>;
  }>;

  ngOnInit() {
    const contactData = this.userService.user()?.contactData;

    this.form = this.formBuilder.nonNullable.group({
      contacts: [
        {
          email: contactData?.email || "",
          emailOwner: contactData?.proprietario_email.id || "A",
          phoneNumber: contactData?.mobile || "",
          phoneNumberOwner: contactData?.proprietario_cellulare.id || "A",
        },
      ],
      phoneOtpCode: ["", [Validators.required]],
      emailOtpCode: ["", [Validators.required, Validators.email]],
    });
  }

  async handleSubmit() {
    try {
      await this.updateContactData();

      if (this.isNewReservationStep) {
        this.newReservationService!.contactsAddedAt.set(new Date().getTime());
        await this.router.navigateByUrl(absoluteRoutesPaths.newReservation);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async updateContactData() {
    if (this.form.controls.contacts.invalid) {
      this.cupContacts()?.markAsTouched();
      throw new Error("Form is invalid");
    }

    const { contacts } = this.form.controls;
    const { email, phoneNumber, phoneNumberOwner, emailOwner } = contacts.value;
    const contactData = this.userService.user()?.contactData;

    if (
      phoneNumberOwner === contactData?.proprietario_cellulare.id &&
      emailOwner === contactData?.proprietario_email.id &&
      email === contactData?.email &&
      phoneNumber === contactData?.mobile
    ) {
      this.notificationService.warning($localize`Nessun nuovo dato da salvare`);
      throw new Error("No new data to save");
    }

    try {
      if (phoneNumber !== contactData?.mobile) {
        await this.startOtpFlow({
          type: "mobile",
          value: phoneNumber,
        });
      }

      if (email !== contactData?.email) {
        await this.startOtpFlow({
          type: "email",
          value: email,
        });
      }

      this.resetOtpCodes();

      const newUserContactData = {
        email,
        mobile: phoneNumber,
        validato: "T",
        codice_fiscale: this.userService.userJwtFiscalCode,
        proprietario_email: {
          id: contacts.value.emailOwner,
        },
        proprietario_cellulare: {
          id: contacts.value.phoneNumberOwner,
        },
      };

      await new Promise<void>((resolve) => {
        this.userService
          .saveContactData({
            ...newUserContactData,
            identificativo: this.userService.userJwtFiscalCode,
          })
          .subscribe(() => {
            this.userService.user.update((user) => ({
              personalData: user?.personalData || null,
              contactData: newUserContactData,
            }));

            resolve();
          });
      });
    } catch (e) {
      if (e instanceof OtpAbortError) {
        this.form.controls.phoneOtpCode.reset();
        this.form.controls.emailOtpCode.reset();

        this.notificationService.warning(
          $localize`Procedura interrotta`,
          $localize`La procedura di aggiornamento dei dati di contatto è stata interrotta.`
        );
      }

      throw new Error("Authorization canceled");
    }
  }

  async startOtpFlow({
    type,
    value,
  }: {
    type: "mobile" | "email";
    value: string;
  }): Promise<void> {
    const otpResponse = await lastValueFrom(
      this.userService.generateContactsOtp({
        [type]: value,
      })
    );

    try {
      const verifyResponse = await (
        type === "mobile" ? this.phoneOTPModal() : this.emailOTPModal()
      )!.initFlow<OtpVerifyResponse>(() =>
        lastValueFrom(
          this.userService.verifyOtp({
            codice_fiscale: this.userService.userJwtFiscalCode,
            ...(type === "mobile"
              ? {
                  otp_code: this.form.controls.phoneOtpCode.value,
                }
              : {
                  otp_mail_code: this.form.controls.emailOtpCode.value,
                }),
            otp_request_id: otpResponse.otp_request_id,
          })
        )
      );

      if (!verifyResponse.dati_verificati) {
        this.errorHandler.handleError(
          new ResponseClientError({
            title: $localize`Codice errato`,
            detail: $localize`Il codice inserito è errato. È stato inviato un nuovo codice.`,
          })
        );

        return this.startOtpFlow({ type, value });
      }
    } catch (e) {
      if (e instanceof OtpAbortError) {
        throw new OtpAbortError();
      }

      if (e instanceof OtpResendCodeError) {
        this.notificationService.success(
          $localize`Codice inviato`,
          $localize`Ti è stato inviato un nuovo codice.`
        );

        return this.startOtpFlow({ type, value });
      }
    }
  }

  async handleRemoveContactData() {
    this.revokeConsentModal().show();

    const status = await new Promise((resolve) => {
      this.contactModalStatusSubject
        .pipe(take(1))
        .subscribe((status) => resolve(status));
    });

    if (status === "abort") {
      return;
    }

    this.userService
      .removeUserContactsData()
      .pipe(tap(() => this.revokeConsentModal().hide()))
      .subscribe(() => {
        void this.router.navigateByUrl(absoluteRoutesPaths.userProfile);
      });
  }

  resetOtpCodes() {
    this.form.controls.phoneOtpCode.setValue("");
    this.form.controls.emailOtpCode.setValue("");
  }

  protected readonly absoluteRoutesPaths = absoluteRoutesPaths;
}
