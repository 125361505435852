// export const environment = {
//   RUN_MOCK_APP_INITIALIZER: false,
//   MOCK_DATA_FILE_NAME: "data",
//   MOCK_APIS: {
//     getRecipeById: false,
//     getReservationById: false,
//     getAllReservations: false,
//     getAllRecipes: false,
//     insertRecipe: false,
//     getAppointments: false,
//   },
//   REDIRECT_URI: "http://localhost:8080",
//   API_BASE_URL: "http://localhost:8080/api", //  https://api-cup-online-dev.regione.umbria.it/api
//   PRODUCTION: true,
//   CLIENT_ID: "",
// };
export const environment = await fetch("./config/config.json")
  .then((resp) => resp.json())
  .then((config) => {
    return config;
  });
