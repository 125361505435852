import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
} from "@angular/core";
import {
  ItCarouselComponent,
  ItCarouselItemComponent,
} from "design-angular-kit";
import { AsyncPipe, TitleCasePipe } from "@angular/common";
import { FormProviderService } from "@/services/form-provider.service";
import { ReactiveFormsModule } from "@angular/forms";
import { RadioChipComponent } from "@/atoms/radio-chip/radio-chip.component";

@Component({
  selector: "cup-filters-chips-carousel",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  template: `
    <it-carousel
      [formGroup]="formProviderService.form"
      class="cup-carousel"
      type="default"
    >
      @for (item of services(); track item) {
        <it-carousel-item>
          <cup-radio-chip
            [value]="item"
            [currentValue]="
              formProviderService.form.controls['serviceType'].valueChanges
                | async
            "
            formControlName="serviceType"
            [label]="item | titlecase"
          />
        </it-carousel-item>
      }
    </it-carousel>
  `,
  imports: [
    ItCarouselComponent,
    ItCarouselItemComponent,
    TitleCasePipe,
    ReactiveFormsModule,
    RadioChipComponent,
    AsyncPipe,
  ],
})
export class FiltersChipsCarouselComponent {
  formProviderService = inject(FormProviderService);
  searchKeyChange = output<string>();
  labelId = input.required<string>();
  services = input.required<string[]>();
}
