import { relativeRoutesPaths, routeLinks } from "@/classes/route-utils";
import { UserContactDataPageComponent } from "@/app/pages/user-contact-data-page/user-contact-data-page.component";
import hasValidToken from "../guards/has-valid-token.guard";
import userResolver from "../resolvers/user.resolver";

const userProfileContactRoute = {
  path: relativeRoutesPaths.userContactData,
  component: UserContactDataPageComponent,
  data: {
    breadcrumbItems: [
      routeLinks.home,
      routeLinks.userProfile,
      routeLinks.userContactData,
    ],
  },
  canActivate: [hasValidToken],
  resolve: {
    user: userResolver,
  },
};

export default userProfileContactRoute;
