import { AbstractControl } from "@angular/forms";

const ITALIAN_FISCAL_CODE_REGEX =
  "[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}";

export default function italianFiscalCodeValidator(control: AbstractControl) {
  const isValidFiscalCode = new RegExp(ITALIAN_FISCAL_CODE_REGEX).test(
    control.value
  );

  return isValidFiscalCode
    ? null
    : {
        fiscalCode: true,
      };
}
