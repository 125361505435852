import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Injectable()
export class FormProviderService {
  form!: FormGroup;

  initServiceForm(form: FormGroup) {
    this.form = form;
  }
}
