import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  ViewEncapsulation,
} from "@angular/core";
import { FullLayoutComponent } from "@/atoms/full-layout.component";
import {
  ItCardComponent,
  ItPaginationComponent,
  ItTableComponent,
} from "design-angular-kit";
import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  NgComponentOutlet,
  NgIf,
  NgTemplateOutlet,
  TitleCasePipe,
} from "@angular/common";
import { GridColDirective } from "@/atoms/grid-col.component";
import { MappedTableData } from "@/types/table-sort";
import { Destroyable } from "@/classes/destroyable";

@Component({
  selector: "cup-table",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    FullLayoutComponent,
    ItPaginationComponent,
    ItTableComponent,
    NgIf,
    NgComponentOutlet,
    DatePipe,
    DecimalPipe,
    CurrencyPipe,
    ItCardComponent,
    GridColDirective,
    NgTemplateOutlet,
    TitleCasePipe,
  ],
  styles: `
    @use "cup";

    cup-table {
      .mobile-layout {
        margin-top: cup.toRem(32);
        margin-bottom: cup.toRem(46);

        @include cup.media-breakpoint-up(lg) {
          display: none;
        }
      }

      .table-wrapper {
        display: none;

        @include cup.media-breakpoint-up(lg) {
          display: block;
          padding-bottom: cup.toRem(218);

          .table {
            margin-bottom: 0;

            thead tr {
              background-color: cup.$color-background-muted;
            }

            td,
            th {
              padding: cup.toRem(24) cup.toRem(32);
            }

            th {
              white-space: nowrap;
            }
          }
        }
      }
    }

    cup-table it-pagination {
      display: block;
    }
  `,
  template: `
    @let data = pageData();

    <div class="table-wrapper">
      <cup-full-layout>
        @if (mappedDesktopData().length) {
          <it-table>
            <ng-container thead>
              <tr>
                @for (heading of headings(); track heading.label) {
                  <th class="h6" scope="col" [colSpan]="heading.colSpan">
                    {{ heading.label }}
                  </th>
                }
              </tr>
            </ng-container>

            <ng-container tbody>
              @for (row of data.desktopPageData; track row.id) {
                <tr>
                  @for (col of row.columns; track $index) {
                    <td>
                      @if (col.value) {
                        @switch (col.format) {
                          @case ("date") {
                            {{ col.value | date: "dd/MM/yyyy, HH:mm" }}
                          }
                          @case ("number") {
                            {{ col.value | number }}
                          }
                          @case ("titlecase") {
                            {{ col.value | titlecase }}
                          }
                          @case ("currency") {
                            {{ col.value | currency }}
                          }
                          @default {
                            {{ col.value }}
                          }
                        }
                      }

                      @if (col.template) {
                        <ng-container
                          *ngTemplateOutlet="col.template; context: col.context"
                        />
                      }

                      @if (col.component) {
                        <ng-container
                          *ngComponentOutlet="col.component; inputs: col.inputs"
                        />
                      }
                    </td>
                  }
                </tr>
              }
            </ng-container>
          </it-table>

          <ng-container *ngTemplateOutlet="pagination"></ng-container>
        } @else {
          <ng-container *ngTemplateOutlet="noResultsFound" />
        }
      </cup-full-layout>
    </div>

    <div class="container-xxl">
      @if (mappedMobileData().length) {
        <div class="mobile-layout">
          <div class="row gap-3">
            @for (data of data.mobilePageData; track $index) {
              <div cupGridCol [config]="{ xs: 12 }">
                <it-card [rounded]="true" [teaser]="true" [shadow]="true">
                  <ng-container
                    *ngComponentOutlet="data.component; inputs: data.inputs"
                  />
                </it-card>
              </div>
            }
          </div>

          <ng-container
            *ngTemplateOutlet="pagination; context: { $implicit: true }"
          />
        </div>
      } @else {
        <div class="d-lg-none mb-5">
          <ng-container *ngTemplateOutlet="noResultsFound" />
        </div>
      }
    </div>

    <ng-template #pagination let-simpleMode>
      <it-pagination
        class="mt-5"
        *ngIf="!isLoadingData() && totalPages() > 0"
        alignment="center"
        [simpleMode]="simpleMode"
        (pageEvent)="pageChange.emit($event)"
        [currentPage]="currentPage()"
        [pageNumbers]="totalPages()"
      />
    </ng-template>

    <ng-template #noResultsFound>
      <p i18n>Nessun risultato trovato.</p>
    </ng-template>
  `,
})
export class TableComponent extends Destroyable {
  data = input.required<MappedTableData>();
  pageSize = input(4);
  currentPage = input(0);
  isLoadingData = input(false);
  totalPagesCount = input<number>(0);
  isClientPagination = input(true);
  mappedDesktopData = computed(() => this.data().desktop);
  mappedMobileData = computed(() => this.data().mobile);
  headings = computed(() => this.data().headings);

  pageData = computed(() => {
    const desktopData = this.mappedDesktopData();
    const mobileData = this.mappedMobileData();

    if (!this.isClientPagination()) {
      return { desktopPageData: desktopData, mobilePageData: mobileData };
    }

    const start = this.currentPage() * this.pageSize();
    const end = start + this.pageSize();

    return {
      desktopPageData: desktopData.slice(start, end),
      mobilePageData: mobileData.slice(start, end),
    };
  });

  totalPages = computed(() => {
    if (!this.isClientPagination()) return this.totalPagesCount();

    return Math.ceil(this.mappedDesktopData().length / this.pageSize());
  });

  pageChange = output<number>();
}
